import React from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
const { Panel } = Collapse;

const Faq = (props) => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <h2 className="text-center mb-8">Frequently Asked Questions</h2>
        <Collapse accordion>
          {props.faqs.map((faq) => (
            <Panel header={faq.header} key={faq.key}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
        <div className=" text-center mt-5 ">
          <Link to="/faqs" className="btn1">
            View all FAQs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faq;
