import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import Banner2 from "../../components/web/banners/Banner2";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import f1 from "../../assets/images/services/document-and-parcel-collection-and-delivery-service.jpg";
import f2 from "../../assets/images/services/door-to-door-express-parcel-delivery-service.jpg";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";

const ParcelDeliveryService = () => {
  const hero = {
    title: "Parcel Delivery Service",
    description:
      <span>Our professional chauffeurs provide a secure <b>parcel delivery service</b>, ensuring safe and timely transportation from pickup to delivery. Don't wait – book your parcel delivery today for a worry-free shipping experience!</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Parcel Delivery Service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Parcel Delivery Service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "A chauffeur offers document and parcel collection and delivery service around Canada.",
    title: "Your Trusted Partner for Secure Parcel Delivery    ",
    description:
      <span>At A Chauffeur, we take immense pride in being your reliable partner for secure parcel and document delivery in Toronto. Our unwavering commitment to the safety and timely transit of your valuable items is at the core of our services. We offer a comprehensive range of solutions, including parcel delivery, same-day courier services, and localized courier options to cater to the diverse needs of both businesses and households. As a trusted name among parcel <b>delivery companies in Toronto</b>, we specialize in efficient same-day parcel delivery, ensuring the satisfaction and peace of mind of our valued clients. Whether you need a cheap courier service in Toronto, the cheapest courier option, or pickup and delivery services, we’ve got you covered. Trust us for all your Toronto parcel delivery service needs.</span>,
  };
  const feature2 = {
    image: f2,
    alt: "Door-to-door parcel delivery service from achauffeur. 100% customer satisfaction in parcel delivery service",
    title: "Your Satisfaction, Our Priority",
    description:
      <span>What truly distinguishes us at A Chauffeur is our unwavering dedication to convenience. Our user-friendly online platform allows you to effortlessly hire a chauffeur, streamlining the entire process and ensuring a seamless experience from start to finish. Whether your needs involve city-to-city parcel delivery or local courier services, A Chauffeur is here to meet your demands. Trust us to handle your courier and parcel delivery with the utmost professionalism and care, ensuring the secure and punctual delivery of your cherished items to their destinations. Your satisfaction and peace of mind remain our top priorities. From parcel delivery in Toronto to finding a cheap courier service in Toronto, we are committed to providing affordable and reliable solutions. Choose us for all your Toronto parcel delivery service needs, including pickup and delivery services and <b>same-day courier service in Toronto</b>.</span>,
  };

  return (
    <>
      <Helmet>
        <title>Book Your Parcel Delivery Service Today | AChauffeur.com</title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service To or From Hamilton Airport. Best Price Guaranteed.
  "
        />{" "}
        <meta
          name="keywords"
          content=" Parcel delivery Toronto, Delivery parcel to Toronto, Toronto parcel delivery service, Cheap courier Toronto, Cheapest courier service in Toronto, Cheap courier service Toronto, Pickup and delivery service Toronto, Same day courier service Toronto, Parcel delivery "
        />
        <link rel="canonical" href="/services/parcel-delivery-service" />
      </Helmet>
      <ServiceHero hero={hero} />
      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default ParcelDeliveryService;
