import React from "react";

const HowItWork = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <h2 className="text-center">How does it work?</h2>

        <div className="grid md:grid-cols-3 gap-5 md:gap-10">
          <div>
            <div className="w-16 h-16 bg-sec-500 rounded-full mx-auto grid place-items-center mb-4">
              <h2 className=" leading-none mb-0 text-white">1</h2>
            </div>
            <h3 className="text-center">Enter your route and select car</h3>
            <div className="text-base text-center">
              Enter all the required data in the input field and then choose the
              desired vehicle for your trip.
            </div>
          </div>
          <div>
            <div className="w-16 h-16 bg-sec-500 rounded-full mx-auto grid place-items-center mb-4">
              <h2 className=" leading-none mb-0 text-white">2</h2>
            </div>
            <h3 className="text-center">Complete booking form</h3>
            <div className="text-base text-center">
              Confirmation is immediate. free cancellation up to 24 hours before
              your scheduled pickup time.
            </div>
          </div>
          <div>
            <div className="w-16 h-16 bg-sec-500 rounded-full mx-auto grid place-items-center mb-4">
              <h2 className=" leading-none mb-0 text-white">3</h2>
            </div>
            <h3 className="text-center">Meet your chauffeur</h3>
            <div className="text-base text-center">
              Our chauffeur will come to your location on time. and get your
              destination quickly and safely.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
