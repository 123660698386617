import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner1";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/tobermory/all-inclusive-limousine-services-from-holiday-inn-expressand-suites-port-elgin-to-tobermory-complete-luxury-transportation-available.jfif";
import one2 from "../../assets/images/hotels/attractions/tobermory/luxury-car-services-from-holiday-inn-expressand-suites-port-elgin-to-tobermory-high-comfortable-and-hassle-free-services.jfif";
import one3 from "../../assets/images/hotels/attractions/tobermory/business-class-trips-from-holiday-inn-expressand-suites-port-elgin-to-tobermory-hourly-services-available-at-affordable-price.jfif";
import two1 from "../../assets/images/hotels/attractions/tobermory/private-car-services-from-best-western-inn-on-the-bay-to-tobermory-fun-and-enjoyful-trip-from-achauffeur.jpg";
import two2 from "../../assets/images/hotels/attractions/tobermory/executive-and-personalized-limo-services-from-best-western-inn-on-the-bay-to-tobermory-clean-and-comfortable-limos-from-achauffeur.jpg";
import two3 from "../../assets/images/hotels/attractions/tobermory/hire-a-private-car-from-best-western-inn-on-the-bay-to-tobermory-we-have-well-trained-drivers-start-your-trip-with-pleasure.jpg";
import three1 from "../../assets/images/hotels/attractions/tobermory/chauffeur-service-at-towneplace-suites-by-marriott-kincardine-to-tobermory-make-your-reservations-online.webp";
import three2 from "../../assets/images/hotels/attractions/tobermory/limo-cars-near-towneplace-suites-by-marriott-kincardine-to-tobermory-hire-a-car-at-affordable-price.webp";
import three3 from "../../assets/images/hotels/attractions/tobermory/personal-chauffeur-service-from-towneplace-suites-by-marriott-kincardine-to-tobermory-safe-and-secure-service-available.webp";
import four1 from "../../assets/images/hotels/attractions/tobermory/book-cars-online-from-the-westin-trillium-house-blue-mountain-to-tobermory-we-have-professional-drivers.webp";
import four2 from "../../assets/images/hotels/attractions/tobermory/higenic-and-comfortable-car-services-from-the-westin-trillium-house-blue-mountain-to-tobermory-we-guarantee-a-stress-free-transportation.webp";
import four3 from "../../assets/images/hotels/attractions/tobermory/premium-quality-limo-services-from-the-westin-trillium-house-blue-mountain-to-tobermory-our-rates-are-affordable.webp";

const Tobermory = () => {
  const hero = {
    title: "Tobermory",
    description:
      "Have a convenient and Hassle-free chauffeur service to Tobermory. Achauffeur offers a first-rate, luxurious & premium limo service in Tobermory. We guarantee your comfort and safety while traveling with us.",
  };

  const desc = {
    title: "It's easy, pre-arrange your chauffeur service to Tobermory.",
    description:
      "Tobermory is a small town located on the Bruce Peninsula in Ontario, Canada, known for its natural beauty and outdoor recreational opportunities. The town is the main gateway to Fathom Five National Marine Park, which is home to crystal clear waters and numerous shipwrecks popular with divers, as well as attractions like Flowerpot Island and the Bruce Peninsula National Park. Tobermory is also home to the Tobermory Lighthouse, offering panoramic views of the town and surrounding area, and the Tobermory Heritage Museum, which features exhibits on the region's natural history and cultural heritage. Other popular tourist attractions in the town include The Grotto, a natural cave with a crystal clear pool, and scuba diving in the clear waters and shipwrecks.",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of Tobermory, as your own personal driver shows you the must-see tourist attractions near Tobermory. You can make an online booking or directly can make a call on the given numbers for your trip.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Holiday Inn Express & Suites Port Elgin to tobermory.",
        "High comfortable and hassle free luxury limousine services from Holiday Inn Express & Suites Port Elgin to tobermory ",
        "Hourly limo services at affordable price from Holiday Inn Express & Suites Port Elgin to tobermory. Business class trips for your vacations",
      ],
      title: "Holiday Inn Express & Suites Port Elgin",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyful trips from Best Western Inn On The Bay to tobermory. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Best Western Inn On The Bay to tobermory. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Best Western Inn On The Bay to tobermory. Hire a private car at an affordable price",
      ],
      title: "Best Western Inn On The Bay",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from TownePlace Suites by Marriott Kincardine to tobermory",
        "Hire a car at affordable price near TownePlace Suites by Marriott Kincardine to tobermory.best chauffeur services",
        "Safe and secure personal chauffeur services from TownePlace Suites by Marriott Kincardine to tobermory. High comfortable and luxury",
      ],
      title: "TownePlace Suites by Marriott Kincardine",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from The Westin Trillium House, Blue Mountain to tobermory.Best limo services to tobermory",
        "Achauffeur offers hygienic and comfortable car services from The Westin Trillium House, Blue Mountain to tobermory. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from The Westin Trillium House, Blue Mountain to tobermory. Our rates are affordable",
      ],
      title: "The Westin Trillium House, Blue Mountain",
    },
  ];
  const mostBookeds = [
    {
      start: "Tobermory",
      end: "Toronto Pearson International Airport",
      duration: "3 hr 26 min",
      distance: "274 Km",
      price: "211.72",
    },
    {
      start: "Tobermory",
      end: "Toronto, Ontario",
      duration: "3 hr 44 min",
      distance: "296 Km",
      price: "211.72",
    },
    {
      start: "Tobermory",
      end: "Owen Sound, Ontario",
      duration: "1 hr 19 min",
      distance: "109 Km",
      price: "211.72",
    },
    {
      start: "Tobermory",
      end: "The Blue Mountains, Ontario",
      duration: "1 hr 56 min",
      distance: "152 Km",
      price: "211.72",
    },
    {
      start: "Tobermory",
      end: "Barrie, Ontario",
      duration: "3h 00 min",
      distance: "224 Km",
      price: "211.72",
    },
    // {
    //   start: "Tobermory",
    //   end: "Barrie (Lake Simcoe)",
    //   duration: "1h 00 min",
    //   distance: "23 Km",
    //   price: "211.72",
    // },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          {" "}
          All-Inclusive Price Private Chauffeur Service to Tobermory.
        </title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Best All-Inclusive, Reliable and Efficient Chauffeur Service To/from Tobermory. Best Price Guaranteed.
          "
        />
        <meta
          name="keywords"
          content="Chauffeur service to Tobermory, Chauffeur service from Tobermory, chauffeur service, Chauffeur service from toronto to Tobermory, airport car service, airport limo service, black car service, airport limo, airport limousine, toronto chauffeur service, Airport transfer, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-tobermory"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Tobermory;
