import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner2";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/cntower/all-inclusive-limousine-services-from-delta-hotels-by-marriott-toronto-to-cn-tower-complete-luxury-transportation-available.jpg";
import one2 from "../../assets/images/hotels/attractions/cntower/luxury-car-services-from-delta-hotels-by-marriott-toronto-to-cn-tower-high-comfortable-and-hassle-free-services.jpg";
import one3 from "../../assets/images/hotels/attractions/cntower/business-class-trips-from-delta-hotels-by-marriott-toronto-to-cn-tower-hourly-services-available-at-affordable-price.jpg";
import two1 from "../../assets/images/hotels/attractions/cntower/private-car-services-from-toronto-marriott-city-centre-hotel-to-cn-tower-fun-and-enjoyful-trip-from-achauffeur.webp";
import two2 from "../../assets/images/hotels/attractions/cntower/executive-and-personalized-limo-services-from-toronto-marriott-city-centre-hotel-to-cn-tower-clean-and-comfortable-limos-from-achauffeur.webp";
import two3 from "../../assets/images/hotels/attractions/cntower/hire-a-private-car-from-toronto-marriott-city-centre-hotel-to-cn-tower-we-have-well-trained-drivers-start-your-trip-with-pleasure.webp";
import three1 from "../../assets/images/hotels/attractions/cntower/chauffeur-service-at-hotel-x-toronto-by-library-hotel-collection-to-cn-tower-make-your-reservations-online.jpg";
import three2 from "../../assets/images/hotels/attractions/cntower/limo-cars-near-hotel-x-toronto-by-library-hotel-collection-to-cn-tower-hire-a-car-at-affordable-price.png";
import three3 from "../../assets/images/hotels/attractions/cntower/personal-chauffeur-service-from-hotel-x-toronto-by-library-hotel-collection-to-cn-tower-safe-and-secure-service-available.jpg";
import four1 from "../../assets/images/hotels/attractions/cntower/book-cars-online-from-intercontinental-toronto-centre-to-cn-tower-we-have-professional-drivers.jfif";
import four2 from "../../assets/images/hotels/attractions/cntower/higenic-and-comfortable-car-services-from-intercontinental-toronto-centre-to-cn-tower-we-guarantee-a-stress-free-transportation.jfif";
import four3 from "../../assets/images/hotels/attractions/cntower/premium-quality-limo-services-from-intercontinental-toronto-centre-to-cn-tower-our-rates-are-affordable.jfif";
const CNTower = () => {
  const hero = {
    title: "CN Tower",
    description:
      "Book a ride with Achauffeur in advance to have a safe and comfortable journey with our professional chauffeurs. Our professionally trained chauffeurs will ensure that you arrive in a safe and timely manner.",
  };

  const desc = {
    title: "It's easy, pre-arrange your chauffeur service CN Tower.",
    description:
      "The CN Tower is a communications and observation tower located in Toronto, Ontario, Canada. It is the tallest free-standing structure in the Western Hemisphere, standing at a height of 1,815 feet. The tower was completed in 1976 and was originally built to improve the quality of television and radio signals in the Toronto area. It has since become one of the most famous landmarks in Toronto and a popular tourist attraction.The CN Tower has several observation decks and a restaurant located at various heights within the tower. ",
    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of CN Tower, as your own personal driver shows you the must-see tourist attractions near CN Tower. You can make an online booking or directly can make a call on the given numbers for your trip.",
    website: "https://www.billybishopairport.com/",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Delta Hotels by Marriott Toronto to CN Tower.",
        "High comfortable and hassle free luxury limousine services from Delta Hotels by Marriott Toronto to CN Tower",
        "Hourly limo services at affordable price from Delta Hotels by Marriott Toronto to CN Tower. Business class trips for your vacations",
      ],
      title: "Delta Hotels by Marriott Toronto",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyfull trips from Toronto Marriott City Centre Hotel to CN Tower. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Toronto Marriott City Centre Hotel to CN Tower. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Toronto Marriott City Centre Hotel to CN Tower. Hire a private car at an affordable price",
      ],
      title: "Toronto Marriott City Centre Hotel",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from Hotel X Toronto by Library Hotel Collection to CN Tower",
        "Hire a car at affordable price near Hotel X Toronto by Library Hotel Collection to CN Tower.best chauffeur services",
        "Safe and secure personal chauffeur services from Hotel X Toronto by Library Hotel Collection to CN Tower. High comfortable and luxury",
      ],
      title: "Hotel X Toronto by Library Hotel Collection",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from InterContinental Toronto Centre to CN Tower.Best limo services to CN Tower",
        "Achauffeur offers hygienic and comfortable car services from InterContinental Toronto Centre to CN Tower. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from InterContinental Toronto Centre to CN Tower. Our rates are affordable",
      ],
      title: "InterContinental Toronto Centre",
    },
  ];
  const mostBookeds = [
    {
      start: "Totonto pearson airport",
      end: "Toronto Pearson International Airport",
      duration: "17 min",
      distance: "24.2 Km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Mississauga, Ontario",
      duration: "26 min",
      distance: "25.8 Km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Oakville, Ontario",
      duration: "24 min",
      distance: "35.9 Km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Richmond Hill, Ontario",
      duration: "31 min",
      distance: "38.6 Km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Niagara Falls, Ontario",
      duration: "1h 12 min",
      distance: "127 Km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Hamilton, Ontario",
      duration: "43 min",
      distance: "66.4 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          All-Inclusive Private Chauffeur Service To CN Tower Toronto
        </title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Best All-Inclusive, Reliable and Efficient Chauffeur Service To/from CN Tower. Best Price Guaranteed."
        />
        <meta
          name="keywords"
          content="chauffeur service, toronto chauffeur service, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur, Chauffeur service to CN Tower, Chauffeur service from CN tower"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-cn-tower"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default CNTower;
