import React from "react";

const MostBookedCard = (props) => {
  return (
    <div className="box--shadow hover:shadow-md bg-white p-5">
      <div className="text-base mb-3">
        {props.mostBooked.start} <i class="ri-arrow-right-line mx-2 "></i>
        <span className="text-base font-semibold">{props.mostBooked.end}</span>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-2">
            <i class="ri-time-line"></i>
            <div> {props.mostBooked.duration}</div>
          </div>
          <div className="flex items-center gap-2">
            <i class="ri-pin-distance-line"></i>
            <div> {props.mostBooked.distance}</div>
          </div>
        </div>
        <div>{/* From: <b> ${props.mostBooked.price}</b> */}</div>
      </div>
    </div>
  );
};

export default MostBookedCard;
