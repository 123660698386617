import React from "react";
import { Link } from "react-router-dom";
import attraction from "../../../assets/images/attraction11.jpg";
import attraction2 from "../../../assets/images/attraction21.jpg";
import attraction3 from "../../../assets/images/attraction31.jpg";
const AttractionsHero = (props) => {
  return (
    <div className="hero--5 ">
      <div className="hero--cover-4 flex items-center  h-full px-3 py-12 sm:py-16 bg-sec-100">
        <div className="container">
          <div className="grid md:grid-cols-2  gap-10">
            <div className="text-white my-auto mx-auto text-center md:text-left">
              <h3 className="text-white">
                <Link
                  to="/home"
                  className="text-white hover:text-pri-500 font-semibold"
                >
                  Home
                </Link>{" "}
                / Tourist Attractions
              </h3>
              <h2 className="text-white">{props.hero.title}</h2>
              <div className="-mt-2 mb-5 text-base max-w-xl">
                {props.hero.description}
              </div>
              <Link
                to="/book-now-a-chauffeur-service"
                className="btn2   text-base  cursor-pointer"
              >
                Book Now
              </Link>
            </div>
            <div className="hidden md:grid grid-cols-3 gap-5  ">
              <div>
                <img
                  className="h-80 object-cover rounded-md "
                  style={{ marginTop: "20px", marginBottom: "-20px" }}
                  src={attraction}
                  alt="flight"
                />
              </div>
              <img
                className="h-80 object-cover rounded-md "
                style={{ marginTop: "-20px", marginBottom: "20px" }}
                src={attraction2}
                alt="flight"
              />
              <img
                className="h-80 object-cover rounded-md"
                style={{ marginTop: "20px", marginBottom: "-20px" }}
                src={attraction3}
                alt="flight"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttractionsHero;
