import React from "react";
import { Link } from "react-router-dom";

const FleetHero = () => {
  return (
    <div className="overflow-hidden">
      <div className="hero--2 ">
        <div className="hero--cover h-full w-full ">
          <div className="container h-full flex items-center justify-center text-center">
            <div>
              <h2 className="roboto uppercase text-white ">
                <span className="text-pri-500 roboto">Our</span> Fleet
              </h2>
              <div className="-mt-2 text-white text-xl mb-7 max-w-3xl">
                With our superb fleet of modern vehicles, we offer excellent
                service whatever the occasion.
              </div>
              <Link
                to="/book-now-a-chauffeur-service"
                className="btn2 text-base"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetHero;
