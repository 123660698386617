import React from "react";
import { Helmet } from "react-helmet-async";
import AllAirportBody from "../components/web/allAirports/AllAirportBody";
import AllAirportsHero from "../components/web/allAirports/AllAirportsHero";

const AllAirports = () => {
  return (
    <>
      <Helmet>
        <title>Major airports transfer with professional chauffeurs.</title>
        <meta
          name="description"
          content="Book your airport transfer service from all major Canada, Ontario and Buffalo Intl airports with professional drivers. "
        />
        <meta
          name="keywords"
          content="airport transfer Toronto, airport transfer Montreal, airport transfer Pearson, Airport transfer Buffalo, Airport transfer Niagara Falls, Airport chauffeur Toronto, Airport Chauffeur service, Airport transfer Sudbury, Airport transfer Ottawa, Airport transfer Hamilton, Airport transfer London, Chauffeur service Toronto, Toronto airport transfer, Airport transfer, airport chauffeur service  "
        />
        <link rel="canonical" href="/airports" />
      </Helmet>
      <AllAirportsHero />
      <AllAirportBody />
    </>
  );
};

export default AllAirports;
