import React from "react";
import car from "../../../assets/images/fleets/all-inclusive-limo-service-with-cadillac-xts.png";
import { Link } from "react-router-dom";

const CadillacXTS = () => {
  return (
    <div className="px-3 py-12 sm:py-16 ">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-start">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Achauffeur offers all-inclusive transport services with cadillac vehicles"
            />
          </div>
          <div>
            <h3>Sedan</h3>
            <h4 className="text-pri-500">Cadillac XTS (or similar)</h4>
            <div className="text-base mb-3">
              The Cadillac XTS is an incredibly lavish and luxurious sedan. This
              car has the perfect balance of comfort and luxury.
            </div>
            <div className="grid  gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>
                <div>Up to 3 passengers, 2 comfortable</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>2 large, 2 small luggages</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-settings-3-line  "></i>
                </div>
                <div>Rear passenger controls for comfort and audio</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadillacXTS;
