import React from "react";
import { Helmet } from "react-helmet-async";
import HelpBody from "../components/web/HelpBody";
import HelpHero from "../components/web/HelpHero";

const Help = () => {
  return (
    <>
      <Helmet>
        <title>
          All that you need to know before you book a chauffeur ride.
        </title>
        <meta
          name="description"
          content=" Booking a chauffeur ride is simple and easy, but we want you to make sure that you know all that information before you book."
        />
        <meta
          name="keywords"
          content=" Book a chauffeur ride, chauffeur ride, a chauffeur, a chauffeur ride, chauffeur service, airport limo, airport taxi, shuttle service, chauffeur, a chauffeur help"
        />
        <link rel="canonical" href="/help" />
      </Helmet>
      <HelpHero />
      <HelpBody />
    </>
  );
};

export default Help;
