import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/barrie/holiday-inn-express-and-suites-barrie-chauffeur-service-toronto-limo-services-car-service-near-barrie.jfif";
import one2 from "../../assets/images/hotels/cities/barrie/holiday-inn-express-and-suites-barrie-private-chauffeur-service-barrie-car-service-from-barrie.jfif";
import one3 from "../../assets/images/hotels/cities/barrie/airport-transfer-services-from-holiday-inn-express-and-suites-barrie-best-price-car-services-in-barrie.jfif";
import two1 from "../../assets/images/hotels/cities/barrie/limousine-services-from-four-points-by-sheraton-barrie-car-service-24-hours-in-barrie-best-car-services-in-barrie.webp";
import two2 from "../../assets/images/hotels/cities/barrie/chauffeur-service-in-barrie-to-four-points-by-sheraton-barrie-at-affordable-price-limo-car-service-downtown-barrie.webp";
import two3 from "../../assets/images/hotels/cities/barrie/hourly-chauffeur-service-from-four-points-by-sheraton-barrie-affordable-car-services-near-barrie.webp";
import three1 from "../../assets/images/hotels/cities/barrie/car-service-from-barrie-to-hampton-inn-and-suites-by-hilton-barrie-all-the-way-limo-services-barrie.webp";
import three2 from "../../assets/images/hotels/cities/barrie/executive-chauffeur-service-near-hampton-inn-and-suites-by-hilton-barrie-comfortable-chauffeur-services-in-barrie.webp";
import three3 from "../../assets/images/hotels/cities/barrie/budget-car-hire-barrie-to-hampton-inn-and-suites-by-hilton-barrie-affordable-price-luxury-service-limousines.jpg";
import four1 from "../../assets/images/hotels/cities/barrie/car-hire-near-fairfield-inn-and-suites-by-marriott-barrie-business-class-limo-services.jfif";
import four2 from "../../assets/images/hotels/cities/barrie/all-inclusive-limo-services-near-fairfield-inn-and-suites-by-marriott-barrie-limo-services-online-bookings.jfif";
import four3 from "../../assets/images/hotels/cities/barrie/barrie-limo-services-to-fairfield-inn-and-suites-by-marriott-barrie-car-service-with-driver.jfif";

const Barrie = () => {
  const hero = {
    title: "HIRE A CHAUFFEUR IN BARRIE",
    description:
      <spn>In Barrie, hire a chauffeur with a car, SUV, van, or bus for efficient airport transfers and private rides. Perfect for business travelers and leisure seekers.</spn>,
  };

  const desc = {
    title: "Barrie",
    description:
      <spn>In search of exceptional ground transportation in <b>Barrie</b>? <b>A Chauffeur by Get Limo Inc</b>. is available 24/7, offering a selection of sedans, SUVs, vans, and buses at fixed prices. Whether you are visiting for winter sports or traveling to <b>Toronto Pearson International Airport (YYZ)</b>, our <b>luxury car service in Barrie</b> and <b>chauffeur service in Barrie</b> promise comfort and reliability.</spn>,
    website: "https://www.barrie.ca/",
    description2:
      <spn>We provide <b>corporate transportation in Barrie</b>, <b>executive car service</b>, and <b>private car hire</b> tailored to your needs. For group travel, our <b>Sprinter van for hire in Barrie</b> is a convenient option, and families can utilize our <b>ride with car seats in Barrie</b> for peace of mind. Additionally, we offer <b>long-distance rides from Barrie</b> to ensure a smooth journey to your destination.</spn>,
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from barrie to The Holiday Inn Express & Suites Barrie best car services from achauffeur",
        "Private chauffeur service from barrie city to Holiday Inn Express & Suites Barrie. luxury car service near barrie",
        "Find airport transfer services in barrie to Holiday Inn Express & Suites Barrie at an affordable price and best services",
      ],
      title: "Car Service To Holiday Inn Express & Suites",
      location: "From Toronto Pearson International Airport (YYZ)",
      distance: "84 km Chauffeur ride",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Express%20and%20Suites%20Barrie&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Four Points by Sheraton Barrie to barrie city. Best car services from achauffeur",
        "Safe and secure chauffeur services in barrie to Four Points by Sheraton Barrie. All-inclusive car services downtown barrie",
        "Hourly chauffeur service from achauffeur to Four Points by Sheraton Barrie from barrie canada at affordable price rates",
      ],
      title: "Car Service To Four Points by Sheraton",
      location: "From Billy Bishop Toronto City Airport (YTZ)",
      distance: "107 km Chauffeur ride",
      map: "https://maps.google.com/maps?q=Four%20Points%20by%20Sheraton%20Barrie&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from barrie to Hampton Inn & Suites by Hilton Barrie. Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Hampton Inn & Suites by Hilton Barrie from barrie city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in barrie . Hire budget cars and limos to Hampton Inn & Suites by Hilton Barrie. Luxury service available",
      ],
      title: "Car Service To Hampton Inn & Suites",
      location: "From Buffalo Niagara International Airport (BUF)",
      distance: "251 km Chauffeur ride",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20and%20Suites%20by%20Hilton%20Barrie&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in barrie to Fairfield Inn & Suites by Marriott Barrie. Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from barrie city to Fairfield Inn & Suites by Marriott Barrie",
        "Do you need a car with driver? Achauffeur offers limo services with driver in barrie to Fairfield Inn & Suites by Marriott Barrie",
      ],
      title: "Car Service To Fairfield Inn & Suites",
      location: "From John C. Munro Hamilton International Airport (YHM)",
      distance: "152 km Chauffeur ride",
      map: "https://maps.google.com/maps?q=Fairfield%20Inn%20and%20Suites%20by%20Marriott%20Barrie&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Barrie, ON Starting from at - $165",
      end: "Toronto, Ontario",
      duration: "1 hr 16 min",
      distance: "109 Km",
      price: "211.72",
    },
    {
      start: "Barrie, ON Starting from at - $360",
      end: "Niagara Falls, Ontario",
      duration: "2 hr",
      distance: "210 km",
      price: "211.72",
    },
    {
      start: "Barrie, ON Starting from at - $155",
      end: "Newmarket, Ontario",
      duration: "39   min",
      distance: "54.4 Km",
      price: "211.72",
    },
    {
      start: "Barrie, ON Starting from at - $145",
      end: "Orillia, Ontario",
      duration: "27  min",
      distance: "37.3 Km",
      price: "211.72",
    },
    {
      start: "Barrie, ON Starting from at - $225",
      end: "Collingwood, Ontario",
      duration: "49   min",
      distance: "57.3 Km",
      price: "211.72",
    },
    {
      start: "Barrie, ON Starting from at - $165",
      end: "Toronto Pearson International Airport",
      duration: "50  min",
      distance: "85.9 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Barrie Chauffeur Service | Sprinter Van & Airport Transfer</title>
        <meta
          name="description"
          content="Chauffeur Service In Barrie Offering Sprinter Van Rentals, Airport Transfers, And Long-Distance Rides. Professional Travel Available 24/7."
        />
        <meta
          name="keywords"
          content="Barrie Chauffeur Service, Barrie Airport Transfer, Luxury Car Service Barrie, Sprinter Van For Hire Barrie, Executive Car Service Barrie, Chauffeur Service In Barrie, Private Car Hire Barrie, Barrie Long-Distance Rides, VIP Transportation Barrie, Intercity Transportation Barrie"
        />
        <link rel="canonical" href="/city/chauffeur-service-barrie" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Barrie;
