import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Toast from "../Toast";

const ContactForm = () => {
  const [toastMessage, setToastMessage] = useState({ s: "", t: "" });
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  function handleReset() {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setSubmit(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (name && email && subject && message) {
      setSubmit(true);
      emailjs
        .sendForm(
          "service_y98h98q",
          "template_rj008nl",
          e.target,
          "SJyEAvOtrLyn4Vl93"
        )
        .then(
          (result) => {
            setToastMessage({
              s: "success",
              t: "We have received your message!",
            });
            handleReset();
          },
          (error) => {
            setToastMessage({ s: "error", t: "Message not send! Try again" });
            handleReset();
          }
        );
    } else {
      setError("Fill all fields");
      setToastMessage({ s: "error", t: "Please fill all fields!" });
      setSubmit(false);
    }
  };

  return (
    <>
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 px-3" id="contactUs">
        <div className="container">
          <h2 className="text-center">Write us a message</h2>
          <div className="max-w-3xl mx-auto">
            <form ref={form} onSubmit={sendEmail}>
              <div className="sm:flex sm:gap-5">
                <div className="flex-1">
                  <div className="mb1">Name</div>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                      setError("");
                    }}
                    value={name}
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className="border-2 p-3 rounded-md w-full mb-3 sm:mb-5"
                    style={
                      error && !name
                        ? { borderColor: "#fd4326" }
                        : { borderColor: "black" }
                    }
                  />
                </div>
                <div className="flex-1">
                  <div className="mb1">Email</div>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError("");
                    }}
                    value={email}
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    className="border-2 p-3 rounded-md w-full mb-3 sm:mb-5"
                    style={
                      error && !email
                        ? { borderColor: "#fd4326" }
                        : { borderColor: "black" }
                    }
                  />
                </div>
              </div>
              <div className="mb1">Subject</div>
              <input
                onChange={(e) => {
                  setSubject(e.target.value);
                  setError("");
                }}
                value={subject}
                name="message"
                type="text"
                placeholder="Enter subject"
                className="border-2 p-3 rounded-md w-full mb-3 sm:mb-5"
                style={
                  error && !subject
                    ? { borderColor: "#fd4326" }
                    : { borderColor: "black" }
                }
              />
              <div className="mb1">Message</div>
              <textarea
                onChange={(e) => {
                  setMessage(e.target.value);
                  setError("");
                }}
                value={message}
                placeholder="Enter your message here"
                rows="5"
                className="border-2 p-3 rounded-md w-full mb-3 sm:mb-5"
                style={
                  error && !message
                    ? { borderColor: "#fd4326" }
                    : { borderColor: "black" }
                }
              />
              <div className="flex justify-center">
                <input
                  type="submit"
                  className="btn1 cursor-pointer w-44"
                  value={submit ? "Sending..." : "Send message"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toast message={toastMessage} />
    </>
  );
};

export default ContactForm;
