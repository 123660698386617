import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import Banner2 from "../../components/web/banners/Banner1";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import f1 from "../../assets/images/services/best-vip-car-service-with-driver-affordable-price.jpg";
import f2 from "../../assets/images/services/business-class-vip-chauffeur-services.jpg";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";

const VIPChauffeurService = () => {
  const hero = {
    title: "VIP chauffeur service",
    description:
      <span>Elevate your travel with our <b>VIP chauffeur service</b>: seamless transfers, premium cars, and a warm meet and greet at Toronto airport. Whether it's for a Blue Jays game or any occasion, request a quote for an unmatched journey! Enjoy VIP transport in Toronto with online <b>booking for luxury car service</b>.</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in VIP chauffeur service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: " 24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for VIP chauffeur service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "Luxury and best VIP chauffeur service with driver in affordable price",
    title: "Luxury and Safety: VIP Chauffeur Services",
    description:
      <span>Achauffeur, synonymous with luxury and sophistication, joins the ranks of <b>VIP Chauffeur Services</b> in redefining transportation excellence. Whether you seek a VIP chauffeur, access to our fleet of luxurious vehicles, executive transport solutions, or the personalized care of a private chauffeur, we cater to your every desire. Your safety and security remain paramount in our mission. Benefit from an online estimate backed by our best price guarantee. From seamless airport transfers to corporate chauffeur services, from creating elegant wedding memories to orchestrating impeccable event transportation, we craft indelible experiences marked by elegance and comfort. Our team of consummate professional drivers spearheads our high-end car service, encompassing a diverse fleet featuring luxury shuttles, black cars, and limousines. Beyond transportation, we excel in curating exclusive, elegant experiences, all centered on your safety and contentment. For the epitome of <b>VIP ground transportation</b>, choose VIP Chauffeur Services, now joined by Achauffeur in elevating your journey to new heights of luxury.</span>,
  };
  const feature2 = {
    image: f2,
    alt: "Hassle-free business class vip  chauffeur service for your business trip",
    title: "Worldwide VIP Chauffeur Excellence ",
    description:
      <span>Step into a world of unparalleled luxury and sophistication with our <b>VIP limo service</b>. Our commitment to excellence is at the heart of everything we do, ensuring that every journey is not just punctual but truly unforgettable. Whether you require airport transfers, executive meetings, or special occasion transportation, our <b>VIP chauffeur hire</b> and VIP car service will redefine your expectations of VIP travel. We take pride in setting the standard for global VIP service, and with our exquisite platinum rides, private car service, black car service, and VIP limousine service options, including sedans, vans, SUVs, and buses, we aim to elevate your travel experience to new heights. Your journey begins today, so contact us to experience the epitome of worldwide chauffeur excellence and get an estimate online.</span>,
  };

  return (
    <>
      <Helmet>
        <title>Book Your Private VIP Chauffeur Service | AChauffeur.com</title>
        <meta
          name="description"
          content=" Looking For A Luxury VIP Chauffeur Service? Book online 24/7. Safe and Reliable Chauffeur Service For Your Leisure, Business and Events. Best Price Guaranteed

  "
        />
        <meta
          name="keywords"
          content="VIP chauffeur service, VIP chauffeur, VIP chauffeur hire, VIP luxury chauffeur, Reliable Chauffeur Service for business travel, Private Chauffeur Service, VIP car service in Toronto, VIP Limousine Service in Toronto, VIP airport chauffeur Service, VIP Chauffeur Service Toronto, VIP Chauffeur Service Toronto"
        />
        <link rel="canonical" href="/services/vip-chauffeur-service" />
      </Helmet>
      <ServiceHero hero={hero} />

      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default VIPChauffeurService;
