import React from "react";
import { Helmet } from "react-helmet-async";
import AboutHero from "../components/web/about/AboutHero";
import Banner3 from "../components/web/banners/Banner3";

import Testimonials from "../components/web/Testimonials";
import Way from "../components/web/Way";
import WhyChooseUs from "../components/web/WhyChooseUs";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About A chauffeur - Upgrade your transfer services.</title>
        <meta
          name="description"
          content="Learn more about driver transfer solutions, exclusivity, vehicles, professionalism and services. "
        />
        <meta
          name="keywords"
          content=" Airport transfer, transfer, a chauffeur, transfer solutions, airport limo, limousine service, transfort solution, airport car service, black car service, transportation service in toronto, chauffeur service in Toronto"
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <AboutHero />
      <Way />
      <WhyChooseUs />
      <Banner3 />
      <Testimonials />
    </>
  );
};

export default AboutUs;
