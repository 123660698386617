import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner2";

// stay images
import one1 from "../../assets/images/hotels/airports/londonInternational/double-tree-by-hilton-hotel-chauffeur-service-london-international-airport.webp";
import one2 from "../../assets/images/hotels/airports/londonInternational/double-tree-by-hilton-hotel-private-chauffeur-service-london-international-airport.webp";
import one3 from "../../assets/images/hotels/airports/londonInternational/double-tree-by-hilton-hotel-airport-car-service-london-international-airport.webp";
import two1 from "../../assets/images/hotels/airports/londonInternational/chauffeur-service-in-london-international-airport-to-delta-hotels-by-marriott-london-armouries.webp";
import two2 from "../../assets/images/hotels/airports/londonInternational/achauffeur-airport-transfer-to-delta-hotels-by-marriott-london-armouries.webp";
import two3 from "../../assets/images/hotels/airports/londonInternational/car-service-from-london-international-airport-to-delta-hotels-by-marriott-london-armouries.webp";
import three1 from "../../assets/images/hotels/airports/londonInternational/executive-chauffeur-service-from-london-international-airport-to-best-western-plus-lamplighter-inn-and-conference-centre.jpg";
import three2 from "../../assets/images/hotels/airports/londonInternational/hourly-chauffeur-service-at-london-international-airport-tobest-western-plus-lamplighter-inn-and-conference-centre.jpg";
import three3 from "../../assets/images/hotels/airports/londonInternational/chauffeur-service-near-london-international-airport-to-best-western-plus-lamplighter-inn-and-conference-centre.jpg";
import four1 from "../../assets/images/hotels/airports/londonInternational/limo-service-at-london-international-airport-to-hampton-inn-by-hilton-london.webp";
import four2 from "../../assets/images/hotels/airports/londonInternational/car-hire-at-london-international-airport-to-hampton-inn-by-hilton-london.jpg";
import four3 from "../../assets/images/hotels/airports/londonInternational/budget-car-hire-london-international-airport-to-hampton-inn-by-hilton-london.webp";
import { Helmet } from "react-helmet-async";

const LondonInternational = () => {
  const hero = {
    title: "London International Airport (YXU)",
    description:
      "To get your trip off to the perfect start, AChauffeur provides an affordable and convenient airport transfer service from London International Airport. To ensure safe and comfortable rides, we meticulously maintain our fleet of vehicles.",
  };
  const desc = {
    title: "London International Airport",
    description:
      "London International Airport is the easy and comfortable airport. From their airline partners to their tenants, they provide the support and infrastructure necessary to foster economic vitality in their aviation community and beyond. They do this while keeping things easy and simple, from concessions to leases, to operations, and across all areas of their business. ",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from London International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle = "Stay at top unique properties near London, ON Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the London International Airport to DoubleTree by Hilton Hotel. Book with affordable price",
        "Private chauffeur service from London International Airport  to DoubleTree by Hilton Hotel. Book online",
        "Airport car service to  DoubleTree by Hilton Hotel from London International Airport",
      ],
      rating: "8.5",
      title: "DoubleTree by Hilton Hotel",
      location: "London Ontario",
      distance: "11.4 km from airport",
      map: "https://maps.google.com/maps?q=DoubleTree%20by%20Hilton%20Hotel%20London%20Ontario&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Delta Hotels by Marriott London Armouries will take you to and from London International Airport .",
        "Search, compare and book London International Airport   transfers with a chauffeur from Delta Hotels by Marriott London Armouries",
        "book your private car service from London International Airport  to Delta Hotels by Marriott London Armouries",
      ],
      rating: "7.5",
      title: "Delta Hotels by Marriott",
      location: "London Ontario",
      distance: "11.1 km from airport",
      map: "https://maps.google.com/maps?q=Delta%20Hotels%20by%20Marriott%20London%20Armouries&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from  London International Airport To Best Western Plus Lamplighter Inn & Conference Centre",
        "Book your Luxury Chauffeur from Best Western Plus Lamplighter Inn & Conference Centre to London International Airport ",
        "Chauffeur service at London International Airport  to Best Western Plus Lamplighter Inn & Conference Centre",
      ],
      rating: "7.5",
      title: "Best Western Plus",
      location: "London Ontario",
      distance: "17.5 km from airport",
      map: "https://maps.google.com/maps?q=Best%20Western%20Plus%20Lamplighter%20Inn%20&%20Conference%20Centre&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from London International Airport  to Hampton Inn by Hilton London",
        "Find cheep and luxury cars at London International Airport  to Hampton Inn by Hilton London",
        "All inclusive budget car services from London International Airport  to Hampton Inn by Hilton London",
      ],
      rating: "6.5",
      title: "Hampton Inn London ON",
      location: "16 Madison Avenue, M5R 2S1 Toronto, Canada",
      distance: "18.5 km from airport",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20by%20Hilton%20London&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "London International Airport",
      end: "Windsor, Ontario",
      duration: "2 hr 1 min",
      distance: "202 km",
      price: "211.72",
    },
    {
      start: "London International Airport",
      end: "Toronto, Ontario",
      duration: "2 hr 5 min",
      distance: "183 km",
      price: "211.72",
    },
    {
      start: "London International Airport",
      end: "Niagara Falls, Ontario",
      duration: "1 hr 54 min",
      distance: "198 km",
      price: "211.72",
    },
    {
      start: "London International Airport",
      end: "Waterloo, Ontario",
      duration: "1 hr 11 min",
      distance: "113 km",
      price: "211.72",
    },
    {
      start: "London International Airport",
      end: " Sarnia, Ontario",
      duration: "1 hr 12 min",
      distance: "123 km",
      price: "211.72",
    },
    {
      start: "London International Airport",
      end: "London, Ontario",
      duration: "18 min",
      distance: "11 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          A Chauffeur Service at London Ontario International Airport
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service To/from London,ON Airport. Best Price Guaranteed..
          "
        />
        <meta
          name="keywords"
          content="Personal Chauffeur Service, Chauffeur Service at London Airport, Airport Chauffeur Service, Chauffeur at London Airport, Chauffeur Services at London, Chauffeur Service from london, Chauffeur Service to london Airport, Chauffeur to london Airport, Chauffeur from london Airport, Airport Chauffeur, Chauffeur in london Airport, Airport Limo, Airport taxi, Airport Shuttle, Private car service"
        />
        <link
          rel="canonical"
          href="/airport/london-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default LondonInternational;
