import React from "react";
import { Link } from "react-router-dom";

const Banner3 = () => {
  return (
    <div className="banner3--cover">
      <div className="promise px-3 py-8 sm:py-8">
        <div className="container">
          <div className="md:grid grid-cols-2">
            <div className="">
              <h2 className="text-white">
                Punctual and Reliable services from us
              </h2>
              <div className="text-base mb-5 -mt-2 text-white">
                We endeavor to always arrive ahead of nominated client pick up
                time, thus providing our clients with a seamless and timely
                transfer as needed.
              </div>
              <Link to="/book-now-a-chauffeur-service" className="btn1">
                Get an Estimate online
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner3;
