import React from "react";
import car from "../../../assets/images/fleets/business-class-limo-service-with-bmw-vehicles.png";
import { Link } from "react-router-dom";

const BMW7Series = () => {
  return (
    <div className="px-3 py-12 sm:py-16 bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10  lg:gap-20 items-center">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Book your business class limo services with bmw vehicles"
            />
          </div>
          <div>
            <h3>Business Class</h3>
            <h4 className="text-pri-500">BMW 5 series (or similar)</h4>
            <div className="text-base mb-3">
              A modern and luxurious option with a spacious interior, the BMW is
              the perfect choice for your next chauffeur ride
            </div>
            <div className="grid sm:grid-cols-2 gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>

                <div>Up to 3 passengers, 2 comfortable</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>2 large, 2 small luggages</div>
              </div>

              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-space"></i>
                </div>
                <div>Extra leg room</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-fire-line  "></i>
                </div>
                <div>Heated seats</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-surround-sound-line  "></i>
                </div>
                <div>Surround sound system and ambient light design</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BMW7Series;
