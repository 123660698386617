import React from "react";
import { Link } from "react-router-dom";

import niagara from "../../assets/images/best-limo-service-at-niagara-falls-timely-transportation-from-achauffeur.jpg";
import cn from "../../assets/images/cn-tower-limo-services-start-your-journey-with-achauffeur-we-have-professional-drivers.jpg";
import thousand from "../../assets/images/chauffeur-service-at-thousand-island-highest-customer-satisfaction.jpg";
import tobermory from "../../assets/images/budget-car-hire-from-tobermory-best-car-service-with-driver.jpg";

const TouristAttractions = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 items-center gap-5 lg:gap-10">
          <div>
            <h2>Tourist Attractions</h2>
            <div className="-mt-2 text-base mb-3">
              Experience the Spectacular Beauty Worldwide - Vibrant Cities, Majestic Niagara Falls,
              Enchanting 1000 Islands, and More! Don't Miss Out on the Best Tourist Attractions
              for an Unforgettable Experience. Discover with Our Exceptional Car Service, 
              Charter Bus Tours, <a href="https://achauffeur.com/services/meet-and-greet-service"><b>Meet and Greet Service at the Airport</b></a>, and Hourly Car Service!
              Plus, explore the world at your pace with services like <a href="https://achauffeur.com/book-now-a-chauffeur-service"><b>hiring a private driver</b></a>, 
              van rentals with drivers, car and driver services, chauffeur-driven cars, and more.
              Your global adventure begins with us.
            </div>
            <div className=" text-base mb-5">
              <b>"A Chauffeur"</b> has a guidance team with professional drivers
              to help you throughout your trip.
            </div>
            <Link to="/tourist-attractions" className="btn1">
              View all Attractions
            </Link>
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-5">
            <Link
              to="/tourist-attraction/chauffeur-service-to-niagara-falls"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={niagara}
                  alt="chauffer"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> Niagara Falls</div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/tourist-attraction/chauffeur-service-to-cn-tower"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={cn}
                  alt="chauffer"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> CN Tower</div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/tourist-attraction/chauffeur-service-to-thousand-islands"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={thousand}
                  alt="chauffer"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> Thousand Island</div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/tourist-attraction/chauffeur-service-to-tobermory"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={tobermory}
                  alt="chauffer"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> Tobermory</div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TouristAttractions;
