import React from "react";
import { Helmet } from "react-helmet-async";
import AllAttractionsBody from "../components/web/allAttractions/AllAttractionsBody";
import AllAttractionsHero from "../components/web/allAttractions/AllAttractionsHero";

const AllAttractions = () => {
  return (
    <>
      <Helmet>
        <title>Private chauffeur service for sightseeing in Toronto, ON</title>
        <meta
          name="description"
          content=" Want to visit tourist attractions in Ontario with the help of private chauffeurs? Book your trips with us 24/7."
        />
        <meta
          name="keywords"
          content=" Private chauffeurs, private chauffeur service, Professional chauffeur service, chauffeur service for sightseeing, chauffeur service to Toronto, chauffeur service to Niagara Falls, Chauffeur service for Toronto, chauffeur service to Blue Mountain, chauffeur service to Muskoka"
        />
        <link rel="canonical" href="/tourist-attractions" />
      </Helmet>
      <AllAttractionsHero />
      <AllAttractionsBody />
    </>
  );
};

export default AllAttractions;
