import React from "react";
import { Link } from "react-router-dom";

import niagara from "../../../assets/images/best-limo-service-at-niagara-falls-timely-transportation-from-achauffeur.jpg";
import cn from "../../../assets/images/cn-tower-limo-services-start-your-journey-with-achauffeur-we-have-professional-drivers.jpg";
import thousand from "../../../assets/images/chauffeur-service-at-thousand-island-highest-customer-satisfaction.jpg";
import muskoka from "../../../assets/images/hourly-chauffeur-service-from-muskoka-district-municipality-premium-quality-chauffeur-services.jpg";
import Algonquin from "../../../assets/images/limo-service-near-algonquin-provincial-park-24-hours-customer-support-available.jpg";
import Tobermory from "../../../assets/images/budget-car-hire-from-tobermory-best-car-service-with-driver.jpg";
import blueResort from "../../../assets/images/comfortable-chauffeur-services-from-blue-mountain-ski-resort-with-first-class-limousines.jpg";
import manitoulin from "../../../assets/images/best-limo-services-from-manitoulin-island-enjoy-a-pleasant-journey-with-achauffeur.jpg";
import blueVillage from "../../../assets/images/chauffeur-services-from-blue-mountain-village-with-well-trained-drivers-at-affordable-price.jpg";
import bruce from "../../../assets/images/business-class-chauffeur-services-from-bruce-peninsula-national Park-high-luxury-and-hygienic-limousines.jpg";
import royal from "../../../assets/images/car-services-from-royal-ontario-museum-falls-book-your-reservations-online-safe-and-secure-chauffeur-service.jpg";
import wonderland from "../../../assets/images/chauffeur-service-from-canada’s-wonderland-stress-free-and-reliable-car-services-at-best-price.jpg";
import { ArrowRightOutlined } from "@ant-design/icons";

const AllAttractionsBody = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <h2 className="text-center">Tourist Attractions</h2>
        <div className="grid md:grid-cols-3 gap-5 lg:gap-10">
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={niagara} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Niagara Falls</h4>
              <div className="  pb-2">
                Niagara Falls is a group of three waterfalls located on the
                Niagara River in western New York state and southeastern
                Ontario, Canada. The falls are one of the most popular...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-niagara-falls"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={cn} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> CN Tower</h4>
              <div className="  pb-2">
                The CN Tower is a communications and observation tower located
                in Toronto, Ontario, Canada. It is the tallest free-standing
                structure in the Western Hemisphere, standing...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-cn-tower"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={thousand} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Thousand Island</h4>
              <div className="  pb-2">
                The Thousand Islands is a group of more than 1,800 islands
                located in the Saint Lawrence River, which forms part of the
                border between the United States and Canada...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-thousand-islands"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={muskoka} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Muskoka District Municipality</h4>
              <div className="  pb-2">
                Muskoka District Municipality is a regional municipality located
                in central Ontario, Canada. It is situated in the heart of the
                Muskoka region, a popular vacation...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-muskoka-district-municipality"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={Algonquin} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Algonquin Provincial Park</h4>
              <div className="  pb-2">
                Algonquin Provincial Park is a large provincial park located in
                Central Ontario, Canada. It is the oldest and one of the largest
                provincial parks in the country, covering an area...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-algonquin-provincial-park"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={Tobermory} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Tobermory</h4>
              <div className="  pb-2">
                Tobermory is a small town located on the Bruce Peninsula in
                Ontario, Canada, known for its natural beauty and outdoor
                recreational opportunities. The town is the main...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-tobermory"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={blueResort} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Blue Mountain Ski Resort</h4>
              <div className="  pb-2">
                Blue Mountain Ski Resort is a ski resort located in the Blue
                Mountains of Ontario, Canada. It is one of the largest ski
                resorts in Eastern North America and is a popular destination
                for...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-blue-mountain-ski-resort"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={manitoulin} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto">Manitoulin Island</h4>
              <div className="  pb-2">
                Manitoulin Island is the largest freshwater island in the world,
                located in Lake Huron in the province of Ontario, Canada. The
                island is home to a number of small communities and...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-manitoulin-island"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={blueVillage} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto">Blue Mountain Village</h4>
              <div className="  pb-2">
                Blue Mountain Village is a resort town located in the Blue
                Mountains of Ontario, Canada. It is located at the base of the
                Blue Mountain Ski Resort and is a popular destination for...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-blue-mountain-village"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={bruce} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto">Bruce Peninsula National Park</h4>
              <div className="  pb-2">
                Bruce Peninsula National Park is a national park located in the
                Bruce Peninsula of Ontario, Canada. It is situated on the
                Niagara Escarpment, a UNESCO World...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-bruce-peninsula-national-park"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={royal} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto">Royal Ontario Museum</h4>
              <div className="  pb-2">
                The Royal Ontario Museum (ROM) is a museum of art, culture, and
                natural history located in Toronto, Ontario, Canada. It is one
                of the largest museums in North America and...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-royal-ontario-museum"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img className="h-32 w-auto " src={wonderland} alt="chauffer" />
            <div className="p-3 mt-3">
              <h4 className="roboto">Canada's Wonderland</h4>
              <div className="  pb-2">
                Canada's Wonderland is an amusement park located in Vaughan,
                Ontario, Canada. It is the largest amusement park in Canada and
                is owned and operated by Cedar Fair...
              </div>
              <Link
                to="/tourist-attraction/chauffeur-service-to-canadas-wonderland"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAttractionsBody;
