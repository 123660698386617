import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";

// stay images
import one1 from "../../assets/images/hotels/airports/montrealPierre/sheraton-montreal-airport-hotel-chauffeur-service-montreal-pierre-elliott-trudeau-international-airport.webp";
import one2 from "../../assets/images/hotels/airports/montrealPierre/sheraton-montreal-airport-hotel-private-chauffeur-service-montreal-pierre-elliott-trudeau-international-airport.webp";
import one3 from "../../assets/images/hotels/airports/montrealPierre/sheraton-montreal-airport-hotel-car-service-montreal-pierre-elliott-trudeau-international-airport.webp";
import two1 from "../../assets/images/hotels/airports/montrealPierre/chauffeur-service-in-montreal-pierre-elliott-trudeau-international-airport-to-montreal-airport-marriott-in-terminal-hotel.webp";
import two2 from "../../assets/images/hotels/airports/montrealPierre/achauffeur-airport-transfer-to-montreal-airport-marriott-in-terminal-hotel.webp";
import two3 from "../../assets/images/hotels/airports/montrealPierre/car-service-from-montreal-pierre-elliott-trudeau-international-airport-to-montreal-airport-marriott-in-terminal-hotel.webp";
import three1 from "../../assets/images/hotels/airports/montrealPierre/executive-chauffeur-service-from-montreal-pierre-elliott-trudeau-international-airport-to-doubletree-by-hilton-montreal-airport.webp";
import three2 from "../../assets/images/hotels/airports/montrealPierre/hourly-chauffeur-service-at-montreal-pierre-elliott-trudeau-international-airport-to-doubletree-by-hilton-montreal-airport.webp";
import three3 from "../../assets/images/hotels/airports/montrealPierre/chauffeur-service-near-montreal-pierre-elliott-trudeau-international-airport-to-doubletree-by-hilton-montreal-airport.webp";
import four1 from "../../assets/images/hotels/airports/montrealPierre/limo-service-at-montreal-pierre-elliott-trudeau-international-airport-to-hotel-novotel-montréal-aéroport.jpg";
import four2 from "../../assets/images/hotels/airports/montrealPierre/car-hire-at-montreal-pierre-elliott-trudeau-international-airport-to-hotel-novotel-montréal-aéroport.jpg";
import four3 from "../../assets/images/hotels/airports/montrealPierre/budget-car-hire-montreal-pierre-elliott-trudeau-international-airport-to-hotel-novotel-montréal-aéroport.jpg";
import { Helmet } from "react-helmet-async";

const MontrealPierre = () => {
  const hero = {
    title: "Montreal Pierre Elliott Trudeau International Airport",
    description:
      "If you wish to begin your adventure with a pleasant atmosphere, AChauffeur offers luxurious, hassle-free airport transportation from and to Montreal Pierre Elliott Trudeau International Airport. We ensure your secure and peaceful journey with one of our experienced chauffeurs.",
  };
  const desc = {
    title: "Montreal Pierre Elliott Trudeau International Airport",
    description:
      "It is the only Transport Canada designated international airport serving Montreal and is situated 20 km (12 mi) west of Downtown Montreal. The airport terminals are located entirely in the suburb of Dorval, while one runway is located in the Montreal borough of Saint-Laurent.",
    website: "https://www.admtl.com/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Montreal Pierre Elliott Trudeau International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle = "Stay at top unique properties near Montreal Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Montreal Pierre Elliott Trudeau International Airport to Sheraton Montreal Airport Hotel. Book with affordable price",
        "Private chauffeur service from Montreal Pierre Elliott Trudeau International Airport to Sheraton Montreal Airport Hotel. Book online",
        "Airport car service to Sheraton Montreal Airport Hotel from Montreal Pierre Elliott Trudeau International Airport",
      ],
      rating: "8.5",
      title: "Sheraton Montreal Airport Hotel",
      location: "location",
      distance: "900 m from airport",
      map: "https://maps.google.com/maps?q=Sheraton%20Montreal%20Airport%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Montreal Airport Marriott In-Terminal Hotel will take you to and from Montreal Pierre Elliott Trudeau International Airport",
        "Search, compare and book Montreal Pierre Elliott Trudeau International Airport transfers with a chauffeur from Montreal Airport Marriott In-Terminal Hotel",
        "book your private car service from Montreal Pierre Elliott Trudeau International Airport  to Montreal Airport Marriott In-Terminal Hotel",
      ],
      rating: "7.5",
      title: "Montreal Airport Marriott In-Terminal Hotel",
      location: "location",
      distance: "1.0 km from airport",
      map: "https://maps.google.com/maps?q=Montreal%20Airport%20Marriott%20In-Terminal%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from Montreal Pierre Elliott Trudeau International Airport To DoubleTree by Hilton Montreal Airport",
        "Book your Luxury Chauffeur from DoubleTree by Hilton Montreal Airport to Montreal Pierre Elliott Trudeau International Airport",
        "Chauffeur service at Montreal Pierre Elliott Trudeau International Airport  to DoubleTree by Hilton Montreal Airport",
      ],
      rating: "7.5",
      title: "DoubleTree by Hilton Montreal Airport",
      location: "location",
      distance: "1.8 km from airport",
      map: "https://maps.google.com/maps?q=DoubleTree%20by%20Hilton%20Montreal%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Montreal Pierre Elliott Trudeau International Airport to Hotel Novotel Montréal Aéroport",
        "Find cheep and luxury cars at Montreal Pierre Elliott Trudeau International Airport to Hotel Novotel Montréal Aéroport",
        "All inclusive budget car services from Montreal Pierre Elliott Trudeau International Airport to Hotel Novotel Montréal Aéroport",
      ],
      rating: "6.5",
      title: "Hotel Novotel Montréal Aéroport",
      location: "location",
      distance: "9.1 km from airport",
      map: "https://maps.google.com/maps?q=Hotel%20Novotel%20Montr%C3%A9al%20A%C3%A9roport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: "Montreal, Quebec",
      duration: "23 min",
      distance: "18.8 km",
      price: "211.72",
    },
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: " Laval, Quebec",
      duration: "23 min",
      distance: "28.6 km",
      price: "211.72",
    },
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: "Quebec City Area, Quebec",
      duration: "2 hr 52 min",
      distance: "291 km",
      price: "211.72",
    },
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: "Ottawa, Ontario",
      duration: "1 hr 48 min",
      distance: "183 km",
      price: "211.72",
    },
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: "Toronto, Ontario",
      duration: "5 hr 2 min",
      distance: "526 km",
      price: "211.72",
    },
    {
      start: "Montreal Pierre Elliott Trudeau International Airport",
      end: "Trois-Rivières, Quebec",
      duration: "1 hr 30 min",
      distance: "149 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book Online. Private Chauffeur service at Montreal Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24hrs, Pre-Book Online. Reliable and Efficient Chauffeur Service to/from Montreal Airport. Best Price Guaranteed.

      "
        />
        <meta
          name="keywords"
          content="airport chauffeur service, Montreal chauffeur service, airport chauffeur, private chauffeur, chauffeur service, chauffeur service near me, chauffeur driver, personal chauffeur, executive car service, chauffeur for hire, luxury chauffeur service
          private chauffeur service, Airport Car service, Airport Limo service, Airport Chauffeur Montreal, Montreal Airport Chauffeur
          "
        />
        <link
          rel="canonical"
          href="/airport/montreal-pierre-elliott-trudeau-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default MontrealPierre;
