import React from "react";

import Banner2 from "../../components/web/banners/Banner1";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
import f1 from "../../assets/images/all-inclusive-intercity-transport-services-to-optimize-your-travel-time.jpg";
import f2 from "../../assets/images/affordable-intercity-transport-services.jpg";
import { Helmet } from "react-helmet-async";

const IntercityRides = () => {
  const hero = {
    title: "Intercity Chauffeured Rides",
    description:
      <span>Intercity Chauffeur offers luxury and flexibility with premium airport car service, <b>black car rides</b>, and hourly pick-up and drop-off options in Major Cities worldwide. Enjoy a seamless and opulent travel experience to and from airports, all with our best price guarantee.</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in intercity chauffeured rides",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for intercity chauffeured rides",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };

  const feature1 = {
    image: f1,
    alt: "Optimize your time with all inclusive intercity chauffeured rides from a chauffeur.",
    title: "Your Premier Choice for Intercity Luxury Travel ",
    description:
      <span>At Achauffeur.com, we specialize in providing exceptional <b>intercity chauffeured rides</b> that seamlessly blend luxury, convenience, and reliability. Whether you require car hire with a driver for a long-distance journey or a minibus with an experienced <b>chauffeur for group travel</b>, our services are designed to meet your specific needs. With a personal driver at your disposal, you can sit back and relax while we handle the details of your intercity travel, ensuring a comfortable and stress-free experience. When you choose Achauffeur.com for your intercity travels, you're not just hiring a driver; you're accessing a network of professional chauffeurs who excel in navigating the highways and byways, ensuring you arrive at your destination safely and on time. Our commitment to excellence extends to every aspect of your journey, making us your premier choice for intercity transportation.</span>,
  };
  const feature2 = {
    image: f2,
    alt: "A chauffeur Minimize your Stress and Frustration during intercity transportation.",
    title: "Hire a Chauffeur Today!",
    description:
      <span>Achauffeur offers a premium transportation experience meticulously tailored to your specific needs. Whether you're in search of <b>a chauffeur for a special occasion</b>, a driver for a day, or personal drivers for hire, our services are designed to deliver luxury, convenience, and reliability. With our fleet of meticulously maintained chauffeur-driven cars and readily available drivers nearby, every journey is characterized by comfort and professionalism. Our diverse selection of well-maintained vehicles, from private car hire with drivers to minibus and van rentals, caters to a range of preferences and occasions, including city-to-city car services. We are dedicated to making long-distance travel stress-free and convenient, offering transparent pricing options for both short-term and extended chauffeur services. Achauffeur is your ultimate choice for weddings, executive transportation, VIP events, and more. Our drivers are readily available and supported by a dedicated team of professionals, establishing us as your trusted partner for a chauffeur-driven experience that goes beyond ordinary transportation. Whether you have your sights set on a chauffeur-driven Sprinter van, a van rental with a driver, or any other customized service, Achauffeur is your destination for luxury, reliability, and exceptional service. Your extraordinary journey begins here.</span>,
  };

  return (
    <>
      <Helmet>
        <title>Book Your City-To-City chauffeur Service | AChauffeur.com</title>
        <meta
          name="description"
          content="A Chauffeur Service. 24/7 Pre-Book Online. Safe, Stress-free and Reliable Intercity Chauffeur Services for your business and leisure. Best Price Guaranteed.
  "
        />{" "}
        <meta
          name="keywords"
          content=" Chauffeur, A Chauffeur, Chauffeur service, Chauffeur driver, Intercity chauffeur service, Reliable chauffeur service, Chauffeur service near me, Blacklane car service, Chauffeur services, Achauffeur "
        />
        <link rel="canonical" href="/services/intercity-chauffeured-rides" />
      </Helmet>
      <ServiceHero hero={hero} />
      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default IntercityRides;
