import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="bg-sec-700 px-3 py-8 sm:py-8 md:py-12 lg:py-16">
        <div className="container grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div>
            <div className="roboto text-lg md:text-xl text-white mb-2">
              Explore
            </div>
            <ul>
              <li>
                <Link
                  to="/services/intercity-chauffeured-rides"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Intercity chauffeured rides
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/airport-transfer"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Airport transfer
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/event-limousine-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Event limousine service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/vip-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    VIP chauffeur service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/private-car-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Private car service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/ground-transportation"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Ground transportation
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/meet-and-greet-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Meet and greet service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/parcel-delivery-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Parcel delivery service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/winery-tour-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Winery tour service
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/services/charter-bus-tour"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Charter bus tours
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className="roboto text-lg md:text-xl text-white mb-2">
              Major airports
            </div>
            <ul>
              <li>
                <Link
                  to="airport/toronto-pearson-international-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Toronto Pearson International Airport (YYZ)
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="airport/billy-bishop-toronto-city-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Billy Bishop Toronto City Airport (YTZ)
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="airport/london-international-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    London International Airport (YXU)
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="airport/john-c.-munro-hamilton-international-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    John C. Munro Hamilton International Airport(YHM)
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="airport/buffalo-niagara-international-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Buffalo Niagara International Airport (BUF)
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="airport/ottawa-mcdonald-cartier-international-airport-chauffeur-service"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Ottawa Mcdonald-cartier International Airport (YOW)
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className="roboto text-lg md:text-xl text-white mb-2">
              Popular cities
            </div>
            <ul>
              <li>
                <Link
                  to="/city/chauffeur-service-toronto"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Toronto
                  </div>
                </Link>
              </li>

              <li>
                <Link
                  to="/city/chauffeur-service-nigara-falls"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Niagara Falls
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-buffalo-nyc"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Buffalo, NYC
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-hamilton"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Hamilton
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-markham"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Markham
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-london"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    London
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-kitchener"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Kitchener
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-peterborough"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Peterborough
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-oshawa"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Oshawa
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/city/chauffeur-service-whitby"
                  className="inline-block py-1 text-sm text-sec-300 hover:text-pri-500"
                >
                  {" "}
                  <div className="flex items-start ">
                    <i className="ri-arrow-right-s-line"></i>
                    Whitby
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className="mb-8">
              <div className="roboto text-lg md:text-xl text-white mb-2">
              <Link
              to="/book-now-a-chauffeur-service"
              className="btn1   text-base  cursor-pointer"
            >
              Hire A Chauffeur Online
            </Link>
              </div>
              <div className="text-sm  text-sec-300">
                A Chauffeur: A global leader in luxury transportation for nearly a 
                decade. We blend safety and convenience with our diverse fleet,  
                including sedans, SUVs, vans, and buses. From top-tier airport  
                transfers to private car services and bespoke hourly rides, we excel. 
                reliability to your journey, whether it's an airport transfer or any
                occasion. Thank you for choosing A Chauffeur.
              </div>
            </div>
            <div>
              {" "}
              <div className="roboto text-lg md:text-xl text-white mb-3">
                Connect with us
              </div>
              <div className="flex gap-2">
                <a
                  href="https://www.facebook.com/profile.php?id=100089088143832"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-sec-500 hover:bg-blue-100 w-10 h-10 rounded-full flex items-center justify-center  mr-1 hover:text-white"
                >
                  <i className="ri-facebook-fill text-xl"></i>
                </a>
                <a
                  href="https://twitter.com/Achauffeurlimo"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-sec-500 hover:bg-blue-200 w-10 h-10 rounded-full flex items-center justify-center  mx-1 hover:text-white"
                >
                  <i className="ri-twitter-fill text-xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/achauffeurlimos/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-sec-500 hover:bg-purple-100 w-10 h-10 rounded-full flex items-center justify-center  mx-1 hover:text-white"
                >
                  <i className="ri-instagram-fill text-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UClftjIYKDx03zl3hDHyRF0Q"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-sec-500 hover:bg-red-100 w-10 h-10 rounded-full flex items-center justify-center  ml-1 hover:text-white"
                >
                  <i className="ri-youtube-fill text-xl"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="container text-sec-300 flex flex-col md:flex-row gap-1 md:justify-between items-center px-3 py-5">
          <div className="flex gap-5 items-center">
            <Link
              to="/terms"
              className="inline-block  text-sec-300 hover:text-pri-500"
            >
              Terms
            </Link>
            <Link
              to="/privacy-policy"
              className="inline-block  text-sec-300 hover:text-pri-500"
            >
              Privacy policy
            </Link>
            <Link
              to="/help"
              className="inline-block  text-sec-300 hover:text-pri-500"
            >
              Help
            </Link>
          </div>
          <div> Copyright &copy; Achauffeur. All rights reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
