import React from "react";
import star from "../../assets/icons/customer-service.png";
import lisencedDriver from "../../assets/icons/chauffeur.png";
import car from "../../assets/icons/car.png";
import paymentSecurity from "../../assets/icons/secure-payment.png";
import customerSupport from "../../assets/icons/customer-service2.png";
import confirmation from "../../assets/icons/order.png";
const WhyChooseUs = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <h2 className="text-center">Why Choose Us</h2>
        <div className="text-center text-lg md:text-xl max-w-3xl mx-auto pb-5 md:pb-10">
          We are unique in the industry. Because we provide high-quality limo
          services to our customers with the help of well-trained chauffeurs and
          our managing team.
        </div>
        <div className="grid lg:grid-cols-2 gap-5 ">
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className=" about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={confirmation}
                alt="achauffeur confirm your booking immediately before you travel"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>
            <div className="text-center sm:text-left">
              <h3>Pre-journey booking confirmation</h3>
              <div className="text-base">
                We respond to your booking immediately once you make a booking
                via our website or call.
              </div>
            </div>
          </div>
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className=" about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={star}
                alt="achauffeur offers high quality limo services at affordable price"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>
            <div className="text-center sm:text-left">
              <h3>Quality service</h3>
              <div className="text-base">
                Our services are high quality, and we handle our customer's
                friendly
              </div>
            </div>
          </div>
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className=" about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={lisencedDriver}
                alt="acauffeur has well trained professional chauffeurs for your ride"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>
            <div className="text-center sm:text-left">
              <h3>Licensed chauffeurs</h3>
              <div className="text-base">
                We have only well-trained and licensed chauffeurs so you can
                travel safely
              </div>
            </div>
          </div>
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className=" about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={car}
                alt="hire luxury limos at achauffeur comfortable and high quality"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>
            <div className="text-center sm:text-left">
              <h3>Luxury vehicles</h3>
              <div className="text-base">
                Our fleets are high luxury, clean, hygienic and comfortable for
                your transport.
              </div>
            </div>
          </div>
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className="about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={paymentSecurity}
                alt="achauffeur has a variety of payment methods. all are safe methods"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>
            <div className="text-center sm:text-left">
              <h3>Payment Security</h3>
              <div className="text-base">
                We like to keep things transparent and secure for our customers.
              </div>
            </div>
          </div>
          <div className="box--shadow flex flex-col sm:flex-row gap-5 items-center bg-white p-5">
            <div className="about--icon flex items-center justify-center bg-sec-500 rounded-full">
              <img
                src={customerSupport}
                alt="if you have any doubts or clarifications call to our hotline. achauffeur provides good support to its client"
                className="w-12 sm:w-14 lg:w-14"
              />
            </div>

            <div className="text-center sm:text-left">
              <h3>24/7 Availability</h3>
              <div className="text-base">
                We are available around the clock to provide any kind of
                transports or hourly services.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
