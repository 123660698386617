import React from "react";
import { Link } from "react-router-dom";
import toronto from "../../assets/images/chauffeur-service-from-toronto-stress-free-and-reliable-car-services-at-best-price.jpg";
import niagra from "../../assets/images/car-services-from-niagara-falls-book-your-reservations-online-safe-and-secure-chauffeur-service.jpg";
import buffalo from "../../assets/images/business-class-chauffeur-services-from-buffalo-high-luxury-and-hygienic-limousines.jpg";
import hamilton from "../../assets/images/chauffeur-services-from-hamilton-with-well-trained-drivers-at-affordable-price.jpg";

const PopularCities = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
      <div className="container">
        <div className="grid lg:grid-cols-2 items-center gap-5 lg:gap-10">
          <div className="hidden lg:grid grid-cols-2 gap-5">
            <Link
              to="/city/chauffeur-service-toronto"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={toronto}
                  alt="achauffeur offers chauffeur services at affordable prices. Enjoy a stress free and reliable limo services from Toronto"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> Toronto</div>
              </div>
            </Link>
            <Link
              to="/city/chauffeur-service-nigara-falls"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={niagra}
                  alt="Book online for your safe and secure chauffeur service at achauffeur. Best car services from Niagara Falls"
                />
              </div>

              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">Niagara Falls</div>
              </div>
            </Link>
            <Link
              to="/city/chauffeur-service-buffalo-nyc"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              {" "}
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={buffalo}
                  alt="High luxury and hygienic limousine services from and to Buffalo. Enjoy your journey with business class chauffeur services."
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">Buffalo, NYC</div>
              </div>
            </Link>
            <Link
              to="/city/chauffeur-service-hamilton"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              {" "}
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={hamilton}
                  alt="Achauffeur has well-trained drivers at affordable price for your limo transportation. Limo services near Hamilton."
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold"> Hamilton</div>
              </div>
            </Link>
          </div>
          <div>
            <h2>Popular Cities</h2>
            <div className="-mt-2 text-base mb-3">
              Upgrade your global journey through vibrant neighborhoods, 
              historical landmarks, and cultural fusion, all effortlessly accessible
              and elevated by 'A Chauffeur's chauffeur-driven car service and
              ground transportation. Whether it's captivating wine tours, 
              our premium fleets and services, including car hire with a driver,  
              minibus hire with a driver, <a href="https://achauffeur.com/services/private-car-service"><b>private driver hire for the day</b></a>, and private  
              chauffeur services. We redefine your travel experience, making it truly 
              exceptional. Upgrade your journey and get an estimate online today.
            </div>
            <div className="text-base mb-5">
              <b>"A Chauffeur"</b> offers a professional transport solution to
              any cities. We are fully committed to maintain seamless luxury by
              providing prompt, professional, discreet and courteous service.
            </div>
            <Link to="/cities" className="btn1">
              View all Cities
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopularCities;
