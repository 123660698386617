import React from "react";

import FleetHero from "../components/web/fleet/FleetHero";
import BMW7Series from "../components/web/fleet/BMW7Series";
import MercedesS550 from "../components/web/fleet/MercedesS550";
import CadillacXTS from "../components/web/fleet/CadillacXTS";
import ChevroletSuburban from "../components/web/fleet/ChevroletSuburban";
import CadillacEscalade from "../components/web/fleet/CadillacEscalade";
import ExecutiveVan from "../components/web/fleet/ExecutiveVan";
import SprinterVan from "../components/web/fleet/SprinterVan";
import MiniCoach from "../components/web/fleet/MiniCoach";
import LimoBus from "../components/web/fleet/LimoBus";
import { Helmet } from "react-helmet-async";

const OurFleet = () => {
  return (
    <>
      <Helmet>
        <title>Stylish, safe & comfortable black car chauffeur service</title>
        <meta
          name="description"
          content="A Chauffeur black car fleet is chosen for uncompromising style, safety and of course comfort. "
        />
        <meta
          name="keywords"
          content="Black car service, a chauffeur, airport chauffeur service, comfort chauffeur service, safe chauffeur service, stylish chauffeur service, comfortable chauffeur service,  "
        />
        <link rel="canonical" href="/our-fleet" />
      </Helmet>
      <FleetHero />
      <CadillacXTS />
      <BMW7Series />
      <MercedesS550 />
      <ChevroletSuburban />
      <CadillacEscalade />
      <ExecutiveVan />
      <SprinterVan />
      <MiniCoach />
      <LimoBus />
    </>
  );
};

export default OurFleet;
