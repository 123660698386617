import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner2";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/niagarafalls/all-inclusive-limousine-services-from-four-points-by-sheraton-niagara-nalls-fallsview-to-niagara-falls-complete-luxury-transportation-available.webp";
import one2 from "../../assets/images/hotels/attractions/niagarafalls/luxury-car-services-from-four-points-by-sheraton-niagara-nalls-fallsview-to-niagara-falls-high-comfortable-and-hassle-free-services.webp";
import one3 from "../../assets/images/hotels/attractions/niagarafalls/business-class-trips-from-four-points-by-sheraton-niagara-nalls-fallsview-to-niagara-falls-hourly-services-available-at-affordable-price.webp";
import two1 from "../../assets/images/hotels/attractions/niagarafalls/private-car-services-from-hilton-niagara-falls-fallsview-hotel-suites-to-niagara-falls-fun-and-enjoyful-trip-from-achauffeur.webp";
import two2 from "../../assets/images/hotels/attractions/niagarafalls/executive-and-personalized-limo-services-from-hilton-niagara-falls-fallsview-hotel-suites-to-niagara-falls-clean-and-comfortable-limos-from-achauffeur.webp";
import two3 from "../../assets/images/hotels/attractions/niagarafalls/hire-a-private-car-from-hilton-niagara-falls-fallsview-hotel-suites-to-niagara-falls-we-have-well-trained-drivers-start-your-trip-with-pleasure.webp";
import three1 from "../../assets/images/hotels/attractions/niagarafalls/chauffeur-service-at-niagara-falls-marriott-fallsview-hotel-and-spa-to-niagara-falls-make-your-reservations-online.webp";
import three2 from "../../assets/images/hotels/attractions/niagarafalls/limo-cars-near-niagara-falls-marriott-fallsview-hotel-and-spa-to-niagara-falls-hire-a-car-at-affordable-price.webp";
import three3 from "../../assets/images/hotels/attractions/niagarafalls/personal-chauffeur-service-from-niagara-falls-marriott-fallsview-hotel-and-spa-to-niagara-falls-safe-and-secure-service-available.webp";
import four1 from "../../assets/images/hotels/attractions/niagarafalls/book-cars-online-from-fallsview-casino-resort-to-niagara-falls-we-have-professional-drivers.jpg";
import four2 from "../../assets/images/hotels/attractions/niagarafalls/higenic-and-comfortable-car-services-from-fallsview-casino-resort-to-niagara-falls-we-guarantee-a-stress-free-transportation.jpg";
import four3 from "../../assets/images/hotels/attractions/niagarafalls/premium-quality-limo-services-from-fallsview-casino-resort-to-niagara-falls-our-rates-are-affordable.jpg";

const NiagaraFall = () => {
  const hero = {
    title: "Niagara Falls",
    description:
      "We strive diligently to provide luxury limo service to Niagara falls with the highest customer satisfaction. Have comfortable, safe and stress-free sightseeing trips with Achauffeur.",
  };

  const desc = {
    title: "It's easy, pre-arrange Your chauffeur service Niagara Falls.",
    description:
      "Niagara Falls is a group of three waterfalls located on the Niagara River in western New York state and southeastern Ontario, Canada. The falls are one of the most popular tourist attractions in North America, and they are a popular honeymoon destination. The Niagara Falls are composed of two major sections: the Horseshoe Falls, also known as the Canadian Falls, and the American Falls. The Horseshoe Falls is located on the Canadian side of the border and is the larger of the two falls, with a height of about 173 feet and a width of about 2,600 feet. The American Falls is located on the American side of the border and is about 167 feet tall and 1,060 feet wide. The falls are a natural wonder and a source of hydroelectric power.",
    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of Niagara falls, as your own personal driver shows you the must-see tourist attractions near Niagara falls. You can make an online booking or directly can make a call on the given numbers for your trip.",
    website: "https://www.billybishopairport.com/",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Four Points by Sheraton Niagara Falls Fallsview to Niagara falls.",
        "High comfortable and hassle free luxury limousine services from Four Points by Sheraton Niagara Falls Fallsview to Niagara falls",
        "Hourly limo services at affordable price from Four Points by Sheraton Niagara Falls Fallsview to Niagara falls. Business class trips for your vacations",
      ],
      title: "Four Points by Sheraton Niagara Falls Fallsview",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyful trips from Hilton Niagara Falls/Fallsview Hotel & Suites to Niagara falls. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Hilton Niagara Falls/Fallsview Hotel & Suites to Niagara falls. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Hilton Niagara Falls/Fallsview Hotel & Suites to Niagara falls. Hire a private car at an affordable price",
      ],
      title: "Hilton Niagara Falls/Fallsview Hotel & Suites",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from Niagara Falls Marriott Fallsview Hotel & Spa to Niagara falls",
        "Hire a car at affordable price near Niagara Falls Marriott Fallsview Hotel & Spa to Niagara falls. best chauffeur services",
        "Safe and secure personal chauffeur services from Niagara Falls Marriott Fallsview Hotel & Spa to Niagara falls. High comfortable and luxury",
      ],
      title: "Niagara Falls Marriott Fallsview Hotel & Spa",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from Fallsview Casino Resort to Niagara falls.Best limo services to Niagara falls",
        "Achauffeur offers hygienic and comfortable car services from Fallsview Casino Resort to Niagara falls. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from Fallsview Casino Resort to Niagara falls. Our rates are affordable",
      ],
      title: "Fallsview Casino Resort",
    },
  ];
  const mostBookeds = [
    {
      start: "Niagara Falls",
      end: "Toronto, Ontario",
      duration: "1 hr 18 min",
      distance: "128 Km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Hamilton, Ontario",
      duration: "52 min",
      distance: "72.6 Km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Kitchener, Ontario",
      duration: "1h 28 min",
      distance: "140 Km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Toronto Pearson International Airport",
      duration: "1h 7 min",
      distance: "122 Km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Niagara-on-the-Lake, Ontario",
      duration: "24 min",
      distance: "24.1 Km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Ottawa, Ontario",
      duration: "5h 29 min",
      distance: "528 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>All-Inclusive Private Chauffeur Service in Niagara Falls.</title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Best All-Inclusive, Reliable and Efficient Chauffeur Service To Niagara Falls. Best Price Guaranteed.          "
        />
        <meta
          name="keywords"
          content="Chauffeur service to Niagara falls, Chauffeur service from Niagara falls, chauffeur service, Chauffeur service from toronto to Niagara falls, toronto chauffeur service, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-niagara-falls"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default NiagaraFall;
