import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import Banner2 from "../../components/web/banners/Banner2";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
import f1 from "../../assets/images/services/five-star-personalized-wine-tour-car-services.jpg";
import f2 from "../../assets/images//services/limo-service-to-winery-to-taste-real-flavour-of-wine.jpg";
const WineryTourService = () => {
  const hero = {
    title: "Winery Tour Service",
    description:
      "Embark on an extraordinary wine journey with Achauffeur. Immerse yourself in Grape Escape Wine Tours, available in Toronto, Niagara Falls, and Prince Edward County. Our Wine Tour Car Service is designed to make your trip convenient, enjoyable, and cost-effective, including rides from the airport.",
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Winery Tour Service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Winery Tour Service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "chauffeur offers five star and personalized wine tour car services. We have an affordable rate.",
    title: "Five-Star Personalized Wine Tours    ",
    description:
      "Our personalized wine tours include three full-production wineries, along with a catered gourmet picnic lunch. A wine tour can be a relaxing and fun way to spend the day. You don’t have to be an expert on wine to enjoy one. If you’re looking for a fun way to spend the day, then a Winery Tours service from Achauffeur is perfect. Our wine chauffeurs are knowledgeable and experienced, and can answer any questions you may have during the tour.",
  };
  const feature2 = {
    image: f2,
    alt: "achauffeur covers many wineries in our winery tour service. You can taste the real flavor of wine with us",
    title: "Taste Real Flavour of Wine    ",
    description:
      "For travellers who visit the country for a Wine Tour, booking our Winery Tour Service is a great option.Let us pamper you with our outstanding chauffeur services while you enjoy a memorable stay in wine country as our guest. We provide our customers with a dependable, cozy, and secure way to explore beautiful winemaking locations in Canada.We offer cars in various classes, providing a comfortable ride. The only thing left for you is to book our chauffeur service, by filling out our online form.      ",
  };
  return (
    <>
      <Helmet>
        <title>Book Your Private Winery Tour Today | AChauffeur.com</title>
        <meta
          name="description"
          content="Winery Tour Chauffeur Service. 24/7 Booking. Reliable Transport To/From Toronto Wineries and Niagara Tours. Best Price Guaranteed."
        />{" "}
        <meta
          name="keywords"
          content="Winery Tour, Winery Tours Near Me, Winery Tour in Toronto, Winery Tour Bus, Wine Tour Locations In Niagara On the lake, Toronto Winery Tours Booking, Wineries in Toronto Ontario, Wine Tour from Downtown Toronto, Niagara Wine Tours Toronto Pick Up, Full-Day Niagara Winery Tour from Toronto"
        />
        <link rel="canonical" href="/services/winery-tour-service" />
      </Helmet>
      <ServiceHero hero={hero} />

      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default WineryTourService;
