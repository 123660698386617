import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";

// stay images
import one1 from "../../assets/images/hotels/airports/torontoPearson/sheraton-gateway-hotel-chauffeur-service-toronto-pearson-international-airport.jfif";
import one2 from "../../assets/images/hotels/airports/torontoPearson/sheraton-gateway-hotel-private-chauffeur-service-toronto-pearson-international-airport.jfif";
import one3 from "../../assets/images/hotels/airports/torontoPearson/sheraton-airport-car-service-toronto-pearson-international-airport.jfif";
import two1 from "../../assets/images/hotels/airports/torontoPearson/chauffeur-service-in-toronto-to-marriott-hotel.jpg";
import two2 from "../../assets/images/hotels/airports/torontoPearson/achauffeur-airport-transfer-to-marriott-hotel.webp";
import two3 from "../../assets/images/hotels/airports/torontoPearson/car-service-from-toronto-pearson-airport-to-marriott-hotel.jpg";
import three1 from "../../assets/images/hotels/airports/torontoPearson/executive-chauffeur-service-from-toronto-airport-alt-hotel.jpg";
import three2 from "../../assets/images/hotels/airports/torontoPearson/hourly-chauffeur-service-at-toronto-airport-alt-hotel.webp";
import three3 from "../../assets/images/hotels/airports/torontoPearson/chauffeur-service-near-toronto-airport-alt-hotel.jpg";
import four1 from "../../assets/images/hotels/airports/torontoPearson/limo-service-at-toronto-pearson-airport-to-embassy-suites-by-hilton-toronto-airport.webp";
import four2 from "../../assets/images/hotels/airports/torontoPearson/car-hire-at-toronto-pearson-airport-to-embassy-suites-by-hilton-toronto-airport.webp";
import four3 from "../../assets/images/hotels/airports/torontoPearson/budget-car-hire-toronto-pearson-to -embassy-suites-by-hilton.webp";
import { Helmet } from "react-helmet-async";

const TorontoPearson = () => {
  const hero = {
    title: "Toronto Pearson International Airport (YYZ)",
    description:
      "Enjoy a pleasant chauffeur service from the Toronto airport to the front door of your accommodation with the complete luxury of our transfers, whether you are traveling for business or pleasure.",
  };
  const desc = {
    title: "Toronto Pearson International Airport",
    description:
      "Pearson Airport is more than just a transportation hub. They’re always working to fuel success for the region now and into the future. As the region develops and demand for air travel grows, they are evolving to meet the demands of passengers, employees and local communities. They are guided by core principles and priorities that balance purposeful and flexible growth with corporate responsibility and accountability to their passengers and workforce.",
    website: "https://www.torontopearson.com/en",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Toronto Pearson International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle =
    "Stay at top unique properties near Totonto Pearson Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Toronto Pearson Airport to Sheraton Gateway Hotel. Book with affordable price",
        "Private chauffeur service from toronto pearson airport to Sheraton Gateway Hotel. Book online",
        "Airport car service to  Sheraton Gateway Hotel from toronto Pearson Airport.",
      ],
      rating: "8.5",
      title: "Sheraton Gateway Hotel",
      location: "Toronto International Airport",
      distance: "550 m from airport",
      map: "https://maps.google.com/maps?q=Sheraton%20Gateway%20Hotel%20In%20Toronto%20International%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service in toronto will take you to and from toronto pearson international airport.",
        "Search, compare and book Toronto Pearson international . Airport  transfers with a chauffeur from marriott-hotel",
        "book your private car service from Toronto  Pearson international airport to Marriott Hotel Toronto",
      ],
      rating: "7.8",
      title: "Toronto Airport Marriott Hotel",
      location: "901 Dixon Rd, Toronto",
      distance: "2.3 km from airport",
      map: "https://maps.google.com/maps?q=Toronto%20Airport%20Marriott%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from  Toronto Pearson international airport To alt hotel        ",
        "Book your Luxury Chauffeur from alt hotel to Toronto Pearson international airport       ",
        "Chauffeur service at toronto pearson international airport to alt hotel",
      ],
      rating: "8.6",
      title: "Alt Hotel Toronto Airport",
      location: "16 Madison Avenue, M5R 2S1 Toronto, Canada",
      distance: "1.1 km from airport",
      map: "https://maps.google.com/maps?q=Alt%20Hotel%20Toronto%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from toronto pearson airport to Embassy Suites by Hilton Toronto Airport",
        "Find cheep and luxury cars at toronto pearson airport to Embassy Suites by Hilton Toronto Airport",
        "All inclusive budget car services from toronto pearson airport to Embassy Suites by Hilton Toronto Airport",
      ],
      rating: "8.1",
      title: "Embassy Suites by Hilton Toronto Airport",
      location: "Toronto Airport",
      distance: "2.1 km from airport",
      map: "https://maps.google.com/maps?q=Embassy%20Suites%20by%20Hilton%20Toronto%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Totonto pearson airport",
      end: "Toronto, Ontario",
      duration: "24 min",
      distance: "29.2 km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Niagara Falls, Ontario",
      duration: "1 hr 13 min",
      distance: "124 km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Hamilton, Ontario",
      duration: "40 min",
      distance: "64.4 km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Mississauga, Ontario",
      duration: "18 min",
      distance: "19.1 km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Waterloo, Ontario",
      duration: "1 hr 1 min",
      distance: "94.6 km",
      price: "211.72",
    },
    {
      start: "Totonto pearson airport",
      end: "Markham, Ontario",
      duration: "25 min",
      distance: "38.8 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book Online. Private Chauffeur Service at Toronto Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24 Hrs, Pre-Book Online. Reliable and Efficient Chauffeur Service To/from Toronto Airport. Best Price Guaranteed"
        />
        <meta
          name="keywords"
          content="Chauffeur, Chauffeur Service, Chauffeur Services, A Chauffeur, Private Chauffeur, Private Chauffeur Service, Private Chauffeur Services, Personal Chauffeur, Personal Chauffeur Service, Chauffeur Service at Toronto, Airport Chauffeur Service, Chauffeur at Toronto Airport, Chauffeur Services at Toronto, Chauffeur Service from Toronto, Chauffeur Service to Toronto Airport, Chauffeur to Toronto Airport, Chauffeur from Toronto Airport, Airport Chauffeur, Chauffeur in Toronto Airport, Chauffeur Service In Toronto Airport, Airport car service, airport limo"
        />
        <link
          rel="canonical"
          href="/airport/toronto-pearson-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default TorontoPearson;
