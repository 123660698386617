import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import Main from "./routes/Main";
import "antd/dist/antd.min.css";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </HelmetProvider>
);
