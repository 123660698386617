import React from "react";
import { Link } from "react-router-dom";
import flight from "../../../assets/images/flight-5.png";

const AirportHero = (props) => {
  return (
    <div className="hero--3 ">
      <div className="hero--cover-2 flex items-center  h-full px-3 py-12 sm:py-16  bg-sec-100">
        <div className="container">
          <div className="grid md:grid-cols-2  gap-10">
            <div className="my-auto mx-auto text-center md:text-left text-white">
              <h3 className="text-white">
                <Link
                  to="/home"
                  className="text-white hover:text-pri-500 font-semibold"
                >
                  Home
                </Link>{" "}
                / Airports
              </h3>
              <h2 className="text-white"> {props.hero.title}</h2>
              <div className="-mt-2 mb-5 text-base max-w-xl mx-auto">
                {props.hero.description}
              </div>
              <Link
                to="/book-now-a-chauffeur-service"
                className="btn2   text-base  cursor-pointer"
              >
                Book now
              </Link>
            </div>
            <div className="hidden md:flex items-center justify-center ">
              <img className=" " src={flight} alt="flight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirportHero;
