import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";

import Banner2 from "../../components/web/banners/Banner2";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import f1 from "../../assets/images/services/private-car-hire-for-your-secrete-transport.jpg";
import f2 from "../../assets/images/services/luxury-private-car-services-with-cleanliness-and-hygiene.jpg";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
const PrivateCarService = () => {
  const hero = {
    title: "Private Car Service",
    description:
      <span>Upgrade Toronto with our <b>personalized private car service</b>. Enjoy personal drivers, skilled chauffeurs, and cost-effective limos for airport, city, business meetings, long-distance rides, and private trips to Niagara Falls. Book for safe, lavish, convenient journeys that redefine extravagance.</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Private Car Service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Private Car Service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "Hire private cars and easily navigate to your destinations",
    title: "Luxury Private Car Service",
    description:
      <span>Experience the epitome of convenience and luxury with <b>Achauffeur's private car services</b>, where you have the ability to hire a chauffeur online. Whether you're in need of a private chauffeur service for a special occasion, <b>a nearby private car service</b> for your daily commute, or seamless transportation to and from the airport, our user-friendly online booking platform makes securing your chauffeur effortless. We take pride in our commitment to transparency, ensuring that you are always well-informed about our pricing, allowing you to make informed choices right from the comfort of your device. At Achauffeur, we go beyond mere private car hire; we provide an unparalleled private ride service that prioritizes your comfort and safety. Booking a <b>private car service</b> with us is a breeze, ensuring a stylish and comfortable journey every time. Choose Achauffeur for a truly exceptional and memorable travel experience, whether it's for business or leisure, and relish the convenience of hiring a chauffeur online to enhance your journey.</span>,
  };
  const feature2 = {
    image: f2,
    alt: "Cheapest luxury private car services. It is clean and hygienic ",
    title: "Platinum Private Car Service: Elevate Your Travel",
    description:
      <span>Experience the pinnacle of travel luxury with A Chauffeur's <b>Platinum Private Car Service</b>. Whether you require a private car pick-up, personal chauffeur hire, or a nearby private car rental, we are your gateway to unparalleled comfort and convenience. From hiring a personal driver to indulging in a <b>private limousine service</b>, we have all your needs covered. Our seamless booking process guarantees a stress-free experience, and our dedicated professional drivers are committed to ensuring your utmost comfort and satisfaction. Elevate your travel experience to a whole new level with A Chauffeur. Contact us today to reserve your next journey in style, featuring our exclusive Platinum Private Car Service.</span>,
  };
  return (
    <>
      <Helmet>
        <title>Book Your Private Car Service | AChauffeur.com</title>
        <meta
          name="description"
          content="Looking for a private car service? Book online 24/7. Efficient and reliable private car service for your leisure, business and events. Best Price Guaranteed

  "
        />{" "}
        <meta
          name="keywords"
          content="Private Car Service, Private Car Service Near Me, Private Car Hire, Private Chauffeur Service, Private Taxi Service, Private Car Booking, Personal Driver Service, Private Limo Service, Personal Driver, Private Hire Driver, Private Car Service Toronto, Private Car Service In Toronto"
        />
        <link rel="canonical" href="/services/private-car-service" />
      </Helmet>
      <ServiceHero hero={hero} />

      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default PrivateCarService;
