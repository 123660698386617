import React from "react";
import { Link } from "react-router-dom";
import airport1 from "../../../assets/images/chauffeur-service-at-toronto-pearson-international-airport-affordable-price-luxury-services.jpg";
import airport2 from "../../../assets/images/personal-chauffeur-services-from-billy-bishop-toronto-city-airport-secure-and-stress-free-airport-transfer-services.jpg";
import airport3 from "../../../assets/images/limo-services-from-and-to-london-international-airport-reliable-and-fastest-airport-transfer-services.jpg";
import airport4 from "../../../assets/images/business-class-and-luxury-airport-transfer-services-from-and-to-john-c-munro-hamilton-international-airport.jpg";
import airport5 from "../../../assets/images/car-service-from-buffalo-niagara-international-airport-book-airport-transfer-affordable-price.jpg";
import airport6 from "../../../assets/images/best-limo-service-from-and-to-ottawa-mcdonald-cartier-international-airport-high-luxury-airport-transfer-services.jpg";
import airport7 from "../../../assets/images/limo-cars-near-montreal-pierre-elliott-trudeau-international-airport-reliable-airport-transfer-services.jpg";
import airport8 from "../../../assets/images/efficient-and-affordable-airport-transfer-services-near-greater-sudbury-airport.jpg";
import airport9 from "../../../assets/images/limo-car-services-near-niagara-falls-international-airport-safe-and-private-chauffeur-services.jpg";
import { ArrowRightOutlined } from "@ant-design/icons";

const AllAirportBody = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <h2 className="text-center">Major airports</h2>
        <div className="grid md:grid-cols-3 gap-5 lg:gap-10">
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport1}
                alt="Book luxury chauffeur services at toronto pearson international airport. Airport transfers at affordable prices "
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Toronto Pearson International Airport (YYZ)
              </h4>
              <div className=" pb-2">
                Pearson Airport is more than just a transportation hub. They’re
                always working to fuel success for the region now and into the
                future. As the region develops and...
              </div>
              <Link
                to="/airport/toronto-pearson-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport2}
                alt="Secure and stress-free airport transfer services from billy bishop toronto city airport. Personal and VIP chauffeur services with best prices"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Billy Bishop Toronto City Airport (YTZ)
              </h4>
              <div className=" pb-2">
                Billy Bishop airport is offering service to more than 20 cities
                in Canada and the U.S., with connection opportunities to more
                than 80 international destinations via our...
              </div>
              <Link
                to="/airport/billy-bishop-toronto-city-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport3}
                alt="Reliable and fastest airport transfer services from London international airport. Best limo services from achauffeur"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                {" "}
                London International Airport <h4 className="lg:block">(YXU)</h4>
              </h4>
              <div className=" pb-2">
                London International Airport is the easy and comfortable
                airport. From their airline partners to their tenants, they
                provide the support and infrastructure necessary to foster...
              </div>
              <Link
                to="/airport/london-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport4}
                alt="Achauffeur offers business class and luxury airport transfer services from and to john c munro hamilton international airport"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                John C. Munro Hamilton International Airport(YHM)
              </h4>
              <div className=" pb-2">
                John C. Munro Hamilton International Airport was first built in
                October 1940 as a wartime air force training station. Originally
                designed as a multi-purpose military field, the Airport...
              </div>
              <Link
                to="/airport/john-c.-munro-hamilton-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport5}
                alt="Book private car services for airport transfers from buffalo niagara international airport at affordable price"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Buffalo Niagara International Airport (BUF)
              </h4>
              <div className=" pb-2">
                Buffalo Niagara International Airport plays an essential role in
                the development of business and tourism in the Buffalo Niagara
                region. Buffalo Niagara International Airport...
              </div>
              <Link
                to="/airport/buffalo-niagara-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport6}
                alt="Book high luxury best limos for airport transfer from and to ottawa Mcdonald-cartier International Airport"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Ottawa Mcdonald-cartier International Airport (YOW)
              </h4>
              <div className=" pb-2">
                Ottawa Mcdonald-cartier International Airport is a leader in
                providing quality, safe, secure, sustainable and affordable air
                transportation services to the airport’s customers...
              </div>
              <Link
                to="/airport/ottawa-mcdonald-cartier-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport7}
                alt="Search and book limo cars near Montreal Pierre Elliott Trudeau International Airport. Reliable airport transfer services from Achauffeur"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Montreal Pierre Elliott Trudeau International Airport
              </h4>
              <div className=" pb-2">
                Montreal Pierre Elliott Trudeau International Airport is the
                only Transport Canada designated international airport serving
                Montreal and is situated 20 km (12 mi)...
              </div>
              <Link
                to="/airport/montreal-pierre-elliott-trudeau-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport8}
                alt="Achauffeur offers efficient and affordable airport transfer services near Greater Sudbury Airport"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Greater Sudbury Airport</h4>
              <div className=" pb-2">
                Greater Sudbury Airport Awarded the Safe Travels Stamp by the
                Worldwide Travel & Tourism Association for meeting and exceeding
                international health and hygiene protocols so that travellers...
              </div>
              <Link
                to="/airport/greater-sudbury-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airport9}
                alt="Safe and private chauffeur services near Niagara Falls International Airport. Book limo cars near Niagara Falls International Airport"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">
                Niagara Falls International Airport - NY
              </h4>
              <div className=" pb-2">
                Niagara Falls International Airport is located 4 mi (6.4 km)
                east of downtown Niagara Falls, in the Town of Niagara in
                Niagara County, New York, United States.[1] Owned and...
              </div>
              <Link
                to="/airport/niagara-falls-international-airport-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAirportBody;
