import React from "react";
import { Link } from "react-router-dom";
import parcelDelivery from "../../assets/images/fastest-parcel-delivery-service--achauffeur.com.jpg";
import eventLimo from "../../assets/images/stress-free-event-limousine-service-achauffeur.com.jpg";
import airportTransfer from "../../assets/images/reliable-airport-transfer-service-achauffeur.com.jpg";
import meetAndGreet from "../../assets/images/exclusive-and-personalized-meet-and-greet-service-achauffeur.com.jpg";
import intercityRides from "../../assets/images/all-inclusive-intercity-chauffeured-rides-achauffeur.com.jpg";
import groundTransport from "../../assets/images/long-distance-ground-transportation-services-achauffeur.com.jpg";
import vip from "../../assets/images/business-class-vip-chauffeur-service-achauffeur.com.jpg";
import privateCar from "../../assets/images/secure-private-car-service-achauffeur.com.jpg";
import { ArrowRightOutlined } from "@ant-design/icons";

const Services = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <h2 className="text-center">Achauffeur-Driven Car Services</h2>
        <div className="text-center text-lg md:text-xl max-w-3xl mx-auto pb-5 md:pb-10">
        Seamless 24/7 chauffeur-driven black car service. <b>Airport Transfers,</b> pick-up, drop-off, and more. 
        Professional chauffeurs day and night.
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 ">
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={intercityRides}
                alt="achauffeur offers all-inclusive intercity chauffeured rides at affordable price"
              />
            </div>
            <div className="p-3 mt-3">
              <h5 className="roboto"> <center>INTERCITY CHAUFFEURED RIDES</center></h5>
              <div className=" pb-2">
                Intercity Chauffeur offers luxury and flexibility Premium airport car service, black
                car rides, and hourly pick-up and drop for your business and leisure
                trips...
              </div>
              <Link
                to="/services/intercity-chauffeured-rides"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={airportTransfer}
                alt="achauffeur offers reliable airport transfer service. We Minimize your Stress and Frustration                 "
              />
            </div>
            <div className="p-3 mt-3">
              <h5 className="roboto"><center>AIRPORT TRANSFER</center></h5>
              <div className=" pb-2">
                For stress-free travel, Experience seamless airport transfer, luxury chauffeur rides, 
                Our best price guarantee, meet and greet service, and evan flight  
                delay...
              </div>
              <Link
                to="/services/airport-transfer"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={eventLimo}
                alt="achauffeur offers stress-free event limousine service. Make booking easily and quickely"
              />
            </div>

            <div className="p-3 mt-3">
              <h5 className="roboto"><center>EVENT LIMOUSINE SERVICE</center></h5>
              <div className=" pb-2">
                Upgrade your events with our premium Event Limousine and luxury car service! 
                Enjoy unforgettable transfers, including wedding limo service...
              </div>
              <Link
                to="/services/event-limousine-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={vip}
                alt="achauffeur offers business class vip chauffeur service. Book your luxury business trip.
                "
              />
            </div>{" "}
            <div className="p-3 mt-3">
              <h5 className="roboto"> <center>VIP CHAUFFEUR SERVICE</center></h5>
              <div className=" pb-2">
                Elevate your travel with our VIP chauffeur service: seamless transfers,
                premium cars, and a warm meet and greet at Toronto airport...
              </div>
              <Link
                to="/services/vip-chauffeur-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2"
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={privateCar}
                alt="achauffeur offers secure private car service. We highly focus on your privacy and security"
              />
            </div>{" "}
            <div className="p-3 mt-3">
              <h5 className="roboto"><center>PRIVATE CAR SERVICE</center></h5>
              <div className=" pb-2">
                Upgrade Toronto with our personalized private car service. Enjoy personal drivers, 
                skilled chauffeurs, and cost-effective limos for airport...
              </div>
              <Link
                to="/services/private-car-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2"
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={groundTransport}
                alt="achauffeur offers the cheapest and luxury long-distance ground transportation service."
              />
            </div>
            <div className="p-3 mt-3">
              <h5 className="roboto"> <center>GROUND TRANSPORTATION</center></h5>
              <div className=" pb-2">
                Experience luxury ground transportation with our chauffeur service,
                offering airport transfers, meet and greet, and flexible hourly car options...
              </div>
              <Link
                to="/services/ground-transportation"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2"
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={meetAndGreet}
                alt="achauffeur offers exclusive and personalized meet and greet service. 100% satisfaction in meet and greet service"
              />
            </div>
            <div className="p-3 mt-3">
              <h5 className="roboto"> <center>MEET AND GREET SERVICE</center></h5>
              <div className=" pb-2">
                Traveling to Toronto, Canada? Let us make your arrival memorable
                with our airport meet and greet service. Our friendly team will be at
                the airport...
              </div>
              <Link
                to="/services/meet-and-greet-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2"
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={parcelDelivery}
                alt="achauffeur offers fastest door to door parcel delivery service. Trustful parcel delivery"
              />
            </div>
            <div className="p-3 mt-3">
              <h5 className="roboto"><center>PARCEL DELIVERY SERVICE</center></h5>
              <div className=" pb-2">
                Our professional chauffeurs provide a secure parcel delivery service,
                ensuring safe and timely transportation from pickup to delivery...
              </div>
              <Link
                to="/services/parcel-delivery-service"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2"
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
