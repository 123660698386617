import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";

// stay images
import one1 from "../../assets/images/hotels/airports/johnCMunro/c-hotel-by-carmen's-bw-premier-collection-chauffeur-service-john-c-munro-hamilton-international-airport.webp";
import one2 from "../../assets/images/hotels/airports/johnCMunro/c-hotel-by-carmen's-bw-premier-collection-private-chauffeur-service-john-c-munro-hamilton-international-airport.webp";
import one3 from "../../assets/images/hotels/airports/johnCMunro/c-hotel-by-carmen's-bw-premier-collection-car-service-john-c-munro-hamilton-international-airport.jpg";
import two1 from "../../assets/images/hotels/airports/johnCMunro/chauffeur-service-in-john-c-munro-hamilton-international-airport-to-sheraton-hamilton-hotel.webp";
import two2 from "../../assets/images/hotels/airports/johnCMunro/achauffeur-airport-transfer-to-sheraton-hamilton-hotel.jpg";
import two3 from "../../assets/images/hotels/airports/johnCMunro/car-service-from-john-c-munro-hamilton-international-airport-to-sheraton-hamilton-hotel.webp";
import three1 from "../../assets/images/hotels/airports/johnCMunro/executive-chauffeur-service-from-john-c-munro-hamilton-international-airport-to-homewood-suites-by-hilton-hamilton.jfif";
import three2 from "../../assets/images/hotels/airports/johnCMunro/hourly-chauffeur-service-at-john-c-munro-hamilton-international-airport-to-homewood-suites-by-hilton-hamilton.jfif";
import three3 from "../../assets/images/hotels/airports/johnCMunro/chauffeur-service-near-john-c-munro-hamilton-international-airport-to-homewood-suites-by-hilton-hamilton.jfif";
import four1 from "../../assets/images/hotels/airports/johnCMunro/limo-service-at-john-c-munro-hamilton-international-airport-to-staybridge-suites-hamilton-downtown.jfif";
import four2 from "../../assets/images/hotels/airports/johnCMunro/car-hire-at-john-c-munro-hamilton-international-airport-to-staybridge-suites-hamilton-downtown.jfif";
import four3 from "../../assets/images/hotels/airports/johnCMunro/budget-car-hire-john-c-munro-hamilton-international-airport-to-staybridge-suites-hamilton-downtown.jfif";
import { Helmet } from "react-helmet-async";

const JohnCMunro = () => {
  const hero = {
    title: "John C. Munro Hamilton International Airport(YHM)",
    description:
      "If you are looking to start the journey in a perfect mood, AChauffeur provides stress-free and luxury Airport service from Hamilton International Airport. Reserve a safe and comfortable journey with our professional chauffeurs.",
  };
  const desc = {
    title: "John C. Munro Hamilton International Airport",
    description:
      "John C. Munro Hamilton International Airport was first built in October 1940 as a wartime air force training station. Originally designed as a multi-purpose military field, the Airport was used for flight training, air navigation, telegraphy, and air gunnery. After World War II, the Airport transitioned from a military establishment into a public facility.",
    website: "https://flyhamilton.ca/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from John C. Munro Hamilton International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle = "Stay at top unique properties near Hamilton Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the John C. Munro Hamilton International Airport to DoubleTree by Hilton Hotel. Book with affordable price",
        "Private chauffeur service from John C. Munro Hamilton International Airport  to DoubleTree by Hilton Hotel. Book online",
        "Airport car service to  DoubleTree by Hilton Hotel from John C. Munro Hamilton International Airport",
      ],
      rating: "8.5",
      title: "C Hotel By Carmen'S",
      location: "Ontario",
      distance: "13.4 km from airport",
      map: "https://maps.google.com/maps?q=C%20Hotel%20By%20Carmen'S&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Sheraton Hamilton Hotel will take you to and from John C. Munro Hamilton International Airport",
        "Search, compare and book John C. Munro Hamilton International Airport transfers with a chauffeur from Sheraton Hamilton Hotel",
        "book your private car service from John C. Munro Hamilton International Airport  to Sheraton Hamilton Hotel",
      ],
      rating: "7.5",
      title: "Sheraton Hamilton Hotel",
      location: "Ontario",
      distance: "19.3 km from airport",
      map: "https://maps.google.com/maps?q=Sheraton%20Hamilton%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from  John C. Munro Hamilton International Airport To Homewood Suites by Hilton Hamilton",
        "Book your Luxury Chauffeur from Homewood Suites by Hilton Hamilton to John C. Munro Hamilton International Airport",
        "Chauffeur service at John C. Munro Hamilton International Airport  to Homewood Suites by Hilton Hamilton",
      ],
      rating: "7.5",
      title: "Homewood Suites by Hilton Hamilton",
      location: "Ontario",
      distance: "18.6 km from airport",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20Hamilton&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from John C. Munro Hamilton International Airport  to Staybridge Suites Hamilton - Downtown",
        "Find cheep and luxury cars at John C. Munro Hamilton International Airport  to Staybridge Suites Hamilton - Downtown",
        "All inclusive budget car services from John C. Munro Hamilton International Airport  to Staybridge Suites Hamilton - Downtown",
      ],
      rating: "6.5",
      title: "Staybridge Suites Hamilton",
      location: "20 Caroline St S, Hamilton, ON L8P 0B1",
      distance: "18.6 km from airport",
      map: "https://maps.google.com/maps?q=Staybridge%20Suites%20Hamilton&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "John C. Munro Hamilton International Airport",
      end: " Hamilton, Ontario",
      duration: "15 min",
      distance: "19.1 km",
      price: "211.72",
    },
    {
      start: "John C. Munro Hamilton International Airport",
      end: " Brantford, Ontario",
      duration: "27 min",
      distance: "37.5 km",
      price: "211.72",
    },
    {
      start: "John C. Munro Hamilton International Airport",
      end: "Toronto, Ontario",
      duration: "55 min",
      distance: "83.3 km",
      price: "211.72",
    },
    {
      start: "John C. Munro Hamilton International Airport",
      end: "Oakville, Ontario",
      duration: "31 min",
      distance: "47.6 km",
      price: "211.72",
    },
    {
      start: "John C. Munro Hamilton International Airport",
      end: "Burlington, Ontario",
      duration: "23 min",
      distance: "31.8 km",
      price: "211.72",
    },
    {
      start: "John C. Munro Hamilton International Airport",
      end: "Niagara Falls, Ontario",
      duration: "53 min",
      distance: "84.2 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book online. Private Chauffeur Service at Hamilton Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service To or From Hamilton Airport. Best Price Guaranteed.
      "
        />
        <meta
          name="keywords"
          content="Chauffeur, Chauffeur Service, Chauffeur Services, A Chauffeur, Private Chauffeur, Private Chauffeur Service, Private Chauffeur Services, Personal Chauffeur, Personal Chauffeur Service, Chauffeur Service at Hamilton, Airport Chauffeur Service, Chauffeur at Hamilton Airport, Chauffeur Services at Hamilton, Chauffeur Service from Hamilton, Chauffeur Service to Hamilton Airport, Chauffeur to Hamilton Airport, Chauffeur from Hamilton Airport, Airport Chauffeur, Chauffeur in Hamilton Airport, Chauffeur Service In Hamilton Airport"
        />
        <link
          rel="canonical"
          href="/airport/john-c.-munro-hamilton-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default JohnCMunro;
