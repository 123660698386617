import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/web/Footer";
import NavBar from "../components/web/NavBar";
import Topbar from "../components/web/Topbar";

const Web = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <a
        href="http://wa.me/+16473900006"
        className="z-50 fixed rounded-full cursor-pointer  h-12 md:h-14 w-12 md:w-14 flex items-center justify-center bg-green-100 bottom-20 md:bottom-24 right-5"
      >
        <i class="ri-whatsapp-line text-white text-2xl md:text-3xl"></i>
      </a>
      <a
        href="tel:+14168001009"
        className="z-50 fixed rounded-full cursor-pointer  h-12 md:h-14 w-12 md:w-14 flex items-center justify-center bg-pri-500 bottom-5 right-5"
      >
        <i className="ri-phone-fill text-white text-2xl md:text-3xl"></i>
      </a>
      <Topbar />
      <NavBar />

      <Outlet />
      <Footer />
    </div>
  );
};

export default Web;
