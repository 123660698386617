import React from "react";
import StayCard from "./StayCard";

const Stay = (props) => {
  return (
    <>
      <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
        <div className="container">
          <h2 className="text-center">{props.staysTitle}</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 ">
            {props.stays.map((stay) => (
              <StayCard key={stay.name} stay={stay} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stay;
