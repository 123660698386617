import React from "react";
import { Link } from "react-router-dom";
import whitby from "../../../assets/images/chauffeur-service-at-whitby-highest-customer-satisfaction.jpg";
import ajax from "../../../assets/images/ajax-limo-services-start-your-journey-with-achauffeur-we-have-professional-drivers.jpg";
import barrie from "../../../assets/images/best-limo-service-at-barrie-timely-transportation-from-achauffeur.png";
import toronto from "../../../assets/images/chauffeur-service-from-toronto-stress-free-and-reliable-car-services-at-best-price.jpg";
import niagra from "../../../assets/images/car-services-from-niagara-falls-book-your-reservations-online-safe-and-secure-chauffeur-service.jpg";
import buffalo from "../../../assets/images/business-class-chauffeur-services-from-buffalo-high-luxury-and-hygienic-limousines.jpg";
import hamilton from "../../../assets/images/chauffeur-services-from-hamilton-with-well-trained-drivers-at-affordable-price.jpg";
import markham from "../../../assets/images/best-limo-services-from-markham-enjoy-a-pleasant-journey-with-achauffeur.jpg";
import london from "../../../assets/images/comfortable-chauffeur-services-from-london-with-first-class-limousines.jpg";

import kitchener from "../../../assets/images/budget-car-hire-from-kitchener-best-car-service-with-driver.jpg";
import peterborough from "../../../assets/images/limo-service-near-peterborough-24-hours-customer-support-available.jpg";
import oshawa from "../../../assets/images/hourly-chauffeur-service-from-oshawa-premium-quality-chauffeur-services.jpg";
import { ArrowRightOutlined } from "@ant-design/icons";

const AllCitiesBody = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <h2 className="text-center">Popular cities</h2>
        <div className="grid md:grid-cols-3 gap-5 lg:gap-10">
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={toronto}
                alt="achauffeur offers chauffeur services at affordable prices. Enjoy a stress free and reliable limo services from Toronto"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Toronto</h4>
              <div className="  pb-2">
                Toronto is the largest city in Canada and the capital of the
                province of Ontario. It is located on the northwestern shore of
                Lake Ontario and is home to a diverse population...
              </div>
              <Link
                to="/city/chauffeur-service-toronto"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={niagra}
                alt="Book online for your safe and secure chauffeur service at achauffeur. Best car services from Niagara Falls"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Niagara Falls</h4>
              <div className="  pb-2">
                Niagara Falls is a city located in the Niagara Region of
                Ontario, Canada, about an hour's drive from Toronto. The city is
                named after the Niagara Falls, a group of three waterfalls...
              </div>
              <Link
                to="/city/chauffeur-service-nigara-falls"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={buffalo}
                alt="High luxury and hygienic limousine services from and to Buffalo. Enjoy your journey with business class chauffeur services."
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Buffalo, NYC</h4>
              <div className="  pb-2">
                Buffalo is a city located in western New York, United States,
                along the eastern shore of Lake Erie. It is the second-most
                populous city in the state of New York, after New York City...
              </div>
              <Link
                to="/city/chauffeur-service-buffalo-nyc"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <img
              className="h-32 w-auto "
              src={hamilton}
              alt="Achauffeur has well-trained drivers at affordable price for your limo transportation. Limo services near Hamilton."
            />
            <div className="p-3 mt-3">
              <h4 className="roboto"> Hamilton</h4>
              <div className="  pb-2">
                Hamilton is a city located in southern Ontario, Canada, on the
                western end of Lake Ontario. It is the ninth largest city in
                Canada and the third largest in Ontario, with a population of
                over...
              </div>
              <Link
                to="/city/chauffeur-service-hamilton"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={markham}
                alt="Enjoy a pleasant journey with the best limo services from markham. Chauffeur focus on good quality services"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Markham</h4>
              <div className="  pb-2">
                Markham is a city located in the Greater Toronto Area (GTA) of
                Ontario, Canada. It is the fourth most populous city in the GTA,
                with a population of over 335,000 people...
              </div>
              <Link
                to="/city/chauffeur-service-markham"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={london}
                alt="achauffeur offers comfortable and first class limo services from London Ontario.best car services near London"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> London</h4>
              <div className="  pb-2">
                London is a city located in southwestern Ontario, Canada. It is
                the 11th largest city in Canada and the sixth largest in
                Ontario, with a population of over 396,000 people...
              </div>
              <Link
                to="/city/chauffeur-service-london"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={kitchener}
                alt="Do you need a best car service with driver? achauffeur has budget car services from and to Kitchener"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Kitchener</h4>
              <div className="  pb-2">
                Kitchener is a city located in southwestern Ontario, Canada,
                about 100 kilometers (60 miles) west of Toronto. It is the 10th
                largest city in Canada and the fourth largest in...
              </div>
              <Link
                to="/city/chauffeur-service-kitchener"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={peterborough}
                alt="High quality limo services with 24 hours customer support at achauffeur. Limo services from peterborough"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto"> Peterborough</h4>
              <div className="  pb-2">
                Peterborough is a city located in central Ontario, Canada, about
                100 kilometers (60 miles) northeast of Toronto. It is the 11th
                largest city in Ontario, with a population...
              </div>
              <Link
                to="/city/chauffeur-service-peterborough"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={oshawa}
                alt="Premium quality chauffeur services near oshawa from achauffeur. Hourly car services to and from oshawa"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">Oshawa</h4>
              <div className="  pb-2">
                Oshawa is a city located in southern Ontario, Canada, about 60
                kilometers (35 miles) east of Toronto. It is the largest
                municipality in the Regional Municipality of Durham and...
              </div>
              <Link
                to="/city/chauffeur-service-oshawa"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={whitby}
                alt="Whitby chauffeur services with highest customer satisfaction and affordable price rates."
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">Whitby</h4>
              <div className="  pb-2">
                Whitby is a town located in the Regional Municipality of Durham
                in southern Ontario, Canada, about 30 kilometers (20 miles) east
                of Toronto. It is the largest town...
              </div>
              <Link
                to="/city/chauffeur-service-whitby"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={ajax}
                alt="Start your journey with achauffeur from Ajax. limo services at ajax with professional drivers"
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">Ajax</h4>
              <div className="  pb-2">
                Ajax is a town located in the Regional Municipality of Durham in
                southern Ontario, Canada, about 25 kilometers (15 miles) east of
                Toronto. It is the sixth largest town in the...
              </div>
              <Link
                to="/city/chauffeur-service-ajax"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
          <div className="box--shadow hover:shadow-md overflow-hidden rounded-md">
            <div className="relative h-32 w-full overflow-hidden">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={barrie}
                alt="Barrie limo services. Chauffeur services at achauffeur focus on timely transportation and best services. "
              />
            </div>
            <div className="p-3 mt-3">
              <h4 className="roboto">Barrie</h4>
              <div className="  pb-2">
                Barrie is a city located in central Ontario, Canada, about 80
                kilometers (50 miles) north of Toronto. It is the 36th largest
                city in Canada and the seventh largest in Ontario, with...
              </div>
              <Link
                to="/city/chauffeur-service-barrie"
                className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base mb-2 "
              >
                <div>Learn more</div> <ArrowRightOutlined className="text-sm" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllCitiesBody;
