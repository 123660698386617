import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner3";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/wonderland/all-inclusive-limousine-services-from-aloft-vaughan-mills-to-canada’s-wonderland-complete-luxury-transportation-available.webp";
import one2 from "../../assets/images/hotels/attractions/wonderland/luxury-car-services-from-aloft-vaughan-mills-to-canada’s-wonderland-high-comfortable-and-hassle-free-services.webp";
import one3 from "../../assets/images/hotels/attractions/wonderland/business-class-trips-from-aloft-vaughan-mills-to-canada’s-wonderland-hourly-services-available-at-affordable-price.jpg";
import two1 from "../../assets/images/hotels/attractions/wonderland/private-car-services-from-novotel-toronto-vaughan-centre-to-canada’s-wonderland-fun-and-enjoyful-trip-from-achauffeur.jpg";
import two2 from "../../assets/images/hotels/attractions/wonderland/executive-and-personalized-limo-services-from-novotel-toronto-vaughan-centre-to-canada’s-wonderland-clean-and-comfortable-limos-from-achauffeur.jpg";
import two3 from "../../assets/images/hotels/attractions/wonderland/hire-a-private-car-from-novotel-toronto-vaughan-centre-to-canada’s-wonderland-we-have-well-trained-drivers-start-your-trip-with-pleasure.jpg";
import three1 from "../../assets/images/hotels/attractions/wonderland/chauffeur-service-at-springhill-suits-by-marriot-toronto-to-canada’s-wonderland-make-your-reservations-online.webp";
import three2 from "../../assets/images/hotels/attractions/wonderland/limo-cars-near-springhill-suits-by-marriot-toronto-to-canada’s-wonderland-hire-a-car-at-affordable-price.webp";
import three3 from "../../assets/images/hotels/attractions/wonderland/personal-chauffeur-service-from-springhill-suits-by-marriot-toronto-to-canada’s-wonderland-safe-and-secure-service-available.webp";
import four1 from "../../assets/images/hotels/attractions/wonderland/book-cars-online-from-courtyard-by-marriott-to-canada’s-wonderland-we-have-professional-drivers.jpg";
import four2 from "../../assets/images/hotels/attractions/wonderland/higenic-and-comfortable-car-services-from-courtyard-by-marriott-to-canada’s-wonderland-we-guarantee-a-stress-free-transportation.jpg";
import four3 from "../../assets/images/hotels/attractions/wonderland/premium-quality-limo-services-from-courtyard-by-marriott-to-canada’s-wonderland-our-rates-are-affordable.jpg";
const CanadasWonderland = () => {
  const hero = {
    title: "Canada’s wonderland",
    description:
      "With our first-class limousines, you may travel more comfortably to and from Canada’s wonderland. We ensure your safety and comfort while traveling to your destination",
  };

  const desc = {
    title: "It's easy, book your chauffeur service to Manitoulin Island.    ",
    description:
      "Canada's Wonderland is an amusement park located in Vaughan, Ontario, Canada. It is the largest amusement park in Canada and is owned and operated by Cedar Fair Entertainment Company. The park is open from May to October and is a popular destination for both tourists and local residents. Canada's Wonderland has a number of rides and attractions, including roller coasters, water rides, and thrill rides. The park also has a number of live shows and entertainment options, as well as a number of restaurants and food outlets.",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of Canada’s wonderland, as your own personal driver shows you the must-see tourist attractions near Canada’s wonderland. You can make an online booking or directly can make a call on the given numbers for your trip.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Aloft Vaughan Mills to canada’s wonderland.",
        "High comfortable and hassle free luxury limousine services from Aloft Vaughan Mills to canada’s wonderland",
        "Hourly limo services at affordable price from Aloft Vaughan Mills to canada’s wonderland. Business class trips for your vacations",
      ],
      title: "Aloft Vaughan Mills",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyable trips from Novotel Toronto Vaughan Centre to canada’s wonderland. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Novotel Toronto Vaughan Centre to canada’s wonderland. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Novotel Toronto Vaughan Centre to canada’s wonderland. Hire a private car at an affordable price",
      ],
      title: "Novotel Toronto Vaughan Centre",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from SpringHill suits by Marriot Toronto to canada’s wonderland",
        "Hire a car at affordable price near SpringHill suits by Marriot Toronto to canada’s wonderland.best chauffeur services",
        "Safe and secure personal chauffeur services from SpringHill suits by Marriot Toronto to canada’s wonderland. High comfortable and luxury",
      ],
      title: "SpringHill suits by Marriot Toronto",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from Courtyard by Marriott  to canada’s wonderland. Best limo services to canada’s wonderland",
        "Achauffeur offers hygienic and comfortable car services from Courtyard by Marriott  to canada’s wonderland. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from Courtyard by Marriott to canada’s wonderland. Our rates are affordable",
      ],
      title: "Courtyard by Marriott ",
    },
  ];
  const mostBookeds = [
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          All-Inclusive Manitoulin Island Private Chauffeur Services.
        </title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Reliable and Efficient Private Chauffeur Service To/from Blue Manitoulin Island. Best Price Guaranteed.
          "
        />
        <meta
          name="keywords"
          content="Chauffeur service to Canada Wonderland, Chauffeur service from Canada Wonderland, chauffeur service, Chauffeur service from toronto to Canada Wonderland, airport car service, Airport transfer, airport limo service, black car service, airport limo, airport limousine, toronto chauffeur service, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-canadas-wonderland"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default CanadasWonderland;
