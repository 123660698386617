import React from "react";
import { Link } from "react-router-dom";
import BookingForm2 from "../application/BookingForm2";

const Hero2 = () => {
  return (
    <div>
      <div className="hero--1 ">
        <div className="hero--cover h-full w-full ">
          <div className="booking-container  h-full ">
            <div className="grid md:grid-cols-2 gap-0 md:gap-10 items-center">
              <div className="px-3 sm:px-0 text-center md:text-left mt-10 lg:-mt-14">
                <h1 className="roboto uppercase text-white ">
                  <span className="text-pri-500 roboto">Chauffeur </span> Car
                  Services
                </h1>
                <div className="text-white mb-4  -mt-4  sm:text-2xl  ">
                  Get estimates for our platinum black car service with airport
                  transfers, hourly rides, and private chauffeur car solutions.
                  Safety is our priority.
                </div>
                <Link
                  to="/book-now-a-chauffeur-service"
                  className="btn2 text-base"
                >
                  Ride Estimates Online
                </Link>
              </div>
              <div className="my-10 sm:my-0 sm:p-3 overflow-hidden bg-sec-700">
                <BookingForm2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero2;
