import React from "react";

const Map = () => {
  return (
    <>
      <div>
        <iframe
          title="map location"
          className="w-full"
          // width="1080"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=500%20Beck%20Crescent,%20Ajax,%20ON%20L1Z%201C9&t=&z=13&ie=UTF8&iwloc=&output=embed"
          scrolling="no"
        ></iframe>
      </div>
    </>
  );
};
export default Map;
