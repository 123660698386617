import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";

// stay images
import one1 from "../../assets/images/hotels/cities/toronto/ritz-carlton-hotel-chauffeur-service-toronto-limo-services-car-service-near-toronto.jfif";
import one2 from "../../assets/images/hotels/cities/toronto/ritz-carlton-hotel-private-chauffeur-service-toronto-car-service-from-toronto.jfif";
import one3 from "../../assets/images/hotels/cities/toronto/airport-transfer-services-from-ritz-carlton-hotel-toronto-best-price-car-services-in-toronto.jfif";
import two1 from "../../assets/images/hotels/cities/toronto/limousine-services-from-le-germain-hotel-car-service-24-hours-in-toronto-best-car-services-in-toronto.png";
import two2 from "../../assets/images/hotels/cities/toronto/chauffeur-service-in-toronto-to-le-germain-hotel-at-affordable-price-limo-car-service-downtown-toronto.png";
import two3 from "../../assets/images/hotels/cities/toronto/hourly-chauffeur-service-from-le-germain-hotel-toronto-affordable-car-services-near-le-germain-hotel.png";
import three1 from "../../assets/images/hotels/cities/toronto/car-service-from-toronto-to-shangri-la-hotel-toronto-all-the-way-limo-services-toronto.png";
import three2 from "../../assets/images/hotels/cities/toronto/executive-chauffeur-service-near-shangri-la-hotel-toronto-comfortable-chauffeur-services-in-toronto.png";
import three3 from "../../assets/images/hotels/cities/toronto/budget-car-hire-toronto-to-shangri-la-hotel-toronto-affordable-price-luxury-service-limousines.png";
import four1 from "../../assets/images/hotels/cities/toronto/car-hire-near-four-seasons-hotel-toronto-business-class-limo-services.jpg";
import four2 from "../../assets/images/hotels/cities/toronto/all-inclusive-limo-services-near-four-seasons-hotel-toronto-limo-services-online-bookings.jpg";
import four3 from "../../assets/images/hotels/cities/toronto/toronto-limo-services-to-four-seasons-hotel-toronto-car-service-with-driver.jpg";
import { Helmet } from "react-helmet-async";

const Toronto = () => {
  const hero = {
    title: "HIRE A CHAUFFEUR IN TORONTO",
    description:
      "Hire a chauffeur with a car, SUV, van, or bus in Toronto for seamless airport transfers and private rides. Perfect for both business and leisure travelers.",
  };

  const desc = {
    title: "Toronto",
    description:
      <span>Searching for exceptional ground transportation in <b>Toronto</b>? <b>A Chauffeur by Get Limo Inc</b>. offers 24/7 service with a diverse fleet of sedans, SUVs, vans, and buses at fixed prices. Whether you are attending the <b>Toronto International Film Festival</b> or exploring the <b>Toronto Christmas Market</b>, our <b>luxury car service in Toronto</b> and <b>chauffeur service in Toronto</b> provide comfort and sophistication.</span>,
    website: "https://www.toronto.ca/explore-enjoy/visitor-services/",
    description2:
      <span>Experience seamless airport transfers from <b>Toronto Pearson International Airport (YYZ)</b>, ensuring a smooth journey every time. Our <b>executive car service in Toronto</b> caters to business needs, while our <b>private car hire in Toronto</b> is perfect for leisure. We also specialize in <b>long-distance rides in Toronto</b> for those traveling beyond the city limits. Enjoy the convenience of our <b>meet and greet service</b>, and families can opt for our <b>ride with car seats in Toronto</b> for added safety.</span>,
  };

  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from Toronto to The Ritz-Carlton hotel best car services from achauffeur",
        "Private chauffeur service from Toronto city to The Ritz-Carlton hotel. luxury car service near toronto",
        "Find airport transfer services in toronto to The Ritz-Carlton at an affordable price and best services",
      ],
      rating: "8.5",
      title: "The Ritz-Carlton",
      location: "181 Wellington St",
      distance: "1.4 km from city center",
      map: "https://maps.google.com/maps?q=The%20Ritz-Carlton,%20Toronto&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Le Germain Hotel to toronto city. Best car services from achauffeur",
        "Safe and secure chauffeur services in Toronto to Le Germain Hotel. All-inclusive car services downtown Toronto",
        "Hourly chauffeur service from achauffeur to Le Germain Hotel from toronto canada at affordable price rates",
      ],
      rating: "7.5",
      title: "Le Germain Hotel",
      location: "Maple Leaf Square, 75 Bremner Blvd",
      distance: "1.7 km from city center",
      map: "https://maps.google.com/maps?q=Le%20Germain%20Hotel%20Toronto%20Maple%20Leaf%20Square&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from toronto to Shangri-La hotel. Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Shangri-La hotel from toronto city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in toronto. Hire budget cars and limos to Shangri-La hotel. Luxury service available",
      ],
      rating: "7.5",
      title: "Shangri-La Toronto",
      location: "188 University Ave, Toronto",
      distance: "800 m from city center",
      map: "https://maps.google.com/maps?q=Shangri-La%20Toronto,%20188%20University%20Ave&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in toronto to Four Seasons Hotel Toronto. Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from toronto city to Four Seasons Hotel Toronto",
        "Do you need a car with driver? Achauffeur offers limo services with driver in toronto to Four Seasons Hotel Toronto",
      ],
      rating: "6.5",
      title: "Four Seasons Hotel Toronto",
      location: "60 Yorkville Ave, Toronto",
      distance: "2.2 km from city center",
      map: "https://maps.google.com/maps?q=Four%20Seasons%20Hotel%20Toronto,%2060%20Yorkville%20Ave&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Toronto",
      end: "Niagara Falls, Ontario",
      duration: "1 hr 25 min - 2 hr",
      distance: "128 km",
      price: "211.72",
    },
    {
      start: "Toronto",
      end: "The Blue Mountains, Ontario",
      duration: "2 hr 10 min - 2 hr 50 min",
      distance: "171 km",
      price: "211.72",
    },
    {
      start: "Toronto",
      end: "Hamilton, Ontario",
      duration: "55 min - 1 hr 20 min",
      distance: "69.9 km",
      price: "211.72",
    },
    {
      start: "Toronto",
      end: "Mississauga, Ontario",
      duration: "26 - 50 min",
      distance: "27.7 km",
      price: "211.72",
    },
    {
      start: "Toronto",
      end: "Burlington, Ontario",
      duration: "45 min - 1 hr 10 min",
      distance: "57.2 km",
      price: "211.72",
    },
    {
      start: "Toronto",
      end: "Ajax, Ontario",
      duration: "40 min - 1 hr 5 min",
      distance: "47.7 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Toronto Chauffeur Service | Airport Transfer, Sprinter Van Hire</title>
        <meta
          name="description"
          content="Luxury Toronto Chauffeur Service For Airport Transfers, Sprinter Van For Hire, And Long-Distance Rides. Available 24/7 For Business Or Leisure Travel."
        />
        <meta
          name="keywords"
          content="Toronto Chauffeur Service, Toronto Airport Transfer, Luxury Car Service Toronto, Sprinter Van For Hire Toronto, Executive Car Service Toronto, Toronto Limousine Rental, Private Car Hire Toronto, Toronto Long-Distance Rides, VIP Transportation Toronto, Intercity Transportation Toronto"
        />
        <link rel="canonical" href="/city/chauffeur-service-toronto" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Toronto;
