import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner3";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/thousandisland/all-inclusive-limousine-services-from-holiday-inn-kingston-waterfront-to-thousand-island-complete-luxury-transportation-available.jfif";
import one2 from "../../assets/images/hotels/attractions/thousandisland/luxury-car-services-from-holiday-inn-kingston-waterfront-to-thousand-island-high-comfortable-and-hassle-free-services.jfif";
import one3 from "../../assets/images/hotels/attractions/thousandisland/business-class-trips-from-holiday-inn-kingston-waterfront-to-thousand-island-hourly-services-available-at-affordable-price.jfif";
import two1 from "../../assets/images/hotels/attractions/thousandisland/private-car-services-from-residence-inn-by-marriott-kingston-water's-edge-to-thousand-island-fun-and-enjoyful-trip-from-achauffeur.webp";
import two2 from "../../assets/images/hotels/attractions/thousandisland/executive-and-personalized-limo-services-from-residence-inn-by-marriott-kingston-water's-edge-to-thousand-island-clean-and-comfortable-limos-from-achauffeur.webp";
import two3 from "../../assets/images/hotels/attractions/thousandisland/hire-a-private-car-from-residence-inn-by-marriott-kingston-water's-edge-to-thousand-island-we-have-well-trained-drivers-start-your-trip-with-pleasure.webp";
import three1 from "../../assets/images/hotels/attractions/thousandisland/chauffeur-service-at-the-rosemount-inn-to-thousand-island-make-your-reservations-online.jpg";
import three2 from "../../assets/images/hotels/attractions/thousandisland/limo-cars-near-the-rosemount-inn-to-thousand-island-hire-a-car-at-affordable-price.jpg";
import three3 from "../../assets/images/hotels/attractions/thousandisland/personal-chauffeur-service-from-the-rosemount-inn-to-thousand-island-safe-and-secure-service-available.jpg";
import four1 from "../../assets/images/hotels/attractions/thousandisland/book-cars-online-from-frontenac-club-to-thousand-island-we-have-professional-drivers.jpg";
import four2 from "../../assets/images/hotels/attractions/thousandisland/higenic-and-comfortable-car-services-from-frontenac-club-to-thousand-island-we-guarantee-a-stress-free-transportation.jpg";
import four3 from "../../assets/images/hotels/attractions/thousandisland/premium-quality-limo-services-from-frontenac-club-to-thousand-island-our-rates-are-affordable.jpg";
const ThousandIsland = () => {
  const hero = {
    title: "Thousand Island",
    description:
      "Experience Achauffeur's first-rate, premium and luxury limousine service to Thousand island. We meticulously maintain our fleet of vehicles to guarantee secure and comfortable journeys.",
  };
  const desc = {
    title: "It's easy, book your chauffeur service at Thousand Island.",
    description:
      "The Thousand Islands is a group of more than 1,800 islands located in the Saint Lawrence River, which forms part of the border between the United States and Canada. The islands are located between Lake Ontario and the Gulf of Saint Lawrence, and they stretch for about 50 miles from the southeastern tip of Ontario to the northeastern tip of New York state.The Thousand Islands region is a popular tourist destination, known for its natural beauty, history, and recreational opportunities.",

    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of Thousand island, as your own personal driver shows you the must-see tourist attractions near Thousand island. You can make an online booking or directly can make a call on the given numbers for your trip.",
    website: "https://www.billybishopairport.com/",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Holiday Inn Kingston-Waterfront to Thousand island.",
        "High comfortable and hassle free luxury limousine services from Holiday Inn Kingston-Waterfront to Thousand island",
        "Hourly limo services at affordable price from Holiday Inn Kingston-Waterfront to Thousand island. Business class trips for your vacations",
      ],
      title: "Holiday Inn Kingston-Waterfront",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyful trips from Residence Inn by Marriott Kingston Water's Edge to Thousand island. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Residence Inn by Marriott Kingston Water's Edge to Thousand island. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Residence Inn by Marriott Kingston Water's Edge to Thousand island. Hire a private car at an affordable price",
      ],
      title: "Residence Inn by Marriott Kingston Water's Edge",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from The Rosemount Inn to Thousand island",
        "Hire a car at affordable price near The Rosemount Inn to Thousand island.best chauffeur services",
        "Safe and secure personal chauffeur services from The Rosemount Inn to Thousand island. High comfortable and luxury",
      ],
      title: "The Rosemount Inn",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from Frontenac Club to Thousand island. Best limo services to Thousand island",
        "Achauffeur offers hygienic and comfortable car services from Frontenac Club to Thousand island. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from Frontenac Club to Thousand island. Our rates are affordable",
      ],
      title: "Frontenac Club",
    },
  ];
  const mostBookeds = [
    {
      start: "Thousand Island",
      end: "Toronto, Ontario",
      duration: "3h 14 min",
      distance: "283 Km",
      price: "211.72",
    },
    {
      start: "Thousand Island",
      end: "Kingston, Ontario",
      duration: "42 min",
      distance: "23.4 Km",
      price: "211.72",
    },
    {
      start: "Thousand Island",
      end: "Ottawa, Ontario",
      duration: "2h 19 min",
      distance: "190 Km",
      price: "211.72",
    },
    {
      start: "Thousand Island",
      end: "Toronto Pearson International Airport",
      duration: "3h 12 min",
      distance: "290 Km",
      price: "211.72",
    },
    {
      start: "Thousand Island",
      end: "Peterborough, Ontario",
      duration: "2h 41 min",
      distance: "201 Km",
      price: "211.72",
    },
    {
      start: "Thousand Island",
      end: "Belleville, Ontario",
      duration: "1h 25 min",
      distance: "103 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          All-Inclusive Private Chauffeur Service To Thousand Island.
        </title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Best All-Inclusive, Reliable and Efficient Chauffeur Service To Thousand Island. Best Price Guaranteed.
          "
        />
        <meta
          name="keywords"
          content="Chauffeur service to thousand island, Chauffeur service from Thousand Island, chauffeur service, Chauffeur service from toronto to Thousand island, airport car service, airport limo service, black car service, airport limo, airport limousine, toronto chauffeur service, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-thousand-islands"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default ThousandIsland;
