import React from "react";
import { Link } from "react-router-dom";

import airport1 from "../../assets/images/chauffeur-service-at-toronto-pearson-international-airport-affordable-price-luxury-services.jpg";
import airport2 from "../../assets/images/personal-chauffeur-services-from-billy-bishop-toronto-city-airport-secure-and-stress-free-airport-transfer-services.jpg";
import airport3 from "../../assets/images/limo-services-from-and-to-london-international-airport-reliable-and-fastest-airport-transfer-services.jpg";
import airport4 from "../../assets/images/business-class-and-luxury-airport-transfer-services-from-and-to-john-c-munro-hamilton-international-airport.jpg";

const MajorAirports = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 items-center gap-5 lg:gap-10">
          <div>
            <h2>Major Airports</h2>
            <div className="-mt-2 text-base mb-3">
              Experience top-tier chauffeur services at major airports worldwide. Whether you 
              need <a href="https://achauffeur.com/services/private-car-service"><b>car hire with a driver</b></a>, a minibus, or a personal chauffeur, we offer
              convenience and style. From <a href="https://achauffeur.com/services/airport-transfer"><b>airport transfers</b></a> to special occasions, our services
              ensure a seamless journey. Enjoy our comprehensive range of services, 
              including private car, limo, charter bus, and <a href="https://achauffeur.com/services/meet-and-greet-service"><b>meet-and-greet at the airport</b></a>, for a
              complete travel experience.
            </div>
            <div className="text-base mb-5">
              Arriving at any airport? <b>"A Chauffeur"</b> Offers High-Priority limo service 
              with a best price guarantee, including airport transfers,
              for a seamless experience.
            </div>
            <Link to="/airports" className="btn1">
              View all Airports
            </Link>
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-5">
            <Link
              to="/airport/toronto-pearson-international-airport-chauffeur-service"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={airport1}
                  alt="Book luxury chauffeur services at toronto pearson international airport. Airport transfers at affordable prices "
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">
                  Toronto Pearson International Airport (YYZ)
                </div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/airport/billy-bishop-toronto-city-airport-chauffeur-service"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={airport2}
                  alt="Secure and stress-free airport transfer services from billy bishop toronto city airport. Personal and VIP chauffeur services with best prices"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">
                  {" "}
                  Billy Bishop Toronto City Airport (YTZ)
                </div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/airport/london-international-airport-chauffeur-service"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={airport3}
                  alt="Reliable and fastest airport transfer services from London international airport. Best limo services from achauffeur"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">
                  {" "}
                  London International Airport (YXU)
                </div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
            <Link
              to="/airport/john-c.-munro-hamilton-international-airport-chauffeur-service"
              className="box--shadow rounded-md overflow-hidden bg-white hover:shadow-lg"
            >
              <div className="relative h-20 w-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={airport4}
                  alt="Achauffeur offers business class and luxury airport transfer services from and to john c munro hamilton international airport"
                />
              </div>
              <div className="p-3 text-sec-500">
                <div className="text-base font-semibold">
                  {" "}
                  John C. Munro Hamilton International Airport(YHM)
                </div>
                {/* <div>5 routes to/from this airport</div> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MajorAirports;
