import React from "react";
import car from "../../../assets/images/fleets/private-limo-service-with-chevrolet-suburban.png";
import { Link } from "react-router-dom";

const ChevroletSuburban = () => {
  return (
    <div className="px-3 py-12 sm:py-16  bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-start">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Achauffeur offers private limo services with chevrolet suburban"
            />
          </div>
          <div>
            <h3>SUV</h3>
            <h4 className="text-pri-500">Chevrolet Suburban (or similar)</h4>
            <div className="text-base mb-3">
              Experience the luxury of modern SUV transportation with this
              spacious Suburban. It promises a smooth and comfortable ride for
              everyone.
            </div>
            <div className="grid sm:grid-cols-2 gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>
                <div>Up to 5 passengers, 4 comfortable</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>5 large, 2 medium 4 small luggages</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-dvd-line  "></i>
                </div>
                <div>DVD</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-record-circle-line  "></i>
                </div>
                <div>4 wheel drive</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-settings-3-line  "></i>
                </div>
                <div> Rear passenger controls for comfort</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChevroletSuburban;
