import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Web from "../layouts/Web";
import AboutUs from "../views/AboutUs";
import BillyBishop from "../views/airports/BillyBishop";
import BuffaloNiagara from "../views/airports/BuffaloNiagara";
import GreaterSudbury from "../views/airports/GreaterSudbury";
import JohnCMunro from "../views/airports/JohnCMunro";
import LondonInternational from "../views/airports/LondonInternational";
import MontrealPierre from "../views/airports/MontrealPierre";
import NiagaraFallsAir from "../views/airports/NiagaraFallsAir";
import OttawaMc from "../views/airports/OttawaMc";
import TorontoPearson from "../views/airports/TorontoPearson";
import AllAirports from "../views/AllAirports";
import AllAttractions from "../views/AllAttractions";
import AllCities from "../views/AllCities";
import AlgonquinProvincial from "../views/attractions/AlgonquinProvincial";
import BlueMountain from "../views/attractions/BlueMountain";
import BlueMountainVillage from "../views/attractions/BlueMountainVillage";
import BrucePeninsula from "../views/attractions/BrucePeninsula";
import CanadasWonderland from "../views/attractions/CanadasWonderland";
import CNTower from "../views/attractions/CNTower";
import ManitoulinIsland from "../views/attractions/ManitoulinIsland";
import MuskokaDistrict from "../views/attractions/MuskokaDistrict";
import NiagaraFall from "../views/attractions/NiagaraFall";
import RoyalOntarioMuseum from "../views/attractions/RoyalOntarioMuseum";
import ThousandIsland from "../views/attractions/ThousandIsland";
import Tobermory from "../views/attractions/Tobermory";
import BookNow from "../views/BookNow";
import Ajax from "../views/cities/Ajax";
import Barrie from "../views/cities/Barrie";
import BuffaloNYC from "../views/cities/BuffaloNYC";
import Hamilton from "../views/cities/Hamilton";
import Kitchener from "../views/cities/Kitchener";
import London from "../views/cities/London";
import Markham from "../views/cities/Markham";
import NiagaraFalls from "../views/cities/NiagaraFalls";
import Oshawa from "../views/cities/Oshawa";
import Peterborough from "../views/cities/Peterborough";
import Toronto from "../views/cities/Toronto";
import Whitby from "../views/cities/Whitby";
import ContactUs from "../views/ContactUs";
import Help from "../views/Help";
import Home from "../views/Home";
import OurFleet from "../views/OurFleet";
import PrivacyPolicy from "../views/PrivacyPolicy";
import AirportTransfer from "../views/services/AirportTransfer";
import CharterBusTours from "../views/services/CharterBusTours";
import GroundTransportation from "../views/services/GroundTransportation";
import IntercityRides from "../views/services/IntercityRides";
import EventLimousineService from "../views/services/LimousineService";
import MeetAndGreetService from "../views/services/MeetAndGreetService";
import ParcelDeliveryService from "../views/services/ParcelDeliveryService";
import PrivateCarService from "../views/services/PrivateCarService";
import VIPChauffeurService from "../views/services/VIPChauffeurService";
import WineryTourService from "../views/services/WineryTourService";
import Terms from "../views/Terms";

const Main = () => {
  return (
    <>
      <Routes>
        <Route element={<Web />}>
          {/* main pages */}
          <Route path="/home" element={<Home />} />
          <Route path="/airports" element={<AllAirports />} />
          <Route path="/cities" element={<AllCities />} />
          <Route path="/tourist-attractions" element={<AllAttractions />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/help" element={<Help />} />
          <Route path="/faqs" element={<Help />} />
          <Route path="/book-now-a-chauffeur-service" element={<BookNow />} />

          {/* <Route path="/intercity-rides" element={<IntercityRides />} />
          <Route path="/limousine-service" element={<LimousineService />} /> */}
          {/* services */}
          <Route
            path={"/services/intercity-chauffeured-rides"}
            element={<IntercityRides />}
          />
          <Route
            path="/services/airport-transfer"
            element={<AirportTransfer />}
          />
          <Route
            path="/services/event-limousine-service"
            element={<EventLimousineService />}
          />
          <Route
            path="services/vip-chauffeur-service"
            element={<VIPChauffeurService />}
          />
          <Route
            path="services/private-car-service"
            element={<PrivateCarService />}
          />
          <Route
            path="/services/ground-transportation"
            element={<GroundTransportation />}
          />
          <Route
            path="/services/meet-and-greet-service"
            element={<MeetAndGreetService />}
          />
          <Route
            path="/services/parcel-delivery-service"
            element={<ParcelDeliveryService />}
          />
          <Route
            path="/services/winery-tour-service"
            element={<WineryTourService />}
          />
          <Route
            path="/services/charter-bus-tour"
            element={<CharterBusTours />}
          />
          <Route path="/our-fleet" element={<OurFleet />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          {/* Airports */}

          <Route
            path="/airport/toronto-pearson-international-airport-chauffeur-service"
            element={<TorontoPearson />}
          />
          <Route
            path="/airport/billy-bishop-toronto-city-airport-chauffeur-service"
            element={<BillyBishop />}
          />

          <Route
            path="/airport/london-international-airport-chauffeur-service"
            element={<LondonInternational />}
          />
          <Route
            path="/airport/john-c.-munro-hamilton-international-airport-chauffeur-service"
            element={<JohnCMunro />}
          />
          <Route
            path="/airport/buffalo-niagara-international-airport-chauffeur-service"
            element={<BuffaloNiagara />}
          />
          <Route
            path="/airport/ottawa-mcdonald-cartier-international-airport-chauffeur-service"
            element={<OttawaMc />}
          />
          <Route
            path="/airport/montreal-pierre-elliott-trudeau-international-airport-chauffeur-service"
            element={<MontrealPierre />}
          />
          <Route
            path="/airport/greater-sudbury-airport-chauffeur-service"
            element={<GreaterSudbury />}
          />
          <Route
            path="/airport/niagara-falls-international-airport-chauffeur-service"
            element={<NiagaraFallsAir />}
          />
          {/* Cities */}
          <Route
            path="/city/chauffeur-service-buffalo-nyc"
            element={<BuffaloNYC />}
          />
          <Route
            path="/city/chauffeur-service-hamilton"
            element={<Hamilton />}
          />
          <Route
            path="/city/chauffeur-service-kitchener"
            element={<Kitchener />}
          />
          <Route path="/city/chauffeur-service-london" element={<London />} />
          <Route path="/city/chauffeur-service-markham" element={<Markham />} />
          <Route
            path="/city/chauffeur-service-nigara-falls"
            element={<NiagaraFalls />}
          />
          <Route path="/city/chauffeur-service-oshawa" element={<Oshawa />} />
          <Route
            path="/city/chauffeur-service-peterborough"
            element={<Peterborough />}
          />
          <Route path="/city/chauffeur-service-toronto" element={<Toronto />} />
          <Route path="/city/chauffeur-service-whitby" element={<Whitby />} />
          <Route path="/city/chauffeur-service-ajax" element={<Ajax />} />
          <Route path="/city/chauffeur-service-barrie" element={<Barrie />} />

          {/* Tourist attractions */}
          <Route
            path="/tourist-attraction/chauffeur-service-to-niagara-falls"
            element={<NiagaraFall />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-cn-tower"
            element={<CNTower />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-thousand-islands"
            element={<ThousandIsland />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-muskoka-district-municipality"
            element={<MuskokaDistrict />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-algonquin-provincial-park"
            element={<AlgonquinProvincial />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-tobermory"
            element={<Tobermory />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-blue-mountain-ski-resort"
            element={<BlueMountain />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-manitoulin-island"
            element={<ManitoulinIsland />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-blue-mountain-village"
            element={<BlueMountainVillage />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-bruce-peninsula-national-park"
            element={<BrucePeninsula />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-canadas-wonderland"
            element={<CanadasWonderland />}
          />
          <Route
            path="/tourist-attraction/chauffeur-service-to-royal-ontario-museum"
            element={<RoyalOntarioMuseum />}
          />

          <Route path="*" element={<Navigate replace to="/home" />} />
        </Route>
      </Routes>
    </>
  );
};

export default Main;
