import React from "react";
import { Helmet } from "react-helmet-async";
import AllCitiesBody from "../components/web/allCities/AllCitiesBody";
import AllCitiesHero from "../components/web/allCities/AllCitiesHero";

const AllCities = () => {
  return (
    <>
      <Helmet>
        <title>Looking for luxury limo services around Canadian cities?</title>
        <meta
          name="description"
          content="Chauffeur limo services are available in hundreds of cities and airports across Canada. Book with a chauffeur 24/7. "
        />
        <meta
          name="keywords"
          content="Luxury limo service, chauffeur limo service, luxury limo services, limo service to Toronto, limo service to Niagara falls, limo service to Hamilton, limo service to Buffalo airport, limo service to London, ON, limo service to markham, limo service to Ajax, limo service to Whitby, limo service to Oshawa, limo service Kitchener, limo service to Peterborough, limo service to barrie "
        />
        <link rel="canonical" href="/cities" />
      </Helmet>
      <AllCitiesHero />
      <AllCitiesBody />
    </>
  );
};

export default AllCities;
