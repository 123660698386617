import React from "react";
import Banner1 from "../components/web/banners/Banner1";
import HowItWork from "../components/web/HowItWork";
import Services from "../components/web/Services";
import Faq from "../components/web/Faq";
import MajorAirports from "../components/web/MajorAirports";
import PopularCities from "../components/web/PopularCities";
import TouristAttractions from "../components/web/TouristAttractions";
import Hero2 from "../components/web/Hero2";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const faqs = [
    {
      header: "How long will the chauffeur wait for the passengers at the pickup point?",
      key: "1",
      answer: "At Achauffeur, we prioritize punctuality and convenience. For standard pickups, we offer a 15-minute wait time and arrive 5 minutes early. We also offer a meet and greet service upon request, which includes assistance with luggage. For airport pickups, we provide a one-hour complimentary wait time, track your flight, and offer a meet and greet inside the terminal, available upon your request. Your satisfaction is our top priority.",
    },
    {
      header: "Achauffeur's cancellation policy and transparency for different vehicles?",
      key: "2",
      answer:
        "Cancellations are accepted only by phone. For sedans/SUVs, please cancel 48 hours or more before pickup to avoid a fee. Specialty vehicles, like limousines or luxury cars, have a 72-hour cancellation window with a fee. Contact us by phone to cancel, and keep the email confirmation as proof. This policy ensures transparency and maintains high service standards, especially for specialty vehicle requests.",
    },
    {
      header: "What is the maximum advance booking period for online rides with Achauffeur?",
      key: "3",
      answer:
        "Planning a trip with Achauffeur? Whether you prefer to plan ahead or need a ride at the last minute, we've got you covered. For airport transfers, you can make bookings as close as 24 hours before your pick-up time. However, if you're looking to reserve one of our specialty vehicles, like limousines or luxury cars, we recommend booking at least 72 hours in advance to ensure availability and a tailored experience. Changes or cancellations are hassle-free and free of charge up to 48 hours before your sedan or SUV pick-up time. If you have any inquiries or require assistance, our customer service team is ready to assist you. Your journey, your choice.",
    },
    {
      header: "What's the passenger capacity of Achauffeur's different vehicle classes?",
      key: "4",
      answer:
        "At Achauffeur, safety is paramount. We provide a range of vehicle classes to suit diverse occasions, including our elegant black car service. Whether you require transportation for airport transfers, special events, or daily commutes, we have options tailored to your needs. Our Standard Class (Sedan) accommodates up to 3 passengers, while Van/SUV Standard is designed for groups of up to 7. Enjoy an upscale experience with First Class for 3 passengers, or opt for Van First Class for groups of up to 6. For larger gatherings, our options include Minibus Ford Transit for 12 passengers and Sprinter Van for 15 passengers. Additionally, you can bring 1 small carrion bags. Select the ideal class for your journey with confidence in both safety and comfort.",
    },
    {
      header: "How can I request a car seat for my airport transfer with Achauffeur?",
      key: "5",
      answer:
        "When making your reservation for an airport transfer, simply inform us that you require a car seat. Provide us with details about your child's age and weight so that we can ensure the appropriate car seat is installed in the vehicle.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
        Book a Chauffeur Ride: Airport Transfer & Black Car Services
        </title>
        <meta
          name="description"
          content="A Chauffeur: Your choice for Ground Transport, Private Chauffeur Services, Airport Transfers, Black Car Services, and Meet-and-Greet Service. Get an Estimate!"
        />
        <meta
          name="keywords"
          content="A Chauffeur, Private Chauffeur Service, Airport Transfer, Black Car Service, Airport Car Service, Car Hire with Driver, Airport Meet and Greet Service, Ground Transportation, Book a chauffeur ride, Luxury Chauffeur Rides,"
        />
        <link rel="canonical" href="/home" />
      </Helmet>
      <Hero2 />

      <Services />
      <MajorAirports />
      <PopularCities />
      <TouristAttractions />

      <Banner1 />

      <HowItWork />

      {/* <BlogPost /> */}

      <Faq faqs={faqs} />
    </>
  );
};

export default Home;
