import React from "react";
import car from "../../../assets/images/exclusive-limo-service-with-ford-transit.png";
import { Link } from "react-router-dom";

const ExecutiveVan = () => {
  return (
    <div className="px-3 py-12 sm:py-16  bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-start">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Hire exclusive limo services with ford transit at achauffeur"
            />
          </div>
          <div>
            <h3>Executive VAN</h3>
            <h4 className="text-pri-500">Ford Transit (or similar)</h4>
            <div className="text-base mb-3">
              Executive Van can provide you with the perfect Chauffeured
              transportation. Our vehicles will fit up to 12 people and have all
              the amenities needed for a night on the town or a wedding.
            </div>
            <div className="grid sm:grid-cols-2 gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>
                <div>Up to 12 passengers</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>12 luggages</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>Privacy glass windows</div>
              </div>

              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>Overhead storage compartments</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>High celling for standing and easy movement</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveVan;
