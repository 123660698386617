import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";

import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";

// stay images

import one1 from "../../assets/images/hotels/airports/billyBishop/four-seasons-hotel-chauffeur-service-billy-bishop-toronto-city-airport.jpg";
import one2 from "../../assets/images/hotels/airports/billyBishop/four-seasons-hotel-private-chauffeur-service-billy-bishop-toronto-city-airport.jpg";
import one3 from "../../assets/images/hotels/airports/billyBishop/four-seasons-hotel-airport-car-service-billy-bishop-toronto-city-airport.jpg";
import two1 from "../../assets/images/hotels/airports/billyBishop/chauffeur-service-in-toronto-to-fairmont-royal-york-hotel.jpg";
import two2 from "../../assets/images/hotels/airports/billyBishop/achauffeur-airport-transfer-to-fairmont-royal-york-hotel.jpg";
import two3 from "../../assets/images/hotels/airports/billyBishop/car-service-from-billy-bishop-toronto-city-airport-to-fairmont-royal-york-hotel.jpg";
import three1 from "../../assets/images/hotels/airports/billyBishop/executive-chauffeur-service-from-billy-bishop-toronto-city-airport-to-the-ritz-carlton-hotel.jfif";
import three2 from "../../assets/images/hotels/airports/billyBishop/hourly-chauffeur-service-from-billy-bishop-toronto-city-airport-to-the-ritz-carlton-hotel.jfif";
import three3 from "../../assets/images/hotels/airports/billyBishop/chauffeur-service-near-billy-bishop-toronto-city-airport-to-the-ritz-carlton-hotel.jfif";
import four1 from "../../assets/images/hotels/airports/billyBishop/limo-service-at-billy-bishop-toronto-city-airport-to-hotel-x-toronto-by-library-hotel-collection.jpg";
import four2 from "../../assets/images/hotels/airports/billyBishop/car-hire-at-billy-bishop-toronto-city-airport-to-hotel-x-toronto-by-library-hotel-collection.jpg";
import four3 from "../../assets/images/hotels/airports/billyBishop/budget-car-hire-billy-bishop-toronto-city-airport-to-hotel-x-toronto-by-library-hotel-collection.jpg";
import { Helmet } from "react-helmet-async";

const BillyBishop = () => {
  const hero = {
    title: "Billy Bishop Toronto City Airport (YTZ)",
    description:
      "Have a comfortable and Hassle-free Airport chauffeur service from Billy Bishop Toronto Airport to your destination. Our Chauffeur Service at Billy Bishop Toronto provides transfers for both business and leisure for everyone.",
  };
  const desc = {
    title: "Billy Bishop Toronto City Airport",
    description:
      "Billy Bishop airport is offering service to more than 20 cities in Canada and the U.S., with connection opportunities to more than 80 international destinations via our airlines’ networks, Billy Bishop Airport is an important international gateway and a key driver to Toronto’s economy which traditionally welcomes approximately 2.8 million passengers per year.",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Billy Bishop Toronto City Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle = "Stay at top unique properties near Billy Bishop Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Billy Bishop Toronto City Airport to Four Seasons Hotel. Book with affordable price",
        "Private chauffeur service from Billy Bishop Toronto City Airport to four seasons hotel. Book online",
        "Airport car service to  Four Seasons Hotel from Billy Bishop Toronto City Airport",
      ],
      rating: "8.7",
      title: "Four Seasons Hotel",
      location: "Toronto",
      distance: "13.8 km from airport",
      map: "https://maps.google.com/maps?q=FOUR%20SEASONS%20HOTEL%20TORONTO&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service in toronto will take you to and from Billy Bishop Toronto City Airport",
        "Search, compare and book Billy Bishop Toronto City Airport  transfers with a chauffeur from Fairmont Royal York",
        "book your private car service from Billy Bishop Toronto City Airport to Fairmont Royal York Hotel Toronto",
      ],
      rating: "8.5",
      title: "Fairmont Royal York",
      location: "Toronto",
      distance: "2.3 km from airport",
      map: "https://maps.google.com/maps?q=Fairmont%20Royal%20York&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from  Billy Bishop Toronto City Airport To The Ritz-Carlton hotel",
        "Book your Luxury Chauffeur from The Ritz-Carlton hotel to Billy Bishop Toronto City Airport",
        "Chauffeur service at Billy Bishop Toronto City Airport to The Ritz-Carlton hotel",
      ],
      rating: "8.6",
      title: "The Ritz-Carlton",
      location: "Toronto",
      distance: "2.7 km from airport",
      map: "https://maps.google.com/maps?q=The%20Ritz-Carlton,%20Toronto&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Billy Bishop Toronto City Airport to Hotel X Toronto by Library Hotel Collection",
        "Find cheep and luxury cars at  Billy Bishop Toronto City Airport to Hotel X Toronto by Library Hotel Collection",
        "All inclusive budget car services from  Billy Bishop Toronto City Airport to Hotel X Toronto by Library Hotel Collection",
      ],
      rating: "8.1",
      title: "Hotel X Toronto",
      location: "Toronto",
      distance: "1.4 km from airport",
      map: "https://maps.google.com/maps?q=Hotel%20X%20Toronto%20by%20Library%20Hotel%20Collection&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Toronto, Ontario",
      duration: "11 min",
      distance: "3.4 km",
      price: "211.72",
    },
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Markham, Ontario",
      duration: "30 min",
      distance: "32.1 km",
      price: "211.72",
    },
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Mississauga, Ontario",
      duration: "27 min",
      distance: "24.8 km",
      price: "211.72",
    },
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Niagara Falls, Ontario",
      duration: "1 hr 12 min",
      distance: "126 km",
      price: "211.72",
    },
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Waterloo, Ontario",
      duration: "1 hr 11 min",
      distance: "111 km",
      price: "211.72",
    },
    {
      start: "Billy Bishop Toronto City Airport",
      end: "Hamilton, Ontario",
      duration: "44 min",
      distance: "65.3 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Book Online. A Chauffeur Service at Toronto City Airport.</title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service To/from Toronto City Airport. Best Price Guaranteed.
          "
        />
        <meta
          name="keywords"
          content="Personal Chauffeur Service, Chauffeur Service at billy Bishop Toronto, Airport Chauffeur Service, Chauffeur at Toronto Airport, Chauffeur Services at Toronto, Chauffeur Service from Toronto, Chauffeur Service to Toronto Airport, Chauffeur to Toronto Airport, Chauffeur from Toronto city  Airport, Airport Chauffeur, Chauffeur in Toronto city Airport, Airport limo Billy Bishop Airport"
        />{" "}
        <link
          rel="canonical"
          href="/airport/billy-bishop-toronto-city-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default BillyBishop;
