import React, { useState, useEffect } from "react";

function Toast(props) {
  const [message, setMessage] = useState({ s: "", t: "" });

  useEffect(() => {
    if (props.message.t) {
      setMessage({ s: props.message.s, t: props.message.t });
      setTimeout(() => setMessage({ s: "", t: "" }), 4000);
    } else {
      setMessage({ s: "", t: "" });
    }
  }, [props.message]);

  return (
    message.t && (
      <div className="fixed top-0 right-0 p-5 ">
        <div
          onClick={() => setMessage({ s: "", t: "" })}
          style={
            message.s === "success"
              ? { color: "#fff", backgroundColor: "#40C69A" }
              : { color: "#fff", backgroundColor: "#fd4326" }
          }
          className="flex items-center p-5 gap-3 rounded-sm text-sm "
        >
          <i className="ri-close-line text-base"></i>
          <div>{message.t}</div>
        </div>
      </div>
    )
  );
}

export default Toast;
