import React from "react";
import { Link } from "react-router-dom";

const ServiceHero = (props) => {
  return (
    <div className="px-3 py-12 sm:py-16  bg-sec-100">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-10">
          <div className="place-self-center text-center md:text-left">
            <h3>
              <Link
                to="/home"
                className="text-sec-500 hover:text-pri-500 font-semibold"
              >
                Home
              </Link>{" "}
              / Services
            </h3>
            <h2>{props.hero.title}</h2>
            <div className="-mt-2 mb-5 text-base max-w-xl ">
              {props.hero.description}
            </div>
            <Link
              to="/book-now-a-chauffeur-service"
              className="btn1   text-base  cursor-pointer"
            >
              Hire A Chauffeur Online
            </Link>
          </div>
          <div className="hidden md:grid grid-cols-2 gap-5 ">
            <div className="box--shadow text-center text-white bg-sec-500 p-5 rounded-md">
              <img
                className="mx-auto mb-4 h-14 w-14"
                src={props.hero.card1.icon}
                alt={props.hero.card1.alt}
              />
              <h4 className="text-white">{props.hero.card1.title}</h4>
              <div className="hidden lg:block">
                {props.hero.card1.description}
              </div>
            </div>
            <div
              className="box--shadow text-center bg-white p-5 rounded-md"
              style={{ marginTop: "-40px", marginBottom: "40px" }}
            >
              <img
                className="mx-auto mb-4 h-14 w-14"
                src={props.hero.card2.icon}
                alt={props.hero.card2.alt}
              />
              <h4>{props.hero.card2.title}</h4>
              <div className="hidden lg:block">
                {props.hero.card2.description}
              </div>
            </div>
            <div className="box--shadow text-center bg-white p-5 rounded-md">
              <img
                className="mx-auto mb-4 h-14 w-14"
                src={props.hero.card3.icon}
                alt={props.hero.card3.alt}
              />
              <h4>{props.hero.card3.title}</h4>
              <div className="hidden lg:block">
                {props.hero.card3.description}
              </div>
            </div>
            <div
              className="box--shadow text-center text-white bg-sec-500 p-5 rounded-md"
              style={{ marginTop: "-40px", marginBottom: "40px" }}
            >
              <img
                className="mx-auto mb-4 h-14 w-14"
                src={props.hero.card4.icon}
                alt={props.hero.card4.alt}
              />
              <h4 className="text-white">{props.hero.card4.title}</h4>
              <div className="hidden lg:block">
                {props.hero.card4.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHero;
