import React from "react";

import Banner2 from "../../components/web/banners/Banner3";
import ServiceHero from "../../components/web/services/ServiceHero";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
import f1 from "../../assets/images/services/charter-bus-tour-for-large-group-of-people.jpg";
import f2 from "../../assets/images/services/long-distance-and-long-journey-charter-bus-tours.jpg";

const CharterBusTours = () => {
  const hero = {
    title: "Charter Bus Tours",
    description:
      "Set out on a journey of comfort and style with our Toronto charter buses, Sprinter vans, passenger vans, cars, and SUVs. As the premier tour company, we offer safe, affordable, and easy-to-book options for charter bus tours. Explore Toronto's wonders like never before - reserve your adventure today!",
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Charter Bus Tours",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Charter Bus Tours",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "achauffeur offers charter bus services for large group of people",
    title: "Large Group Tours    ",
    description:
      "Nothing is better than spending time with those you love, especially when there are a lot of them! If you want to organize a large group tour to visit, Charter Bus Tours create the perfect outing for everyone. For large and small groups, our Charter Bus Tours  provide a secure means of transportation that is safe and affordable.We have SUVs, vans, and mini or large party buses that can transport everyone in your group.      ",
  };
  const feature2 = {
    image: f2,
    alt: "Do you like long journey? achauffeur has long-distance and long-journey charter bus tours.",
    title: "Long Distance & Long Journey    ",
    description:
      "Achauffeur is proud to provide high quality chartered bus transportation for long-distance and long journeys, camps & retreats, special events, private sightseeing tours, business occasions, school trips and more. We have luxuriously outfitted cars with excellent maintenance, including Executive Van, Sprinter Van, Limo Bus, and 31 Pax MiniCoach. To ensure your smoothest journey, make a reservation in advance using our website. Once you've made a reservation, you'll get an email confirmation containing your driver's information.  ",
  };
  return (
    <>
      <Helmet>
        <title>
          Book Your Private Charter Bus Tours Today | AChauffeur.com
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service To or From Hamilton Airport. Best Price Guaranteed.
  "
        />
        <meta
          name="keywords"
          content="Charter bus tours, Charter bus tours near me, Bus charter tours, Bus charter trips, Executive charter bus, Charter bus round trip, Vip charter bus, Charter bus tour packages, Charter bus tours for seniors, Toronto charter bus company, Grand tours bus "
        />
        <link rel="canonical" href="/services/charter-bus-tour" />
      </Helmet>
      <ServiceHero hero={hero} />
      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default CharterBusTours;
