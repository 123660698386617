import React from "react";
import canada from "../../assets/images/canada.png";
import profile from "../../assets/images/testimonial.jpg";

import quote from "../../assets/icons/quote-left.png";

const Testimonials = () => {
  return (
    <div className="testimonials px-3 py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="container">
        <div className="md:grid md:grid-cols-3 gap-10">
          <div className="col-span-2">
            <div className="p-5 bg-sec-200">
              <img src={quote} alt="chauffer" />
              <div className="text-base">
                On a recommendation from a friend we booked Achauffeur for a
                trip to Niagara Falls with a few planned stops on the way.
                Everything went exactly as planned. The limousine was clean and
                comfortable, the chauffeur very sociable and an excellent safe
                driver. We have no hesitation in recommending Achauffeur as an
                excellent and reliable company to do business with.
              </div>
            </div>
            <div className="flex items-center gap-5 mt-5">
              <div className="w-28 h-28 overflow-hidden rounded-full relative">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={profile}
                  alt="chauffer"
                />
              </div>
              <div>
                <h4>Vanitha Narendiran</h4>
                <div>Transit secretary of Durham Region Transit</div>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <img src={canada} alt="chauffer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
