import React from "react";
import car from "../../../assets/images/fleets/limo-bus-for-vacation-trip.png";
import { Link } from "react-router-dom";

const LimoBus = () => {
  return (
    <div className="px-3 py-12 sm:py-16">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-start">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Hire limo for your vacation trip at achauffeur"
            />
          </div>
          <div>
            <h3>Bus</h3>
            <h4 className="text-pri-500">Limo Bus (or similar)</h4>
            <div className="text-base mb-3">
              The Limo Bus will provide chauffeured transportation for up to 22
              passengers. Perfect for any parties or events without compromising
              luxury, this bus will make your journey even more memorable
            </div>
            <div className="grid sm:grid-cols-2 gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>
                <div>Up to 22 passengers, 20 comfortable</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>No luggages</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-tv-2-line  "></i>
                </div>
                <div> 42" TV</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div> 4 bars</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>Lavatory</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-microsoft-line  "></i>
                </div>
                <div> Privacy blinds</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-lightbulb-flash-line  "></i>
                </div>
                <div> Fiber optic lighting</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimoBus;
