import React, { useEffect, useRef } from "react";

const BookingForm = (props) => {
  const var1 = useRef();
  const func = () => {
    const script = document.createElement("script");
    script.src = "https://software.limo/iframeResizer/iframeResizer.min.js";
    script.async = true;
    var1.current.appendChild(script);
    script.onload = () => {
      const resizeScript = document.createElement("script");
      resizeScript.innerHTML = `iFrameResize({heightCalculationMethod:'taggedElement',autoResize:true},document.getElementById("iframe-E69Tp7PfvUSo8kiAKaqj9tLHoogHFUiA"))`;
      var1.current.appendChild(resizeScript);
    };
  };
  useEffect(() => {
    func();
  }, []);

  return (
    <>
      <iframe
        title="booking form"
        ref={var1}
        id="iframe-E69Tp7PfvUSo8kiAKaqj9tLHoogHFUiA"
        src="https://software.limo/quote-request-form/XaakjPU-fLJqLbCIz_Fumq793in-YauV?iframe=1"
        style={{
          width: "100%",
          minHeight: "700px",
          border: "0",
          marginBottom: "0px",
        }}
      ></iframe>
    </>
  );
};

export default BookingForm;
