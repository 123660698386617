import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";

// stay images
import one1 from "../../assets/images/hotels/airports/niagaraFalls/holiday-inn-express-and-suites-chauffeur-service-niagara-falls-international-airport.jfif";
import one2 from "../../assets/images/hotels/airports/niagaraFalls/holiday-inn-express-and-suites-private-chauffeur-service-niagara-falls-international-airport.jfif";
import one3 from "../../assets/images/hotels/airports/niagaraFalls/holiday-inn-express-and-suites-car-service-niagara-falls-international-airport.jfif";
import two1 from "../../assets/images/hotels/airports/niagaraFalls/chauffeur-service-in-niagara-falls-international-airport-to-best-western-summit-inn.jpg";
import two2 from "../../assets/images/hotels/airports/niagaraFalls/achauffeur-airport-transfer-to-best-western-summit-inn.jpg";
import two3 from "../../assets/images/hotels/airports/niagaraFalls/car-service-from-niagara-falls-international-airport-to-best-western-summit-inn.jpg";
import three1 from "../../assets/images/hotels/airports/niagaraFalls/executive-chauffeur-service-from-niagara-falls-international-airport-to-hampton-inn-niagara-falls.jfif";
import three2 from "../../assets/images/hotels/airports/niagaraFalls/hourly-chauffeur-service-at-niagara-falls-international-airport-to-hampton-inn-niagara-falls.jfif";
import three3 from "../../assets/images/hotels/airports/niagaraFalls/chauffeur-service-near-niagara-falls-international-airport-to-hampton-inn-niagara-falls.jfif";
import four1 from "../../assets/images/hotels/airports/niagaraFalls/limo-service-at-niagara-falls-international-airport-to-comfort-inn-and-suites-niagara-falls.webp";
import four2 from "../../assets/images/hotels/airports/niagaraFalls/car-hire-at-niagara-falls-international-airport-to-comfort-inn-and-suites-niagara-falls.webp";
import four3 from "../../assets/images/hotels/airports/niagaraFalls/budget-car-hire-niagara-falls-international-airport-to-comfort-inn-and-suites-niagara-falls.webp";
import { Helmet } from "react-helmet-async";

const NiagaraFallsAir = () => {
  const hero = {
    title: "Niagara Falls International Airport - NY",
    description:
      "Reserve a ride with AChauffeur in advance to have a peaceful journey whether you are traveling for business purposes or leisure. Our chauffeur service provides private, personal, limo, executive car services, and so on. ",
  };
  const desc = {
    title: "Niagara Falls International Airport",
    description:
      "Niagara Falls International Airport is located 4 mi (6.4 km) east of downtown Niagara Falls, in the Town of Niagara in Niagara County, New York, United States.[1] Owned and operated by the Niagara Frontier Transportation Authority, the airport is a joint civil-military airfield and shares its runways with the Niagara Falls Air Reserve Station. A new terminal building opened in 2009. It is notable for serving vastly more Canadian passengers from over the nearby border than Americans.",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Niagara Falls International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Niagara Falls International Airport to Holiday Inn Express & Suites. Book with affordable price",
        "Private chauffeur service from Niagara Falls International Airport to Holiday Inn Express & Suites. Book online",
        "Airport car service to Holiday Inn Express & Suites from Niagara Falls International Airport",
      ],
      rating: "8.5",
      title: "Holiday Inn Express",
      location: "Niagara Falls",
      distance: "0.3 mile from airport",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Express%20and%20Suites%20Niagara%20Falls,%20an%20IHG%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Best Western Summit Inn will take you to and from Niagara Falls International Airport",
        "Search, compare and book Niagara Falls International Airport transfers with a chauffeur from Best Western Summit Inn",
        "book your private car service from Niagara Falls International Airport  to Best Western Summit Inn",
      ],
      rating: "7.5",
      title: "Best Western Summit Inn",
      location: "9500 Niagara Falls Blvd",
      distance: "0.7 mile from airport",
      map: "https://maps.google.com/maps?q=Best%20Western%20Summit%20Inn,%209500%20Niagara%20Falls%20Blvd,%20Niagara%20Falls&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from Niagara Falls International Airport To Hampton Inn Niagara Falls",
        "Book your Luxury Chauffeur from Hampton Inn Niagara Falls to Niagara Falls International Airport",
        "Chauffeur service at Niagara Falls International Airport  to Hampton Inn Niagara Falls",
      ],
      rating: "7.5",
      title: "Hampton Inn Niagara Falls",
      location: " 6501 Niagara Falls Blvd",
      distance: "2.7 miles from airport",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20Niagara%20Falls/Blvd,%206501%20Niagara%20Falls%20Blvd,%20Niagara%20Falls&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Niagara Falls International Airport to Comfort Inn & Suites Niagara Falls",
        "Find cheep and luxury cars at Niagara Falls International Airport to  Comfort Inn & Suites Niagara Falls",
        "All inclusive budget car services from Niagara Falls International Airport to Comfort Inn & Suites Niagara Falls",
      ],
      rating: "6.5",
      title: "Comfort Inn & Suites",
      location: "6115 Niagara Falls Blvd, Niagara Falls",
      distance: "3.0 miles from airport",
      map: "https://maps.google.com/maps?q=Comfort%20Inn%20and%20Suites%20Niagara%20Falls%20Blvd%20USA,%20&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const staysTitle =
    "Stay at top unique properties near Niagara Falls , ON Airport.";
  const mostBookeds = [
    {
      start: "Niagara Falls International Airport",
      end: "Niagara Falls, Ontario ",
      duration: "22 min",
      distance: "8.4 miles",
      price: "211.72",
    },
    {
      start: "Niagara Falls International Airport",
      end: "Toronto, Ontario",
      duration: " 1h 42 min",
      distance: "86.4 miles",
      price: "211.72",
    },
    {
      start: "Niagara Falls International Airport",
      end: "Niagara-on-the-Lake, Ontario",
      duration: "34 min",
      distance: "19.4 miles",
      price: "211.72",
    },
    {
      start: "Niagara Falls International Airport",
      end: "St. Catharines, Ontario",
      duration: "30 min",
      distance: "20.2 miles",
      price: "211.72",
    },
    {
      start: "Niagara Falls International Airport",
      end: " Mississauga, Ontario",
      duration: "1 hr 21 min",
      distance: "73.6 miles",
      price: "211.72",
    },
    {
      start: "Niagara Falls International Airport",
      end: "Hamilton, Ontario",
      duration: "1 hr 4 min",
      distance: "60.8 miles",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book Online. A Chauffeur service at Niagara Falls Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service to/from Niagara Falls Airport. Best Price Guaranteed.
      "
        />
        <meta
          name="keywords"
          content="airport chauffeur service,  Niagara chauffeur service, airport chauffeur, private chauffeur, chauffeur service, chauffeur service near me, chauffeur driver, personal chauffeur, executive car service, chauffeur for hire, luxury chauffeur service
          private chauffeur service, Airport Car service, Airport Limo service, Airport Chauffeur Niagara falls,  Niagara falls Airport Chauffeur, Airport Limo, Airport Limousine"
        />
        <link
          rel="canonical"
          href="/airport/niagara-falls-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default NiagaraFallsAir;
