import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/oshawa/towneplace-suites-by-marriott-oshawa-chauffeur-service-oshawa-limo-services-car-service-near-oshawa.webp";
import one2 from "../../assets/images/hotels/cities/oshawa/towneplace-suites-by-marriott-oshawa-private-chauffeur-service-oshawa-car-service-from-oshawa.webp";
import one3 from "../../assets/images/hotels/cities/oshawa/airport-transfer-services-from-towneplace-suites-by-marriott-oshawa-best-price-car-services-in-oshawa.webp";
import two1 from "../../assets/images/hotels/cities/oshawa/limousine-services-from-holiday-inn-express-and-suites-oshawa-car-service-24-hours-in-oshawa-best-car-services-in-oshawa.jfif";
import two2 from "../../assets/images/hotels/cities/oshawa/chauffeur-service-in-oshawa-to-holiday-inn-express-and-suites-oshawa-at-affordable-price-limo-car-service-downtown-oshawa.jfif";
import two3 from "../../assets/images/hotels/cities/oshawa/hourly-chauffeur-service-from-holiday-inn-express-and-suites-oshawa-affordable-car-services-near-le-germain-hotel.jfif";
import three1 from "../../assets/images/hotels/cities/oshawa/car-service-from-oshawa-to-best-western-plus-durham-hotel-and-conference-centre-all-the-way-limo-services-oshawa.jpg";
import three2 from "../../assets/images/hotels/cities/oshawa/executive-chauffeur-service-near-best-western-plus-durham-hotel-and-conference-centre-comfortable-chauffeur-services-in-oshawa.jpg";
import three3 from "../../assets/images/hotels/cities/oshawa/budget-car-hire-oshawa-to-best-western-plus-durham-hotel-and-conference-centre-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/oshawa/car-hire-near-best-western-plus-bowmanville-business-class-limo-services.jpg";
import four2 from "../../assets/images/hotels/cities/oshawa/all-inclusive-limo-services-near-best-western-plus-bowmanville-limo-services-online-bookings.jpg";
import four3 from "../../assets/images/hotels/cities/oshawa/oshawa-limo-services-to-best-western-plus-bowmanville-car-service-with-driver.webp";

const Oshawa = () => {
  const hero = {
    title: "HIRE A CHAUFFEUR IN OSHAWA",
    description:
      "Hire a chauffeur with a car, SUV, van, or bus in Oshawa for smooth airport transfers and private rides. Perfect for business engagements and leisure travel.      ",
  };

  const desc = {
    title: "Oshawa",
    description:
      <span>For reliable ground transportation in <b>Oshawa</b>, <b>A Chauffeur by Get Limo Inc</b>. operates 24/7, offering sedans, SUVs, vans, and buses at fixed prices. Whether you are visiting local attractions or heading to <b>Toronto Pearson International Airport (YYZ)</b>, our <b>luxury car service in Oshawa</b> and <b>chauffeur service in Oshawa</b> ensure a comfortable ride.</span>,
    website:
      "https://www.oshawa.ca/en/parks-recreation-and-culture/things-to-do.aspx",
    description2:
      <span>We specialize in <b>corporate transportation in Oshawa</b>, <b>executive car service</b>, and <b>private car hire</b>. Additionally, our <b>long-distance rides from Oshawa</b> cater to those needing to travel further, and we offer a <b>Sprinter van for hire in Oshawa</b> for family trips.</span>,
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from oshawa to The TownePlace Suites by Marriott Oshawa best car services from achauffeur",
        "Private chauffeur service from oshawa city to TownePlace Suites by Marriott Oshawa. luxury car service near oshawa",
        "Find airport transfer services in oshawa to TownePlace Suites by Marriott Oshawa at an affordable price and best services",
      ],
      title: "TownePlace Suites by Marriott Oshawa",
      location: "Oshawa, Ontario",
      distance: "5.4 km from center",
      map: "https://maps.google.com/maps?q=TownePlace%20Suites%20by%20Marriott%20Oshawa&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Holiday Inn Express & Suites Oshawa  to oshawa city. Best car services from achauffeur",
        "Safe and secure chauffeur services in oshawa to Holiday Inn Express & Suites Oshawa . All-inclusive car services downtown oshawa",
        "Hourly chauffeur service from achauffeur to Holiday Inn Express & Suites Oshawa  from oshawa canada at affordable price rates",
      ],
      title: "Holiday Inn Express & Suites Oshawa Downtown",
      location: "Oshawa, Ontario",
      distance: "450 m from center",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Express%20and%20Suites%20Oshawa%20Downtown&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from oshawa to Best Western Plus Durham Hotel & Conference Centre. Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Best Western Plus Durham Hotel & Conference Centre from oshawa city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in oshawa. Hire budget cars and limos to Best Western Plus Durham Hotel & Conference Centre. Luxury service available",
      ],
      title: "Best Western Plus Durham Hotel & Conference Centre",
      location: "Oshawa, Ontario",
      distance: "3.7 km from center",
      map: "https://maps.google.com/maps?q=Best%20Western%20Plus%20Durham%20Hotel%20and%20Conference%20Centre&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in oshawa to Best Western Plus Bowmanville. Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from oshawa city to Best Western Plus Bowmanville",
        "Do you need a car with driver? Achauffeur offers limo services with driver in oshawa toBest Western Plus Bowmanville",
      ],
      title: "Best Western Plus Bowmanville",
      location: "Oshawa, Ontario",
      distance: "18.2 km from center",
      map: "https://maps.google.com/maps?q=Best%20Western%20Plus%20Bowmanville&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Oshawa",
      end: "Toronto, Ontario",
      duration: "43 min",
      distance: "61 Km",
      price: "211.72",
    },
    {
      start: "Oshawa",
      end: "Mississauga, Ontario",
      duration: "56 min",
      distance: "82.1 Km",
      price: "211.72",
    },
    {
      start: "Oshawa",
      end: "Markham, Ontario",
      duration: "35 min",
      distance: "52.6 Km",
      price: "211.72",
    },
    {
      start: "Oshawa",
      end: "Cobourg, Ontario",
      duration: "40 min",
      distance: "60.9 Km",
      price: "211.72",
    },
    {
      start: "Oshawa",
      end: "Kingston, Ontario",
      duration: "2h 9 min",
      distance: "206 Km",
      price: "211.72",
    },
    {
      start: "Oshawa",
      end: "Niagara Falls, Ontario",
      duration: "1h 52 min",
      distance: "187 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Oshawa Chauffeur Service | Van Hire & Airport Transfers</title>
        <meta
          name="description"
          content="Chauffeur Services In Oshawa For Airport Transfers, Private Car Hire, Sprinter Van Hire, And Long-Distance Rides. Professional, Luxury Travel 24/7."
        />
        <meta
          name="keywords"
          content="Oshawa Chauffeur Service, Oshawa Airport Transfer, Luxury Car Service Oshawa, Sprinter Van For Hire Oshawa, Executive Car Service Oshawa, Chauffeur Service In Oshawa, Private Car Hire Oshawa, Oshawa Long-Distance Rides, VIP Transportation Oshawa, Intercity Transportation Oshawa"
        />
        <link rel="canonical" href="/city/chauffeur-service-oshawa" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Oshawa;
