import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner2";

// stay images
import one1 from "../../assets/images/hotels/airports/ottawaMc/hilton-garden-inn-ottawa-airport-chauffeur-service-ottawa-mcdonald-cartier-international-airport.jpg";
import one2 from "../../assets/images/hotels/airports/ottawaMc/hilton-garden-inn-ottawa-airport-private-chauffeur-service-ottawa-mcdonald-cartier-international-airport.webp";
import one3 from "../../assets/images/hotels/airports/ottawaMc/hilton-garden-inn-ottawa-airport-car-service-ottawa-mcdonald-cartier-international-airport.webp";
import two1 from "../../assets/images/hotels/airports/ottawaMc/chauffeur-service-in-ottawa-mcdonald-cartier-international-airport-to-hampton-inn-by-hilton-ottawa-airport.webp";
import two2 from "../../assets/images/hotels/airports/ottawaMc/achauffeur-airport-transfer-to-hampton-inn-by-hilton-ottawa-airport.webp";
import two3 from "../../assets/images/hotels/airports/ottawaMc/car-service-from-ottawa-mcdonald-cartier-international-airport-to-hampton-inn-by-hilton-ottawa-airport.jpg";
import three1 from "../../assets/images/hotels/airports/ottawaMc/executive-chauffeur-service-from-ottawa-mcdonald-cartier-international-airport-to-fairfield-innand-suites-by-marriott-ottawa-airport.webp";
import three2 from "../../assets/images/hotels/airports/ottawaMc/hourly-chauffeur-service-at-ottawa-mcdonald-cartier-international-airport-to-fairfield-innand-suites-by-marriott-ottawa-airport.webp";
import three3 from "../../assets/images/hotels/airports/ottawaMc/chauffeur-service-near-ottawa-mcdonald-cartier-international-airport-to-fairfield-innand-suites-by-marriott-ottawa-airport.jpg";
import four1 from "../../assets/images/hotels/airports/ottawaMc/limo-service-at-ottawa-mcdonald-cartier-international-airport-to-residence-inn-by-marriott-ottawa-airport.webp";
import four2 from "../../assets/images/hotels/airports/ottawaMc/car-hire-at-ottawa-mcdonald-cartier-international-airport-to-residence-inn-by-marriott-ottawa-airport.webp";
import four3 from "../../assets/images/hotels/airports/ottawaMc/budget-car-hire-ottawa-mcdonald-cartier-international-airport-to-residence-inn-by-marriott-ottawa-airport.webp";
import { Helmet } from "react-helmet-async";

const OttawaMc = () => {
  const hero = {
    title: "Ottawa Mcdonald-cartier International Airport (YOW)",
    description:
      "Enjoy a pleasant airport shuttle from Ottawa Mcdonald-cartier International Airport to the front door of your accommodation. Our chauffeur service provides Luxury, Private, and Executive limo service and so on...",
  };
  const desc = {
    title: "Ottawa Mcdonald-cartier International Airport",
    description:
      "Ottawa Mcdonald-cartier International Airport is a leader in      providing quality, safe, secure, sustainable and affordable air      transportation services to the airport’s customers and      communities and be a driver of economic growth within Canada’s      Capital Region.",
    website: "https://yow.ca/en",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Ottawa Mcdonald-cartier International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Ottawa Mcdonald-cartier International Airport to Hilton Garden Inn Ottawa Airport. Book with affordable price",
        "Private chauffeur service from Ottawa Mcdonald-cartier International Airport to Hilton Garden Inn Ottawa Airport. Book online",
        "Airport car service to  Hilton Garden Inn Ottawa Airport from Ottawa Mcdonald-cartier International Airport",
      ],
      rating: "8.5",
      title: "Hilton Garden Inn",
      location: "Ottawa Airport",
      distance: "1.4 km from airport",
      map: "https://maps.google.com/maps?q=Hilton%20Garden%20Inn%20Ottawa%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Hampton Inn by Hilton Ottawa Airport will take you to and from Ottawa Mcdonald-cartier International Airport",
        "Search, compare and book Ottawa Mcdonald-cartier International Airport transfers with a chauffeur from Hampton Inn by Hilton Ottawa Airport",
        "book your private car service from Ottawa Mcdonald-cartier International Airport  to Hampton Inn by Hilton Ottawa Airport",
      ],
      rating: "7.5",
      title: "Hampton Inn",
      location: "Hilton Ottawa Airport",
      distance: "3.7 km from airport",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20by%20Hilton%20Ottawa%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from Ottawa Mcdonald-cartier International Airport To Fairfield Inn & Suites by Marriott Ottawa Airport",
        "Book your Luxury Chauffeur from Fairfield Inn & Suites by Marriott Ottawa Airport to Ottawa Mcdonald-cartier International Airport",
        "Chauffeur service at Ottawa Mcdonald-cartier International Airport  to Fairfield Inn & Suites by Marriott Ottawa Airport",
      ],
      rating: "7.5",
      title: "Fairfield Inn & Suites",
      location: "Marriott Ottawa Airport",
      distance: "1.7 km from airport",
      map: "https://maps.google.com/maps?q=Fairfield%20Inn%20and%20Suites%20by%20Marriott%20Ottawa%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Ottawa Mcdonald-cartier International Airport to Residence Inn by Marriott Ottawa Airport",
        "Find cheep and luxury cars at Ottawa Mcdonald-cartier International Airport to Residence Inn by Marriott Ottawa Airport",
        "All inclusive budget car services from Ottawa Mcdonald-cartier International Airport to Residence Inn by Marriott Ottawa Airport",
      ],
      rating: "6.5",
      title: "Residence Inn",
      location: "Marriott Ottawa Airport",
      distance: "5.9 km from airport",
      map: "https://maps.google.com/maps?q=Residence%20Inn%20by%20Marriott%20Ottawa%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const staysTitle = "Stay at top unique properties near Ottawa Airport.";
  const mostBookeds = [
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Toronto, Ontario ",
      duration: "4 hr 8 min",
      distance: "438 km",
      price: "211.72",
    },
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Kingston, Ontario",
      duration: "1 hr 52 min",
      distance: "183 km",
      price: "211.72",
    },
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Montreal, Quebec",
      duration: "2 hr 6 min",
      distance: "196 km",
      price: "211.72",
    },
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Thousand Islands",
      duration: "1 hr 27 min",
      distance: "141 km",
      price: "211.72",
    },
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Algonquin Provincial Park",
      duration: "2 hr 46 min",
      distance: "245 km",
      price: "211.72",
    },
    {
      start: "Ottawa Mcdonald-cartier International Airport ",
      end: "Peterborough, Ontario",
      duration: "3 hr 6 min",
      distance: "274 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Book Online. Private Chauffeur Service at Ottawa Airport.</title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24hrs, Pre-Book Online. Reliable and Efficient Chauffeur Service to/from Ottawa Airport. Best Price Guaranteed.
      "
        />
        <meta
          name="keywords"
          content="airport chauffeur service, Ottawa chauffeur service, airport chauffeur, private chauffeur, chauffeur service, chauffeur service near me, chauffeur driver, personal chauffeur, executive car service, chauffeur for hire, luxury chauffeur service
          private chauffeur service, Airport Car service, Airport Limo service, Airport Chauffeur Ottawa, Ottawa Airport Chauffeur
          "
        />
        <link
          rel="canonical"
          href="/airport/ottawa-mcdonald-cartier-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default OttawaMc;
