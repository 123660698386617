import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/kitchener/doubletree-by-hilton-kitchener-chauffeur-service-kitchener-limo-services-car-service-near-kitchener.jpg";
import one2 from "../../assets/images/hotels/cities/kitchener/doubletree-by-hilton-kitchener-private-chauffeur-service-kitchener-car-service-from-kitchener.webp";
import one3 from "../../assets/images/hotels/cities/kitchener/airport-transfer-services-from-doubletree-by-hilton-kitchener-best-price-car-services-in-kitchener.webp";
import two1 from "../../assets/images/hotels/cities/kitchener/limousine-services-from-homewood-suites-by-hilton-waterloo-car-service-24-hours-in-kitchener-best-car-services-in-kitchener.webp";
import two2 from "../../assets/images/hotels/cities/kitchener/chauffeur-service-in-kitchener-to-homewood-suites-by-hilton-waterloo-at-affordable-price-limo-car-service-downtown-kitchener.webp";
import two3 from "../../assets/images/hotels/cities/kitchener/hourly-chauffeur-service-from-homewood-suites-by-hilton-waterloo-affordable-car-services-near-kitchener.webp";
import three1 from "../../assets/images/hotels/cities/kitchener/car-service-from-kitchener-to-delta-hotels-by-marriott-waterloo-all-the-way-limo-services-kitchener.webp";
import three2 from "../../assets/images/hotels/cities/kitchener/executive-chauffeur-service-near-delta-hotels-by-marriott-waterloo-comfortable-chauffeur-services-in-kitchener.webp";
import three3 from "../../assets/images/hotels/cities/kitchener/budget-car-hire-kitchener-to-delta-hotels-by-marriott-waterloo-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/kitchener/car-hire-near-staybridge-suites-waterloo-business-class-limo-services.jfif";
import four2 from "../../assets/images/hotels/cities/kitchener/all-inclusive-limo-services-near-staybridge-suites-waterloo-limo-services-online-bookings.jfif";
import four3 from "../../assets/images/hotels/cities/kitchener/kitchener-limo-services-to-staybridge-suites-waterloo-car-service-with-driver.jfif";

const Kitchener = () => {
  const hero = {
    title: "HIRE A CHAUFFEUR IN KITCHENER",
    description:
      "In Kitchener, hire a chauffeur with a car, SUV, van, or bus for hassle-free airport transfers and private rides. Tailored for business and leisure needs.",
  };

  const desc = {
    title: "Kitchener",
    description:
      <span>Need top-notch ground transportation in <b>Kitchener</b>? <b>A Chauffeur by Get Limo Inc</b>. operates 24/7, providing sedans, SUVs, vans, and buses at fixed prices. Whether you are attending the Kitchener-Waterloo Oktoberfest or traveling to Region of Waterloo International Airport (YKF), our <b>luxury car service in Kitchener</b> and <b>chauffeur service in Kitchener</b> deliver exceptional comfort.</span>,
    website:
      "https://www.kitchener.ca/en/arts-culture-and-events/places-to-visit.aspx",
    description2:
      <span>For business or pleasure, our <b>corporate transportation in Kitchener</b>, <b>executive car service</b>, and <b>private car hire in Kitchener</b> ensure convenience. We also offer <b>long-distance rides from Kitchener</b> for those needing to travel further, along with our <b>Sprinter van for hire in Kitchener</b> for larger groups. Families can benefit from our <b>ride with car seats in Kitchener</b> for a hassle-free experience.</span>,
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from kitchener to DoubleTree by Hilton Kitchener best car services from achauffeur",
        "Private chauffeur service from kitchener city to DoubleTree by Hilton Kitchener . luxury car service near kitchener",
        "Find airport transfer services in kitchener to DoubleTree by Hilton Kitchener  at an affordable price and best services",
      ],
      title: "DoubleTree by Hilton Kitchener",
      location: "Kitchener, Ontario",
      distance: "6.0 km from center",
      map: "https://maps.google.com/maps?q=DoubleTree%20by%20Hilton%20Kitchener&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Homewood Suites by Hilton Waterloo   to kitchener city. Best car services from achauffeur",
        "Safe and secure chauffeur services in kitchener to Homewood Suites by Hilton Waterloo  . All-inclusive car services downtown kitchener",
        "Hourly chauffeur service from achauffeur to Homewood Suites by Hilton Waterloo   from kitchener canada at affordable price rates",
      ],
      title: "Homewood Suites by Hilton Waterloo",
      location: "Kitchener, Ontario",
      distance: "12.2 km from center",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20Waterloo&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from kitchener to Delta Hotels by Marriott Waterloo . Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Delta Hotels by Marriott Waterloo  from kitchener city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in kitchener. Hire budget cars and limos to Delta Hotels by Marriott Waterloo . Luxury service available",
      ],
      title: "Delta Hotels by Marriott Waterloo",
      location: "Kitchener, Ontario",
      distance: "3.4 km from center",
      map: "https://maps.google.com/maps?q=Delta%20Hotels%20by%20Marriott%20Waterloo&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in kitchener to Staybridge Suites Waterloo . Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from kitchener city to Staybridge Suites Waterloo ",
        "Do you need a car with driver? Achauffeur offers limo services with driver in kitchener to Staybridge Suites Waterloo ",
      ],

      title: "Staybridge Suites Waterloo",
      location: "Kitchener, Ontario",
      distance: "12.2 km from center",
      map: "      https://maps.google.com/maps?q=Staybridge%20Suites%20Waterloo&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Kitchener",
      end: "Toronto, Ontario",
      duration: "1 hr 20 min",
      distance: "108 Km",
      price: "211.72",
    },
    {
      start: "Kitchener",
      end: "Mississauga, Ontario",
      duration: "1h 00 min",
      distance: "(88.1 Km",
      price: "211.72",
    },
    {
      start: "Kitchener",
      end: "Hamilton, Ontario ",
      duration: "59 min",
      distance: "71.2 Km",
      price: "211.72",
    },
    {
      start: "Kitchener",
      end: "Niagara Falls, Ontario",
      duration: "1h 31 min",
      distance: "139 Km",
      price: "211.72",
    },
    {
      start: "Kitchener",
      end: "Brantford, Ontario",
      duration: "51 min",
      distance: "47.6 Km",
      price: "211.72",
    },
    {
      start: "Kitchener",
      end: "London, Ontario",
      duration: "1h 14 min",
      distance: "23 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Kitchener Chauffeur Service | Van Hire & Airport Transfers</title>
        <meta
          name="description"
          content="Chauffeur Service In Kitchener With Sprinter Van Hire, Airport Transfers, And Long-Distance Rides. Reliable, Luxury Travel Available 24/7."
        />
        <meta
          name="keywords"
          content="Kitchener Chauffeur Service, Kitchener Airport Transfer, Luxury Car Service Kitchener, Sprinter Van For Hire Kitchener, Executive Car Service Kitchener, Chauffeur Service In Kitchener, Private Car Hire Kitchener, Kitchener Long-Distance Rides, VIP Transportation Kitchener, Intercity Transportation Kitchener"
        />
        <link rel="canonical" href="/city/chauffeur-service-kitchener" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Kitchener;
