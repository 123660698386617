import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner2";

// stay properties
import one1 from "../../assets/images/hotels/airports/buffalloNiagara/holiday-inn-express-and-suites-buffalo-airport-chauffeur-service-buffalo-niagara-international-airport.jfif";
import one2 from "../../assets/images/hotels/airports/buffalloNiagara/holiday-inn-express-and-suites-buffalo-airport-private-chauffeur-service-buffalo-niagara-international-airport.jfif";
import one3 from "../../assets/images/hotels/airports/buffalloNiagara/holiday-inn-express-and-suites-buffalo-airport-car-service-buffalo-niagara-international-airport.jfif";
import two1 from "../../assets/images/hotels/airports/buffalloNiagara/chauffeur-service-in-buffalo-niagara-international-airport-to-homewood-suites-by-hilton-buffalo-airport.webp";
import two2 from "../../assets/images/hotels/airports/buffalloNiagara/achauffeur-airport-transfer-to-homewood-suites-by-hilton-buffalo-airport.webp";
import two3 from "../../assets/images/hotels/airports/buffalloNiagara/car-service-from-buffalo-niagara-international-airport-to-homewood-suites-by-hilton-buffalo-airport.webp";
import three1 from "../../assets/images/hotels/airports/buffalloNiagara/executive-chauffeur-service-from-buffalo-niagara-international-airport-to-hampton-inn-and-suites-buffalo-airport.webp";
import three2 from "../../assets/images/hotels/airports/buffalloNiagara/hourly-chauffeur-service-at-buffalo-niagara-international-airport-to-hampton-inn-and-suites-buffalo-airport.jpg";
import three3 from "../../assets/images/hotels/airports/buffalloNiagara/chauffeur-service-near-buffalo-niagara-international-airport-to-hampton-inn-and-suites-buffalo-airport.webp";
import four1 from "../../assets/images/hotels/airports/buffalloNiagara/limo-service-at-buffalo-niagara-international-airport-to-courtyard-by-marriott-buffalo-airport.webp";
import four2 from "../../assets/images/hotels/airports/buffalloNiagara/car-hire-at-buffalo-niagara-international-airport-to-courtyard-by-marriott-buffalo-airport.webp";
import four3 from "../../assets/images/hotels/airports/buffalloNiagara/budget-car-hire-buffalo-niagara-international-airport-to-courtyard-by-marriott-buffalo-airport.webp";
import { Helmet } from "react-helmet-async";

const BuffaloNiagara = () => {
  const hero = {
    title: "Buffalo Niagara International Airport (BUF)",
    description:
      "Travel better to and from Buffalo Niagara International Airport in our luxury First Class limousine vehicles. Whether you are going for business or pleasure, we guarantee your safety and comfort while you travel to your destination.",
  };
  const desc = {
    title: "Buffalo Niagara International Airport",
    description:
      "Buffalo Niagara International Airport plays an essential role in the development of business and tourism in the Buffalo Niagara region. Buffalo Niagara International Airport has up to 100 nonstop flights per day serving 31 airport destinations and beyond.",
    website: "https://www.buffaloairport.com/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Buffalo Niagara International Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle =
    "Stay at top unique properties near Buffalo Niagara Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "airport chauffeur service near the Buffalo Niagara International Airport to Holiday Inn Express & Suites Buffalo-Airport. Book with affordable price",
        "Private chauffeur service from Buffalo Niagara International Airport to Holiday Inn Express & Suites Buffalo-Airport. Book online",
        "Airport car service to  Holiday Inn Express & Suites Buffalo-Airport from Buffalo Niagara International Airport",
      ],
      rating: "8.5",
      title: "Holiday Inn Express & Suites Buffalo-Airport",
      location: "locaion",
      distance: "2.4 miles from airport",
      map: "https://maps.google.com/maps?q=42.92852840877911,%20-78.73846522832548&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "professional chauffeur service at Homewood Suites by Hilton Buffalo-Airport will take you to and from Buffalo Niagara International Airport",
        "Search, compare and book Buffalo Niagara International Airport transfers with a chauffeur from Homewood Suites by Hilton Buffalo-Airport",
        "book your private car service from Buffalo Niagara International Airport  to Homewood Suites by Hilton Buffalo-Airport",
      ],
      rating: "7.5",
      title: "Homewood Suites by Hilton Buffalo-Airport",
      location: "760 Dick Rd",
      distance: "1.4 miles from airport",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20Buffalo-Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Hire private cars from  Buffalo Niagara International Airport To Hampton Inn & Suites Buffalo Airport",
        "Book your Luxury Chauffeur from Hampton Inn & Suites Buffalo Airport to Buffalo Niagara International Airport",
        "Chauffeur service at Buffalo Niagara International Airport  to Hampton Inn & Suites Buffalo Airport",
      ],
      rating: "7.5",
      title: "Hampton Inn & Suites Buffalo Airport",
      location: "133 Buell Ave",
      distance: "2.4 miles from airport",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20and%20Suites%20Buffalo%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Buffalo Niagara International Airport  to Courtyard by Marriott Buffalo Airport",
        "Find cheep and luxury cars at Buffalo Niagara International Airport  to Courtyard by Marriott Buffalo Airport",
        "All inclusive budget car services from Buffalo Niagara International Airport  to Courtyard by Marriott Buffalo Airport",
      ],
      rating: "6.5",
      title: "Courtyard by Marriott Buffalo Airport",
      location: "4243 Genesee St",
      distance: "1.9 miles from airport",
      map: "https://maps.google.com/maps?q=Courtyard%20by%20Marriott%20Buffalo%20Airport&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Buffalo Niagara International Airport ",
      end: "Hamilton, Ontario",
      duration: "1 hr 28 min",
      distance: "73.3 miles",
      price: "211.72",
    },
    {
      start: "Buffalo Niagara International Airport ",
      end: "Niagara Falls, Ontario",
      duration: "40 min",
      distance: "27.1 miles",
      price: "211.72",
    },
    {
      start: "Buffalo Niagara International Airport ",
      end: "Toronto, Ontario",
      duration: "1 hr 56 min",
      distance: "108 miles",
      price: "211.72",
    },
    {
      start: "Buffalo Niagara International Airport ",
      end: "  Markham, Ontario",
      duration: "2 hr 14 min",
      distance: "124 miles",
      price: "211.72",
    },
    {
      start: "Buffalo Niagara International Airport ",
      end: "Mississauga, Ontario",
      duration: "1 hr 43 min",
      distance: "94.6 miles",
      price: "211.72",
    },
    {
      start: "Buffalo Niagara International Airport ",
      end: "St. Catharines, Ontario",
      duration: "51 min",
      distance: "41.2 miles",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book Online. Private Chauffeur Service at Buffalo Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24/7 Pre-Book Online. Reliable and Efficient Chauffeur Service to/from Buffalo Airport. Best Price Guaranteed.       .
      "
        />
        <meta
          name="keywords"
          content="airport chauffeur service, Buffalo chauffeur service, airport chauffeur, private chauffeur, chauffeur service, chauffeur service near me, chauffeur driver, personal chauffeur, executive car service, chauffeur for hire, luxury chauffeur service
          private chauffeur service, Airport Car service, Airport Limo service, Airport Chauffeur Buffalo, Buffalo Airport Chauffeur
          "
        />
        <link
          rel="canonical"
          href="/airport/buffalo-niagara-international-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default BuffaloNiagara;
