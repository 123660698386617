import React from "react";
import AttractionDesc from "../../components/web/attractions/AttractionDesc";
import AttractionsHero from "../../components/web/attractions/AttractionsHero";
import Faq from "../../components/web/Faq";
import MostBooked from "../../components/web/MostBooked";
import Stay from "../../components/web/Stay";
import Banner1 from "../../components/web/banners/Banner3";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/attractions/royal/all-inclusive-limousine-services-from-yorkville-royal-sonesta-to-royal-ontario-museum-complete-luxury-transportation-available.jpg";
import one2 from "../../assets/images/hotels/attractions/royal/luxury-car-services-from-yorkville-royal-sonesta-to-royal-ontario-museum-high-comfortable-and-hassle-free-services.jpg";
import one3 from "../../assets/images/hotels/attractions/royal/business-class-trips-from-yorkville-royal-sonesta-to-royal-ontario-museum-hourly-services-available-at-affordable-price.jpg";
import two1 from "../../assets/images/hotels/attractions/royal/private-car-services-from-park-hyatt-toronto-to-royal-ontario-museum-fun-and-enjoyful-trip-from-achauffeur.jpg";
import two2 from "../../assets/images/hotels/attractions/royal/executive-and-personalized-limo-services-from-park-hyatt-toronto-to-royal-ontario-museum-clean-and-comfortable-limos-from-achauffeur.jpg";
import two3 from "../../assets/images/hotels/attractions/royal/hire-a-private-car-from-park-hyatt-toronto-to-royal-ontario-museum-we-have-well-trained-drivers-start-your-trip-with-pleasure.jpg";
import three1 from "../../assets/images/hotels/attractions/royal/chauffeur-service-at-windsor-arms-hotel-to-royal-ontario-museum-make-your-reservations-online.jpg";
import three2 from "../../assets/images/hotels/attractions/royal/limo-cars-near-windsor-arms-hotel-to-royal-ontario-museum-hire-a-car-at-affordable-price.jpg";
import three3 from "../../assets/images/hotels/attractions/royal/personal-chauffeur-service-from-windsor-arms-hotel-to-royal-ontario-museum-safe-and-secure-service-available.jpg";
import four1 from "../../assets/images/hotels/attractions/royal/book-cars-online-from-kimpton-saint-george-hotel-to-royal-ontario-museum-we-have-professional-drivers.jpg";
import four2 from "../../assets/images/hotels/attractions/royal/higenic-and-comfortable-car-services-from-kimpton-saint-george-hotel-to-royal-ontario-museum-we-guarantee-a-stress-free-transportation.jpg";
import four3 from "../../assets/images/hotels/attractions/royal/premium-quality-limo-services-from-kimpton-saint-george-hotel-to-royal-ontario-museum-our-rates-are-affordable.jpg";
const RoyalOntarioMuseum = () => {
  const hero = {
    title: "Royal Ontario Museum",
    description:
      "If you want a relaxing start to your journey, AChauffeur provides luxurious, hassle-free transportation to and from Royal Ontario Museum. With one of our professional chauffeurs, we guarantee your pleasant and secure journey.",
  };

  const desc = {
    title: "It's easy, book your chauffeur service to Manitoulin Island.    ",
    description:
      "The Royal Ontario Museum (ROM) is a museum of art, culture, and natural history located in Toronto, Ontario, Canada. It is one of the largest museums in North America and is known for its diverse and extensive collections, which include over six million artifacts and specimens from around the world.The ROM is home to a number of galleries and exhibits, including the Art of East Asia Gallery, the Earth's Treasures Gallery, and the Fossil Gallery. The museum also has a number of special exhibitions throughout the year, featuring a range of subjects from art and culture to science and natural history.",
    website: "https://www.billybishopairport.com/",
    description2:
      "Achauffeur offers multiple services including sightseeing tours. You can experience all of the sights and sounds of Royal Ontario Museum, as your own personal driver shows you the must-see tourist attractions near Royal Ontario Museum. You can make an online booking or directly can make a call on the given numbers for your trip.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Complete luxury and all inclusive limousine services from Yorkville Royal Sonesta to Royal Ontario museum.",
        "High comfortable and hassle free luxury limousine services from Yorkville Royal Sonesta to Royal Ontario museum",
        "Hourly limo services at affordable price from Yorkville Royal Sonesta to Royal Ontario museum. Business class trips for your vacations",
      ],
      title: "Yorkville Royal Sonesta",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Fun and enjoyable trips from Park Hyatt Toronto to Royal Ontario museum. Private car services from hotel",
        "Achauffeur offers executive and personalized limousine services from Park Hyatt Toronto to Royal Ontario museum. We maintain our limos clean and comfortable",
        "Start your trip with pleasure with well-trained drivers from Park Hyatt Toronto to Royal Ontario museum. Hire a private car at an affordable price",
      ],
      title: "Park Hyatt Toronto",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "Make your reservations online for your chauffeur services from Windsor Arms Hotel to Royal Ontario museum",
        "Hire a car at affordable price near Windsor Arms Hotel to Royal Ontario museum.best chauffeur services",
        "Safe and secure personal chauffeur services from Windsor Arms Hotel to Royal Ontario museum. High comfortable and luxury",
      ],
      title: "Windsor Arms Hotel",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Book cars with professional drivers from Kimpton Saint George Hotel  to Royal Ontario museum. Best limo services to canada’s wonderland",
        "Achauffeur offers hygienic and comfortable car services from Kimpton Saint George Hotel  to Royal Ontario museum. We guarantee a stress free transportation ",
        "Book premium quality chauffeur services from Kimpton Saint George Hotel Royal Ontario museum. Our rates are affordable",
      ],
      title: "Kimpton Saint George Hotel",
    },
  ];
  const mostBookeds = [
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
    {
      start: "Manitoulin Island",
      end: "Barrie (Lake Simcoe)",
      duration: "1h 00 min",
      distance: "23 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          All-Inclusive Manitoulin Island Private Chauffeur Services.
        </title>
        <meta
          name="description"
          content="Your Executive Chauffeur Service. 24Hrs, Pre-Book Online. Reliable and Efficient Private Chauffeur Service To/from Blue Manitoulin Island. Best Price Guaranteed.
          "
        />
        <meta
          name="keywords"
          content="Chauffeur service to Royal Ontario Museum, Chauffeur service from Royal Ontario Museum, chauffeur service, Chauffeur service from toronto to Royal Ontario Museum, airport car service, airport limo service, black car service, airport limo, Airport transfer, airport limousine, toronto chauffeur service, chauffeur service toronto, private chauffeur service, chauffeur service near me, luxury chauffeur service toronto, executive chauffeur service, personal chauffeur service, luxury chauffeur service, a Chauffeur"
        />
        <link
          rel="canonical"
          href="/tourist-attraction/chauffeur-service-to-royal-ontario-museum"
        />
      </Helmet>
      <AttractionsHero hero={hero} />
      <AttractionDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default RoyalOntarioMuseum;
