import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner2";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/markham/hilton-toronto-markham-suites-conference-centre-and-spa-chauffeur-service-markham-limo-services-car-service-near-markham.webp";
import one2 from "../../assets/images/hotels/cities/markham/hilton-toronto-markham-suites-conference-centre-and-spa-private-chauffeur-service-markham-car-service-from-markham.webp";
import one3 from "../../assets/images/hotels/cities/markham/airport-transfer-services-from-hilton-toronto-markham-suites-conference-centre-and-spa-best-price-car-services-in-markham.webp";
import two1 from "../../assets/images/hotels/cities/markham/limousine-services-from-monte-carlo-inn-and-suites-car-service-24-hours-in-markham-best-car-services-in-markham.jpg";
import two2 from "../../assets/images/hotels/cities/markham/chauffeur-service-in-markham-to-monte-carlo-inn-and-suites-at-affordable-price-limo-car-service-downtown-markham.jpg";
import two3 from "../../assets/images/hotels/cities/markham/hourly-chauffeur-service-from-monte-carlo-inn-and-suites-affordable-car-services-near-markham.jpg";
import three1 from "../../assets/images/hotels/cities/markham/car-service-from-markham-to-homewood-suites-by-hilton-toronto-markham-all-the-way-limo-services-markham.webp";
import three2 from "../../assets/images/hotels/cities/markham/executive-chauffeur-service-near-homewood-suites-by-hilton-toronto-markham-comfortable-chauffeur-services-in-markham.webp";
import three3 from "../../assets/images/hotels/cities/markham/budget-car-hire-markham-to-homewood-suites-by-hilton-toronto-markham-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/markham/car-hire-near-toronto-marriott-markham-business-class-limo-services.webp";
import four2 from "../../assets/images/hotels/cities/markham/all-inclusive-limo-services-near-toronto-marriott-markham-limo-services-online-bookings.webp";
import four3 from "../../assets/images/hotels/cities/markham/markham-limo-services-to-toronto-marriott-markham-car-service-with-driver.webp";

const Markham = () => {
  const hero = {
    title: "HIRE A CHAUFFEUR IN MARKHAM",
    description:
      "In Markham, hire a chauffeur with a car, SUV, van, or bus for convenient airport transfers and private rides. Perfect for business and leisure travel.",
  };

  const desc = {
    title: "Markham",
    description:
      <span>In need of professional ground transportation in <b>Markham</b>? <b>A Chauffeur by Get Limo Inc</b>. is available 24/7, offering a fleet of luxury vehicles at fixed prices. Whether you are heading to a business meeting or enjoying the <b>Markham Jazz Festival</b>, our <b>luxury car service in Markham</b> and <b>chauffeur service in Markham</b> deliver an unparalleled experience</span>,
    website: "https://visitmarkham.ca",
    description2:
      <span>We specialize in <b>corporate transportation in Markham</b> and offer <b>private car hire in Markham</b> for leisure travel. For long journeys, our <b>long-distance rides from Markham</b> ensure you reach your destination comfortably. Need to transport a group? Our <b>Sprinter van for hire in Markham</b> is the perfect solution, and families can benefit from our <b>ride with car seats in Markham</b> for peace of mind.</span>,
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from markham to Hilton Toronto/Markham Suites Conference Centre & Spa  best car services from achauffeur",
        "Private chauffeur service from markham city to Hilton Toronto/Markham Suites Conference Centre & Spa . luxury car service near markham",
        "Find airport transfer services in markham to Hilton Toronto/Markham Suites Conference Centre & Spa  at an affordable price and best services",
      ],
      title: "Hilton Toronto/Markham Suites Conference Centre & Spa",
      location: "Markham, Ontario",
      distance: "850 m from center",
      map: "https://maps.google.com/maps?q=Hilton%20Toronto/Markham%20Suites%20Conference%20Centre%20&%20Spa&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Monte Carlo Inn & Suites to markham city. Best car services from achauffeur",
        "Safe and secure chauffeur services in markham to Monte Carlo Inn & Suites. All-inclusive car services downtown markham",
        "Hourly chauffeur service from achauffeur to Monte Carlo Inn & Suites from markham canada at affordable price rates",
      ],
      title: "Monte Carlo Inn & Suites - Downtown Markham",
      location: "Markham, Ontario",
      distance: "4.2 km from center",
      map: "https://maps.google.com/maps?q=Monte%20Carlo%20Inn%20and%20Suites%20-%20Downtown%20Markham&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from Markham to Homewood Suites by Hilton Toronto-Markham. Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Homewood Suites by Hilton Toronto-Markham from Markham city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in Markham. Hire budget cars and limos to Homewood Suites by Hilton Toronto-Markham. Luxury service available",
      ],
      title: "Homewood Suites by Hilton Toronto-Markham",
      location: "Markham, Ontario",
      distance: "2.4 km from center",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20Toronto-Markham&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in Markham to Toronto Marriott Markham . Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from Markham city to Toronto Marriott Markham ",
        "Do you need a car with driver? Achauffeur offers limo services with driver in Markham to Toronto Marriott Markham ",
      ],
      title: "Toronto Marriott Markham",
      location: "Markham, Ontario",
      distance: "5.0 km from center",
      map: "https://maps.google.com/maps?q=Toronto%20Marriott%20Markham&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Markham",
      end: "Toronto, Ontario",
      duration: "39 min",
      distance: "31.1 Km",
      price: "211.72",
    },
    {
      start: "Markham",
      end: "Newmarket, Ontario",
      duration: "30 min",
      distance: "31.6 Km",
      price: "211.72",
    },
    {
      start: "Markham",
      end: "Vaughan, Ontario",
      duration: "26 min",
      distance: "20.5 Km",
      price: "211.72",
    },
    {
      start: "Markham",
      end: "Mississauga",
      duration: "27 min",
      distance: "38.2 Km",
      price: "211.72",
    },
    {
      start: "Markham",
      end: "Niagara Falls, Ontario",
      duration: "1h 42 min",
      distance: "160 Km",
      price: "211.72",
    },
    {
      start: "Markham",
      end: "Buffalo, New York, USA",
      duration: "2h 9 min",
      distance: "197 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Markham Chauffeur Service | Sprinter Van Hire & Airport Ride</title>
        <meta
          name="description"
          content="Markham Chauffeur Services For Airport Transfers, Sprinter Van Hire, And Long-Distance Rides. Professional Travel 24/7 For Business And Leisure."
        />
        <meta
          name="keywords"
          content="Markham Chauffeur Service, Markham Airport Transfer, Luxury Car Service Markham, Sprinter Van For Hire Markham, Executive Car Service Markham, Chauffeur Service In Markham, Private Car Hire Markham, Markham Long-Distance Rides, VIP Transportation Markham, Intercity Transportation Markham"
        />
        <link rel="canonical" href="/city/chauffeur-service-markham" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Markham;
