import React from "react";
import Banner3 from "../../components/web/banners/Banner3";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
import f1 from "../../assets/images/services/fleet-limousine-service-for-any-event.jpg";
import f2 from "../../assets/images/services/interesting-and-less-tedious-all-event-limo-services.jpg";

const EventLimousineService = () => {
  const hero = {
    title: "Event Limousine Service",
    description:
     <span>Upgrade your events with our premium Event Limousine and <b>Luxury Car Service</b>! Enjoy unforgettable transfers, including wedding limo service in Toronto, proms, and more. Receive an all-inclusive price when you get your online quote today, adding a touch of luxury to your special occasions.</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Event Limousine Service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Event Limousine Service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };

  const feature1 = {
    image: f1,
    alt: "Limousine services with professional chauffeurs for any kind of events",
    title: "Premier Limousine Service: Elevate Your Occasions",
    description:
      <span>Experience the epitome of luxury and safety with our <b>premium limousine service</b>. Whether you desire an elegant wedding limo for a grand entrance, <b>a reliable airport limo for stress-free travel</b>, or a lavish prom limo to craft indelible memories, we've got you covered. Our competitive limousine rates, meticulously maintained fleet, and professional chauffeurs, who prioritize your comfort and safety, set us apart as a premier choice among limousine companies. When you choose us, you're not just securing a ride; you're guaranteeing a stylish and sophisticated experience that will leave a lasting impression. Safety is our first priority, and we offer the best price guarantee. Trust our expert chauffeurs to provide the ultimate in luxury and safety. Contact us today to make a limousine reservation and elevate your transportation for any occasion.</span>,
  };
  const feature2 = {
    image: f2,
    alt: "All event limousine services. Travel with interesting and less tedious",
    title: "Party in Style with Our Limo Services",
    description:
      <span>From birthday extravaganzas to unforgettable prom nights, our <b>limousine services</b> are the ideal choice to elevate your celebrations. Safety is our first priority, ensuring you can enjoy your special day worry-free. With our specialized limo birthday party services, you'll experience a unique and fun-filled way to mark your special day, complete with all the amenities to keep the excitement alive. When it comes to prom night, our dedicated prom limousine service ensures both safety and glamour, ensuring memories that will last a lifetime. Regardless of the occasion, our <b>chauffeur-driven limousines</b> are ready to transform your events into unforgettable moments. Get an estimate online today to secure your reservation and infuse an element of luxury and elegance into your celebrations!</span>,
  };

  return (
    <>
      <Helmet>
        <title>Book Your Event Limousine Service Today | AChauffeur.com</title>
        <meta
          name="description"
          content="Looking for chauffeur service for your events? Book online 24/7. Efficient and reliable private car service for your leisure, business and events. Best Price Guaranteed

  "
        />{" "}
        <meta
          name="keywords"
          content="Toronto Event Limousine Service for Weddings, Event Chauffeur Limousine Service Toronto, Luxury Wedding Limousine Toronto, Prom Event Limousine Service Toronto, Professional Event Limo Chauffeur Toronto, Toronto Limousine Service for Special Events, Airport Event Limo Service Toronto, Event Limousine Hire for Toronto Weddings, Event Wedding Limousine Service Toronto, Toronto Event Chauffeur Limo Service"
        />
        <link rel="canonical" href="/services/event-limousine-service" />
      </Helmet>
      <ServiceHero hero={hero} />
      <Banner3 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default EventLimousineService;
