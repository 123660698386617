import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

const AttractionDesc = (props) => {
  return (
    <>
      <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
        <div className="container">
          <h3>{props.desc.title}</h3>

          <div className="text-base mb-2">{props.desc.description}</div>
          <div className="text-base mb-2">{props.desc.description2}</div>
          <div className="flex items-center gap-1 w-fit text-black hover:text-pri-500 font-semibold text-base ">
            <Link
              to="/book-now-a-chauffeur-service"
              className="text-black hover:text-pri-500"
            >
              Book for your Trip
            </Link>
            <ArrowRightOutlined className="text-sm" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AttractionDesc;
