import React from "react";
import { Link } from "react-router-dom";

const AllCitiesHero = () => {
  return (
    <div className="px-3 py-12   bg-sec-100">
      <div className="container">
        <h2 className="text-center max-w-3xl mx-auto">
          We serve luxurious limo services around the cities of Canada.
        </h2>
        <div className="text-center">
          <Link to="/book-now-a-chauffeur-service" className="btn1">
            Book Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AllCitiesHero;
