import { Collapse } from "antd";
import React from "react";
const { Panel } = Collapse;

function HelpBody() {
  const start = [
    {
      question: "How do I contact your customer care team?",
      key: "1",
      answer:
        "You can reach our Customer Care Team via telephone at +1- 888-999-4545  and +1-416-800-1009, email at info@achauffeur.com or whatsapp chat.",
    },
    {
      question: "What if I have more luggage than the baggage allowance?",
      key: "2",
      answer:
        "We do not recommend going over the maximum luggage allowance indicated for each booking because we focused on safety. If you are traveling with more luggage than the permitted, make sure you reserve enough vehicles to be able to transfer all of your luggage without exceeding the maximum capacity. Excess baggage may result in additional fees, and Achauffeurs reserve the right to refuse service based on the amount of additional luggage.If you are unfamiliar with the vehicle maximum luggage allowance, please contact us and we would be happy to help you.",
    },
    {
      question: "Can I bring food and drinks into the car?",
      key: "3",
      answer:
        "Yes, Customers are welcome to bring their own snacks, beverages and coolers.  However, if the vehicle requires cleaning due to spilled food or drinks, there will be an additional charge because maintaining the cleanliness of the vehicles is an important part of our service      ",
    },
  ];
  const bookings = [
    {
      question: "How can I make changes to my bookings?",
      key: "1",
      answer:
        "You can request any modification to your booking via our contact form, email, or by giving us a call. Changes requested within 24 h of your transfer (local time) are subject to availability.",
    },
    {
      question: "How do I add extra stops while traveling?",
      key: "2",
      answer:
        "You can call our hotline and add extra stops at any time or communicate with the driver to add extra stops while traveling. You will be charged for extra stops.",
    },
    {
      question:
        "I booked a ride with the wrong pickup or dropoff location. What do I do?",
      key: "3",
      answer: "Call to our hotline to change the location as soon as possible.",
    },
  ];
  const payments = [
    {
      question: "Which payment options are available?",
      key: "1",
      answer:
        "You can pay using any of the following method Cash payment, American Express, Credit card, Direct Bill/ Invoice, Invoice Credits, MasterCard, e-transfer, Visa	",
    },
    {
      question: "When do I get charged for my ride?",
      key: "2",
      answer:
        "You will be charged during the booking process. You have to pay additional charges if you add extra stops while traveling",
    },
    {
      question: "What factors influence the price?",
      key: "3",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
  ];
  const safety = [
    {
      question: "Am I supposed to wear a face mask during the ride?",
      key: "1",
      answer:
        "Yes, guests are strongly encouraged to wear a face mask during rides, but not required. This protects chauffeurs and future guests as well.      ",
    },
    {
      question: "Do you have hourly booking service?",
      key: "2",
      answer:
        "Yes, you can reserve one of our chauffeur-driven vehicle by the hour, the price is determined by the car's size and model. We need a minimum of three hours of hire time for the hourly service.",
    },
    {
      question: "I have more questions, Who should I contact?",
      key: "3",
      answer:
        "If you have any more questions, feel free to reach us through whatsapp, call, message or email . Our customer service is available at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <h3>Getting started</h3>
        <Collapse accordion>
          {start.map((faq) => (
            <Panel header={faq.question} key={faq.key}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
        <h3 className=" mt-10">Manage bookings</h3>
        <Collapse accordion>
          {bookings.map((faq) => (
            <Panel header={faq.question} key={faq.key}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
        <h3 className=" mt-10">Billing and payments</h3>
        <Collapse accordion>
          {payments.map((faq) => (
            <Panel header={faq.question} key={faq.key}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
        <h3 className=" mt-10">Safety questions</h3>
        <Collapse accordion>
          {safety.map((faq) => (
            <Panel header={faq.question} key={faq.key}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}

export default HelpBody;
