import React from "react";
import { Link } from "react-router-dom";

const Banner1 = () => {
  return (
    <div className="banner1--cover">
      <div className="promise px-3 py-8 sm:py-8 ">
        <div className="container">
          <div className="md:grid grid-cols-2">
            <div className="text-center md:text-left">
              <h2 className="text-white">
               WE PROMISE, YOU WILL HAVE THE BEST EXPERIENCE.{" "}
              </h2>
              <div className="text-base -mt-2 mb-5 text-white">
               With our skilled chauffeurs and extensive experience,
               we guarantee exceptional service. 
               Upgrade to our chauffeur service for complete customer satisfaction.
              </div>
              <Link to="/book-now-a-chauffeur-service" className="btn2">
                Get an Estimate online
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner1;
