import React from "react";
import AirportDesc from "../../components/web/airports/AirportDesc";
import AirportHero from "../../components/web/airports/AirportHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";

// stay images
import one1 from "../../assets/images/hotels/airports/greatSudbury/executive-chauffeur-service-from-greater-sudbury-airport-to-hilton-garden-inn-sudbury.webp";
import one2 from "../../assets/images/hotels/airports/greatSudbury/hourly-chauffeur-service-at-greater-sudbury-airport-to-hilton-garden-inn-sudbury.webp";
import one3 from "../../assets/images/hotels/airports/greatSudbury/chauffeur-service-near-greater-sudbury-airport-to-hilton-garden-inn-sudbury.webp";
import two1 from "../../assets/images/hotels/airports/greatSudbury/holiday-inn-sudbury-an-ihg-hotel-chauffeur-service-greater-sudbury-airport.jfif";
import two2 from "../../assets/images/hotels/airports/greatSudbury/holiday-inn-sudbury-an-ihg-hotel-private-chauffeur-service-greater-sudbury-airport.jfif";
import two3 from "../../assets/images/hotels/airports/greatSudbury/holiday-inn-sudbury-an-ihg-hotel-car-service-greater-sudbury-airport.jfif";
import three1 from "../../assets/images/hotels/airports/greatSudbury/chauffeur-service-in-greater-sudbury-airport-to-pine-falls-lodge.png";
import three2 from "../../assets/images/hotels/airports/greatSudbury/achauffeur-airport-transfer-to-pine-falls-lodge.webp";
import three3 from "../../assets/images/hotels/airports/greatSudbury/car-service-from-greater-sudbury-airport-to-pine-falls-lodge.png";
import four1 from "../../assets/images/hotels/airports/greatSudbury/limo-service-at-greater-sudbury-airport-to-towneplace-suites-by-marriott-sudbury.webp";
import four2 from "../../assets/images/hotels/airports/greatSudbury/car-hire-at-greater-sudbury-airport-to-towneplace-suites-by-marriott-sudbury.webp";
import four3 from "../../assets/images/hotels/airports/greatSudbury/budget-car-hire-greater-sudbury-airport-to-towneplace-suites-by-marriott-sudbury.jpg";
import { Helmet } from "react-helmet-async";

const GreaterSudbury = () => {
  const hero = {
    title: "Greater Sudbury Airport",
    description:
      "Whether you are traveling for leisure or business, take advantage of a pleasant chauffeur service from the Greater Sudbury Airport to the entrance of your lodging with the total luxury of our transfers.",
  };
  const desc = {
    title: "Greater Sudbury Airport",
    description:
      "Greater Sudbury Airport Awarded the Safe Travels Stamp by the Worldwide Travel & Tourism Association for meeting and exceeding international health and hygiene protocols so that travellers can experience Safe Travels.",
    website: "https://flysudbury.ca/",
    description2:
      "Achauffeur pride ourselves in offering premium and luxurious limo services from Greater Sudbury Airport. We provide airport transfers for both business and leisure for everyone. You can make an online booking or directly can make a call on the given numbers. We maintain a fleet of comfortable and clean limousines driven by highly professional, well-trained chauffeurs. The basic fundamental for us is customer satisfaction so our drivers go above and beyond to provide that quality service     Once you book with us, you will have peace of mind that your taxi will be there on time. Moreover, we try to send reminder text messages to our customers. ",
  };
  const staysTitle = "Stay at top unique properties near Sudbury Airport.";
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Hire private cars from Greater Sudbury Airport To Hilton Garden Inn Sudbury",
        "Book your Luxury Chauffeur from Hilton Garden Inn Sudbury to Greater Sudbury Airport",
        "Chauffeur service at Greater Sudbury Airport  to Hilton Garden Inn Sudbury",
      ],
      rating: "8.5",
      title: "Hilton Garden Inn Sudbury",
      location: "475 Barry Downe Rd",
      distance: "19.4 km km from airport",
      map: "https://maps.google.com/maps?q=Hilton%20Garden%20Inn%20Sudbury,%20475%20Barry%20Downe%20Rd&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "airport chauffeur service near the Greater Sudbury Airport to Holiday Inn Sudbury, an IHG Hotel. Book with affordable price",
        "Private chauffeur service from Greater Sudbury Airport to Holiday Inn Sudbury, an IHG Hotel. Book online",
        "Airport car service to Holiday Inn Sudbury, an IHG Hotel from Greater Sudbury Airport",
      ],
      rating: "8.1",
      title: "Holiday Inn Sudbury",
      location: "1696 Regent St",
      distance: "28.1 km from airport",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Sudbury,%20an%20IHG%20Hotel,%201696%20Regent%20St&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "professional chauffeur service at Pine Falls Lodge will take you to and from Greater Sudbury Airport",
        "Search, compare and book Greater Sudbury Airport transfers with a chauffeur from Pine Falls Lodge",
        "book your private car service from Greater Sudbury Airport  to Pine Falls Lodge",
      ],
      rating: "8.5",
      title: "Pine Falls Lodge",
      location: "2000A Chiniguchi River Rd",
      distance: "2.5 km from airport",
      map: "https://maps.google.com/maps?q=Pine%20Falls%20Lodge,%202000A%20Chiniguchi%20River%20Rd,%20Markstay-Warren&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Affordable limousine service from Greater Sudbury Airport to TownePlace Suites by Marriott Sudbury",
        "Find cheep and luxury cars at Greater Sudbury Airport to TownePlace Suites by Marriott Sudbury",
        "All inclusive budget car services from Greater Sudbury Airport to TownePlace Suites by Marriott Sudbury",
      ],
      rating: "8.2",
      title: "TownePlace Suites by Marriott Sudbury",
      location: " 1710 The, Kingsway, Greater Sudbury",
      distance: "18.6 km from airport",
      map: "https://maps.google.com/maps?q=TownePlace%20Suites%20by%20Marriott%20Sudbury&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Greater Sudbury Airport",
      end: " Greater Sudbury, Ontario ",
      duration: "22 min",
      distance: "23.1 km",
      price: "211.72",
      map: "",
    },
    {
      start: "Greater Sudbury Airport",
      end: "Sault Ste. Marie, Ontario",
      duration: "3 hr 38 min",
      distance: "332 km",
      price: "211.72",
    },
    {
      start: "Greater Sudbury Airport",
      end: "Manitoulin Island, Ontario",
      duration: "2 hr 27 min",
      distance: "199 km",
      price: "211.72",
    },
    {
      start: "Greater Sudbury Airport",
      end: "North Bay, Ontario",
      duration: "1 hr 33 min",
      distance: "132 km",
      price: "211.72",
    },
    {
      start: "Greater Sudbury Airport",
      end: "Toronto, Ontario",
      duration: "4 hr 11 min",
      distance: "424 km",
      price: "211.72",
    },
    {
      start: "Greater Sudbury Airport",
      end: "Parry Sound, Ontario",
      duration: "1 hr 56 min",
      distance: "186 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header:
        " What will happen if I am delayed due to long queues at the airport?",
      key: "4",
      answer:
        "All airport pickups by a chauffeur include one hour of complimentary waiting time. We are aware that unexpected changes could be made to airport arrival processes. Our chauffeurs are flexible and wait longerthan an hour if at all possible.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Book Online. A Chauffeur Service at Greater Sudbury Airport.
        </title>
        <meta
          name="description"
          content="Your Professional Airport Chauffeur Service. 24hrs, Pre-Book Online. Reliable and Efficient Chauffeur Service to/from Sudbury Airport. Best Price Guaranteed.

      "
        />
        <meta
          name="keywords"
          content="airport chauffeur service,  Sudbury chauffeur service, airport chauffeur, private chauffeur, chauffeur service, chauffeur service near me, chauffeur driver, personal chauffeur, executive car service, chauffeur for hire, luxury chauffeur service
          private chauffeur service, Airport Car service, Airport Limo service, Airport Chauffeur  Sudbury,  Sudbury Airport Chauffeur"
        />
        <link
          rel="canonical"
          href="/airport/greater-sudbury-airport-chauffeur-service"
        />
      </Helmet>
      <AirportHero hero={hero} />
      <AirportDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} staysTitle={staysTitle} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default GreaterSudbury;
