import React from "react";
import car from "../../../assets/images/fleets/minicoach-for-large-group-of-people.png";
import { Link } from "react-router-dom";

const MiniCoach = () => {
  return (
    <div className="px-3 py-12 sm:py-16  bg-sec-100">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-start">
          <div className="place-self-center">
            <img
              className="h-60"
              src={car}
              alt="Book minicoach for large group of people at affordable price"
            />
          </div>
          <div>
            <h3>MiniCoach </h3>
            <h4 className="text-pri-500">30 Pax MiniCoach (or similar)</h4>
            <div className="text-base mb-3">
              The Mini Coach is the perfect way to travel in style, with room
              for up to 30 of your closest friends. With its luxurious leather
              seats, it’s ideal for Wine Tasting Tours and special occasions.
            </div>
            <div className="grid sm:grid-cols-2 gap-2 mb-5">
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-user-line  "></i>
                </div>
                <div>Up to 30 passengers, 27 comfortable</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-briefcase-line  "></i>
                </div>
                <div>30 luggages</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-database-2-line  "></i>
                </div>
                <div>Overhead storge compartments</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-lightbulb-line  "></i>
                </div>
                <div>Reading light</div>
              </div>
              <div className="flex gap-2 items-start">
                <div
                  className="rounded-md h-6 flex items-center justify-center "
                  style={{
                    color: "#fd4326",
                    minWidth: "1.5rem",
                    backgroundColor: "rgba(253, 67, 38,.1)",
                  }}
                >
                  <i className="ri-tv-2-line  "></i>
                </div>
                <div>TV's and DVD system</div>
              </div>
            </div>
            <Link to="/book-now-a-chauffeur-service" className="btn1 text-base">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCoach;
