import { Modal } from "antd";
import React, { useState } from "react";
import { Carousel } from "antd";

const StayCard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="box--shadow hover:shadow-md overflow-hidden rounded-md bg-white">
      <div className="relative">
        <Carousel autoplay>
          <div className="relative h-40 w-full overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={props.stay.image[0]}
              alt={props.stay.alt[0]}
            />
          </div>
          <div className="relative h-40 w-full overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={props.stay.image[1]}
              alt={props.stay.alt[1]}
            />
          </div>
          <div className="relative h-40 w-full overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={props.stay.image[2]}
              alt={props.stay.alt[2]}
            />
          </div>
        </Carousel>
      </div>
      <div className="p-3 mt-3">
        <h4 className="roboto">{props.stay.title}</h4>
        {props.stay.location ? (
          <div className="flex  gap-2 mb-1">
            <i className="ri-map-pin-line  text-pri-500"></i>
            <div> {props.stay.location}</div>
          </div>
        ) : (
          <></>
        )}
        {props.stay.distance ? (
          <div className="flex   gap-2 mb-1">
            <i className="ri-pin-distance-line text-pri-500"></i>
            <div> {props.stay.distance}</div>
          </div>
        ) : (
          <></>
        )}
        {props.stay.map ? (
          <div className="flex cursor-pointer  gap-2 mb-1">
            <i className="ri-map-2-line  text-pri-500"></i>{" "}
            <div onClick={() => setIsModalOpen(true)}>
              <b>View on map</b>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal
        title={props.stay.title}
        centered
        visible={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <iframe
          title={props.stay.title}
          className="h-96 w-full"
          src={props.stay.map}
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </Modal>
    </div>
  );
};

export default StayCard;
