import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";
import Banner2 from "../../components/web/banners/Banner1";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";

import f1 from "../../assets/images/services/meet-and-greet-car-service-exclusive-and-personalized.jpg";
import f2 from "../../assets/images/services/smart-greeters-at-achauffeur’s-meet-and-greet-service.jpg";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";
const MeetAndGreetService = () => {
  const hero = {
    title: "Meet and Greet Service",
    description:
      <span>Traveling to Toronto, Canada? Let us make your arrival memorable with our <b>Airport Meet and Greet Service</b>. Our friendly team will be at the airport to assist you, and our reliable car service ensures a seamless journey to your destination. Available 24/7 – contact us to arrange your warm welcome</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Meet and Greet Service",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Meet and Greet Service",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "Exclusive and Personalized meet and greet service. We provide services at any locations.",
    title: "Achauffeur: Your Meet and Greet Solution",
    description:
      <span>Elevate your travel experience by choosing Achauffeur's exclusive <b>Meet and Greet Service</b>, where you can hire a chauffeur to ensure a seamless and personalized airport journey. Our highly experienced chauffeurs are dedicated to catering to your specific needs, whether it's welcoming you upon arrival, assisting with baggage claim, or escorting you inside the airport. With a personalized name sign, our courteous and knowledgeable chauffeur ensures that your arrival is met with a warm and welcoming touch. Discover the unparalleled ease and <b>luxury of hiring a chauffeur for our Meet and Greet Service</b>, and choose Achauffeur for a journey that sets a new standard in convenience, elegance, and personalization.       </span>,
  };
  const feature2 = {
    image: f2,
    alt: "achauffeur welcomes you during your business and private trips. Meet and greet services with smart greeters.",
    title: "Achauffeur: Elevating Your Arrival Experience   ",
    description:
      <span>Discover the epitome of luxury and convenience with Achauffeur's <b>private airport meet and greet services</b>. Whether you're arriving by private plane, helicopter, yacht, or attending a special event, our bilingual greeters will personally welcome you upon arrival. Expect nothing less than exceptional service, as our dedicated team assists you discreetly and efficiently throughout your journey. Enjoy a warm and personalized welcome on your business and private trips alike. Choose excellence in <b>meet and greet assistance</b> for a seamless experience at the airport and beyond. Upgrade your travel experience with Achauffeur and book now to elevate your private travel experience to the next level!</span>,
  };
  return (
    <>
      <Helmet>
        <title>
          Book Your Airports Meet And Greet Service | AChauffeur.com
        </title>
        <meta
          name="description"
          content="A chauffeured meet and greet service ensures smooth coordination during airport arrivals, departures or transfers. Book online 24/7. Best Price Guaranteed"
        />{" "}
        <meta
          name="keywords"
          content="Meet And Greet Service, Airport Meet And Greet Services, Toronto Pearson Airport Meet And Greet, Airport Meet And Assist Services, Pearson International Airport Meet & Greet, Meet And Greet Toronto Airport, Meet And Greet Toronto, Toronto Meet And Greet Service, Toronto Airport Meet And Greet Service, Meet & Greet Service"
        />
        <link rel="canonical" href="/hoservices/meet-and-greet-serviceme" />
      </Helmet>
      <ServiceHero hero={hero} />
      <Banner2 />
      <ServiceFeature1 feature={feature1} />{" "}
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default MeetAndGreetService;
