import React from "react";
import { Link } from "react-router-dom";

const Banner2 = () => {
  return (
    <div className="banner2--cover">
      <div className="promise px-3 py-8 sm:py-8">
        <div className="container">
          <div className="md:grid grid-cols-2">
            <div className="">
              <h2 className="text-white">
                Book for your next ride in Toronto and other cities
              </h2>
              <div className="text-base -mt-2 mb-5 text-white">
                Our chauffeurs are industry trained, accredited and insured,
                providing our valued clients with the confidence that they are
                in safe hands.
              </div>
              <Link to="/book-now-a-chauffeur-service" className="btn2">
                Get an Estimate online
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner2;
