import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Toast from "../Toast";

const SocialContacts = () => {
  const [toastMessage, setToastMessage] = useState({ s: "", t: "" });
  const form = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  function handleReset() {
    setUserEmail("");

    setSubmit(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (userEmail) {
      setSubmit(true);
      emailjs
        .sendForm(
          "service_dctm35j",
          "template_nhcn1c8",
          form.current,
          "SJyEAvOtrLyn4Vl93"
        )
        .then(
          (result) => {
            setToastMessage({
              s: "success",
              t: "We have received your request!",
            });
            handleReset();
          },
          (error) => {
            setToastMessage({
              s: "error",
              t: "Subscription not send! Try again",
            });
            handleReset();
          }
        );
    } else {
      setError("Fill all fields");
      setToastMessage({ s: "error", t: "Please fill all fields!" });
      setSubmit(false);
    }
  };
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <h3 className="text-center">
          Stay turned to A Chauffeur's newsletter and get discounts for your
          next booking!
        </h3>
        <form ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col sm:flex-row items-center justify-center my-10 gap-5">
            <input
              onChange={(e) => {
                setUserEmail(e.target.value);
                setError("");
              }}
              value={userEmail}
              type="email"
              name="userEmail"
              placeholder="Enter your email"
              className="border-b-2 p-3 bg-transparent outline-none w-full max-w-sm"
              style={
                error && !userEmail
                  ? { borderColor: "#fd4326" }
                  : { borderColor: "black" }
              }
            />
            <input
              type="submit"
              value={submit ? "Subscribing..." : "Subscribe"}
              className="btn1 cursor-pointer w-44"
            />
          </div>
        </form>
        <div className="flex items-center justify-center">
          <a
            href="https://www.facebook.com/profile.php?id=100089088143832"
            target="_blank"
            rel="noreferrer"
            className="text-white bg-sec-500 hover:bg-blue-100 w-14 h-14 rounded-full flex items-center justify-center  mx-3 hover:text-white"
          >
            <i className="ri-facebook-fill text-xl"></i>
          </a>
          <a
            href="https://twitter.com/Achauffeurlimo"
            target="_blank"
            rel="noreferrer"
            className="text-white bg-sec-500 hover:bg-blue-200 w-14 h-14 rounded-full flex items-center justify-center  mx-3 hover:text-white"
          >
            <i className="ri-twitter-fill text-xl"></i>
          </a>
          <a
            href="https://www.instagram.com/achauffeurlimos/"
            target="_blank"
            rel="noreferrer"
            className="text-white bg-sec-500 hover:bg-purple-100 w-14 h-14 rounded-full flex items-center justify-center  mx-3 hover:text-white"
          >
            <i className="ri-instagram-fill text-xl"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UClftjIYKDx03zl3hDHyRF0Q"
            target="_blank"
            rel="noreferrer"
            className="text-white bg-sec-500 hover:bg-red-100 w-14 h-14 rounded-full flex items-center justify-center  mx-3 hover:text-white"
          >
            <i className="ri-youtube-fill text-xl"></i>
          </a>
        </div>
      </div>
      <Toast message={toastMessage} />
    </div>
  );
};

export default SocialContacts;
