import React from "react";

const Way = () => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
      <div className="container">
        <h2 className="text-center">A Chauffeur's Way</h2>
        <div className="text-center text-lg md:text-xl max-w-5xl mx-auto ">
          Founded in 2017, Achauffeur has grown to be one of the largest luxury
          limousine service company in Canada. Since then, We have been helping
          thousands of customers to reach their destinations comfortably. We
          maintain a fleet of clean and hygienic limousines driven by highly
          professional, well-trained chauffeurs. The basic fundamental for us is
          customer satisfaction so our chauffeurs go above and beyond to provide
          that quality service.
        </div>
      </div>
    </div>
  );
};

export default Way;
