import React from "react";
import icon from "../../assets/icons/payment-security-at-achauffeur.png";

import Banner2 from "../../components/web/banners/Banner3";
import ServiceHero from "../../components/web/services/ServiceHero";
import support from "../../assets/icons/24/7-live-supprot-from-achauffeur.png";
import diamond from "../../assets/icons/luxury-vehicles-for-your-transportations.png";
import rating from "../../assets/icons/years-of-experience-in-chauffeur-service-with-professional-chauffeurs.png";
import { Helmet } from "react-helmet-async";
import f1 from "../../assets/images/services/long-distance-ground-transportation-services.jpg";
import f2 from "../../assets/images/services/ground-transportation-for-vacation-trip.jpg";
import ServiceFeature1 from "../../components/web/services/ServiceFeature1";
import ServiceFeature2 from "../../components/web/services/ServiceFeature2";

const GroundTransportation = () => {
  const hero = {
    title: "Ground Transportation",
    description:
      <span>Experience <b>luxury ground transportation</b> with our chauffeur service, offering airport transfers, meet and greet, and flexible hourly car options in major cities across Canada and the US. Book your <b>premium car service</b> online and enjoy the comfort and convenience from pickup to drop-off throughout your journey</span>,
    card1: {
      icon: rating,
      alt: "achauffeur has years of experience in Ground Transportation",
      title: "Years of experience",
      description:
        "With a team of more than 20 professionals, our years keep on adding.",
    },
    card2: {
      icon: support,
      alt: "24/7 live support from achauffeur. Call at any time to our hotline",
      title: "24/7 live support",
      description:
        "We serve last-minute accommodations with 24/7 customer support.",
    },
    card3: {
      icon: icon,
      alt: "achauffeur provide secure payment methods",
      title: "Payment Security",
      description:
        "We like to keep things transparent and secure for our customers.",
    },
    card4: {
      icon: diamond,
      alt: "achauffeur has luxury vehicles for Ground Transportation",
      title: "Luxury vehicles",
      description:
        "We have an array of luxury limousines waiting for your booking.",
    },
  };
  const feature1 = {
    image: f1,
    alt: "Luxury & Hassle-free long-distance ground transportation services. You can add multiple stops during travel",
    title: "Luxury & Hassle-free Ground Transportation ",
    description:
      <span>If you miss the flight or are unable to fly on time due to severe weather conditions, Achauffeur offers luxurious and hassle-free <b>Ground Transportation</b>. We will ensure that your journey is a reliable, enjoyable and stress free experience. You may undoubtedly have the smoothest Ground Transportation, just as your flight transportation, with our luxury vehicles. We offer <b>Ground Transportation services</b> for Board of Directors meetings, large and small conferences or special events.      </span>,
  };
  const feature2 = {
    image: f2,
    alt: "Book ground transportation services for your vacation trip in an affordable price at achauffeurs",
    title: "Award-Winning Airport Transportation Worldwide",
    description:
      <span>Discover AChauffeur: Experience the pinnacle of <b>ground transportation</b> with our globally renowned airport transportation service. With a diverse fleet that includes sedans, SUVs, vans, and buses, AChauffeur caters to all your travel requirements, ensuring your journey is comfortable and tailored to your needs. Whether you're in need of a stress-free airport transfer or dependable pick-up and drop-off solutions, our team of dedicated professional drivers at AChauffeur places punctuality and passenger comfort at the forefront of every ride, guaranteeing a seamless travel experience from the moment you book until you reach your destination. For a competitive quote and to discover the exceptional value we offer, simply request a quote online or find us by searching <b>'AChauffeur - Ground Transportation Near Me.'</b> Choose AChauffeur for your ground transportation and airport transport needs, and you'll quickly understand why our award-winning service stands as an industry leader, providing the best travel experience regardless of your destination.      </span>,
  };
  return (
    <>
      <Helmet>
        <title>Book Your Ground Transportation Service | AChauffeur.com</title>
        <meta
          name="description"
          content="Ground Transportation Chauffeur Service. 24/7- Efficient and reliable ground transportation service for your leisure, business and events. Best Price Guaranteed  "
        />
        <meta
          name="keywords"
          content="Ground Transportation service, Airport Ground transportation service, Reliable Ground Transportation, Ground Transportation service in Toronto, Airport Ground Transportation, Ground transportation, Lax ground transportation, Airport ground transportation, Executive ground transportation  "
        />
        <link rel="canonical" href="/services/ground-transportation" />
      </Helmet>
      <ServiceHero hero={hero} />

      <Banner2 />
      <ServiceFeature1 feature={feature1} />
      <ServiceFeature2 feature={feature2} />
    </>
  );
};

export default GroundTransportation;
