import React from "react";

const ServiceFeature1 = (props) => {
  return (
    <>
      <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 ">
        <div className="container">
          <div className="grid  md:grid-cols-2 gap-5 md:gap-10 lg:gap-20 items-center">
            <div className="row-start-2 md:row-start-1">
              <img src={props.feature.image} alt={props.feature.alt} />
            </div>
            <div>
              <h2>{props.feature.title}</h2>
              <div className=" -mt-2 text-base">
                {props.feature.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceFeature1;