import { Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import logo from "../../assets/images/achauffeurlogo.png";
const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/intercity-chauffeured-rides"
            className="nav--item text-base px-2  "
          >
            {" "}
            Intercity chauffeured rides
          </NavLink>
        ),
      },
      {
        key: "2",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/airport-transfer"
            className="text-base px-2 "
          >
            Airport transfer
          </NavLink>
        ),
      },
      {
        key: "3",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/event-limousine-service"
            className="text-base px-2"
          >
            Event limousine service
          </NavLink>
        ),
      },
      {
        key: "4",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/vip-chauffeur-service"
            className="text-base px-2"
          >
            VIP chauffeur service
          </NavLink>
        ),
      },
      {
        key: "5",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/private-car-service"
            className="text-base px-2"
          >
            Private car service
          </NavLink>
        ),
      },
      {
        key: "6",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/ground-transportation"
            className="text-base px-2"
          >
            Ground transportation
          </NavLink>
        ),
      },
      {
        key: "7",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/meet-and-greet-service"
            className="text-base px-2"
          >
            {" "}
            Meet and greet service
          </NavLink>
        ),
      },
      {
        key: "8",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/parcel-delivery-service"
            className="text-base px-2"
          >
            Parcel delivery service
          </NavLink>
        ),
      },
      {
        key: "9",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/winery-tour-service"
            className="text-base px-2"
          >
            Winery tour service
          </NavLink>
        ),
      },
      {
        key: "10",
        label: (
          <NavLink
            style={({ isActive }) => {
              return { color: isActive ? "#fd4326" : "" };
            }}
            to="/services/charter-bus-tour"
            className="text-base px-2"
          >
            Charter bus tours
          </NavLink>
        ),
      },
    ]}
  />
);

const NavBar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [expandNav, setExpandNav] = useState(false);

  const serviceList = (
    <>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/intercity-chauffeured-rides"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Intercity chauffeured rides
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/airport-transfer"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Airport transfer
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/event-limousine-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Event limousine service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/vip-chauffeur-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        VIP chauffeur service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/private-car-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Private car service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/ground-transportation"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Ground transportation
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/meet-and-greet-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Meet and greet service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/parcel-delivery-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Parcel delivery service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/winery-tour-service"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Winery tour service
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return { color: isActive ? "#fd4326" : "" };
        }}
        to="/services/charter-bus-tour"
        className="block text-base text-sec-500"
        onClick={() => {
          setMobileMenu(false);
          setExpandNav(false);
        }}
      >
        Charter bus tours
      </NavLink>
    </>
  );

  return (
    <>
      <div className="px-3 py-0 bg-white ">
        <div className="container">
          <div className="flex justify-between items-center ">
            <NavLink to="/home">
              <img className="h-20 py-5 md:py-2.5" src={logo} alt="logo" />
            </NavLink>
            <div>
              <div className="hidden lg:flex  items-center ">
                <Dropdown
                  overlay={menu}
                  className="mx-3 lg:mx-5 text-base cursor-pointer"
                >
                  <Space>
                    <div className="flex items-center">
                      <div> Services &nbsp;</div>
                      <DownOutlined className="text-sm " />
                    </div>
                  </Space>
                </Dropdown>

                <NavLink
                  to="/our-fleet"
                  className="text-sec-500 mx-3 lg:mx-5 text-base  cursor-pointer hover:text-pri-500"
                  style={({ isActive }) => {
                    return { color: isActive ? "#fd4326" : "" };
                  }}
                >
                  Our Fleet
                </NavLink>
                <NavLink
                  style={({ isActive }) => {
                    return { color: isActive ? "#fd4326" : "" };
                  }}
                  to="/about-us"
                  className="text-sec-500 mx-3 lg:mx-5 text-base  cursor-pointer hover:text-pri-500"
                >
                  About Us
                </NavLink>
                <NavLink
                  style={({ isActive }) => {
                    return { color: isActive ? "#fd4326" : "" };
                  }}
                  to="/help"
                  className="text-sec-500 mx-3 lg:mx-5 text-base  cursor-pointer hover:text-pri-500"
                >
                  Help
                </NavLink>

                <NavLink
                  to="contact-us"
                  className="btn1 ml-3 lg:ml-5 text-base  cursor-pointer"
                >
                  Contact Us
                </NavLink>
              </div>
              <div
                onClick={() => setMobileMenu(!mobileMenu)}
                className="lg:hidden"
              >
                <MenuOutlined className="text-2xl" />
              </div>
            </div>
          </div>
          {mobileMenu && (
            <div className="mt-3 ">
              <div
                onClick={() => {
                  setExpandNav(!expandNav);
                }}
                className="block text-base cursor-pointer"
              >
                <div className="mb-1 flex items-center text-sec-500">
                  <div> Services &nbsp;</div>
                  <DownOutlined className="text-sm " />
                </div>
              </div>
              <div className="ml-4">{expandNav && serviceList}</div>
              <NavLink
                style={({ isActive }) => {
                  return { color: isActive ? "#fd4326" : "" };
                }}
                to="/our-fleet"
                onClick={() => {
                  setMobileMenu(false);
                  setExpandNav(false);
                }}
                className="block mb-1 text-sec-500 text-base cursor-pointer"
              >
                Our Fleet
              </NavLink>
              <NavLink
                style={({ isActive }) => {
                  return { color: isActive ? "#fd4326" : "" };
                }}
                to="/about-us"
                onClick={() => {
                  setMobileMenu(false);
                  setExpandNav(false);
                }}
                className="block mb-1 text-sec-500 text-base cursor-pointer"
              >
                About Us
              </NavLink>
              <NavLink
                style={({ isActive }) => {
                  return { color: isActive ? "#fd4326" : "" };
                }}
                to="/help"
                onClick={() => {
                  setMobileMenu(false);
                  setExpandNav(false);
                }}
                className="block mb-1 text-sec-500 text-base cursor-pointer"
              >
                Help
              </NavLink>
              <NavLink
                to="contact-us"
                onClick={() => {
                  setMobileMenu(false);
                  setExpandNav(false);
                }}
                className="block mb-1 text-sec-500 text-base cursor-pointer"
              >
                Contact Us
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;
