import React from "react";
import ContactHero from "../components/web/ContactHero";
import ContactForm from "../components/web/ContactForm";
import Map from "../components/web/Map";
import SocialContacts from "../components/web/SocialContacts";
import { Helmet } from "react-helmet-async";
const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact us. chauffeur service solutions from trusted experts
        </title>
        <meta
          name="description"
          content="We take the stress out of your transportation and let you focus on your guests. Contact us today to discuss our chauffeur service options. "
        />
        <meta
          name="keywords"
          content=" A chauffeur, chauffeur service, chauffeur transportation, contact a chauffeur, airport limo, airport transfer, airport chauffeurs, airport chauffeur, airport chauffeur Toronto, livery service, hourly service"
        />
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <ContactHero />
      <ContactForm />
      <SocialContacts />
      <Map />
    </>
  );
};

export default ContactUs;
